import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Marketplace, MarketplaceData } from 'types/Marketplace'
import {
  createMarketplace,
  deleteMarketplace,
  getMarketplace,
  getMarketplaceList,
  updateMarketplace,
} from './ActionCreators'

interface MarketplaceState {
  marketplacesData: {
    count: number
    results: Marketplace[]
  }
  marketplace: Marketplace
  isLoading: boolean
  error: any
}

const initialState: MarketplaceState = {
  marketplacesData: {
    count: 0,
    results: [],
  },
  marketplace: {
    id: 0,
    name: '',
    address: '',
    position: {
      type: '',
      coordinates: [],
    },
    administrators: [],
  },
  isLoading: false,
  error: '',
}

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {},
  extraReducers: {
    [getMarketplaceList.fulfilled.type]: (state, action: PayloadAction<MarketplaceData>) => {
      state.isLoading = false
      state.error = ''
      state.marketplacesData.count = action.payload.count
      state.marketplacesData.results = action.payload.results
    },
    [getMarketplaceList.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMarketplaceList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getMarketplace.fulfilled.type]: (state, action: PayloadAction<Marketplace>) => {
      state.isLoading = false
      state.error = ''
      state.marketplace = action.payload
    },
    [getMarketplace.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMarketplace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateMarketplace.fulfilled.type]: (state, action: PayloadAction<Marketplace>) => {
      state.isLoading = false
      state.error = ''
      state.marketplace = action.payload
    },
    [updateMarketplace.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateMarketplace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [deleteMarketplace.fulfilled.type]: (state) => {
      state.isLoading = false
      state.error = ''
    },
    [deleteMarketplace.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteMarketplace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createMarketplace.fulfilled.type]: (state) => {
      state.isLoading = false
      state.error = ''
    },
    [createMarketplace.pending.type]: (state) => {
      state.isLoading = true
    },
    [createMarketplace.rejected.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default marketplaceSlice.reducer
