import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Login } from 'types/Login'
import { login } from './ActionCreators'

interface AuthtState {
  login: Login
  isLoading: boolean
  errors: string[]
}

const initialState: AuthtState = {
  login: {
    phone: '',
    token: '',
    user_id: 0,
  },
  isLoading: false,
  errors: [],
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = []
    },
  },
  extraReducers: {
    [login.fulfilled.type]: (state, action: PayloadAction<Login>) => {
      state.isLoading = false
      state.errors = []
      state.login = action.payload
    },
    [login.pending.type]: (state) => {
      state.isLoading = true
    },
    [login.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
  },
})

export default authSlice.reducer
