import React, { FC } from 'react'
import { Autocomplete, SxProps, TextField, Theme } from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { SelectFieldType } from 'types/Other'

interface AutocompleteFieldProps {
  control: Control<FieldValues>
  name: string
  selectItem: SelectFieldType[]
  defaultValue?: number | string | null
  label?: string
  required?: boolean
  requiredText?: string
  size?: 'small' | 'medium'
  disabled?: boolean
  sx?: SxProps<Theme>
}

const AutocompleteField: FC<AutocompleteFieldProps> = ({
  control,
  name,
  selectItem,
  defaultValue,
  label,
  required,
  requiredText = '',
  size,
  disabled,
  sx,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disablePortal
          options={selectItem}
          value={value}
          onChange={(_event, data) => onChange(data?.value ?? '')}
          getOptionLabel={(option) =>
            option?.label ?? selectItem.find(({ value }) => value === option)?.label ?? ''
          }
          noOptionsText='Не найдено'
          renderInput={(params) => (
            <TextField
              sx={{
                ...sx,
                '& .MuiInputLabel-root': {
                  color: '#BDBDBD',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#ffffff',
                },
                '& .MuiInputLabel-shrink': {
                  color: '#ffffff',
                  backgroundColor: 'primary.main',
                  padding: '0px 5px',
                  left: '-5px',
                  top: '2px',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '20px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  '& fieldset': {
                    border: '2px solid',
                    borderColor: 'primary.main',
                    '& legend': {
                      width: '38px',
                    },
                  },
                  '&:hover fieldset': {
                    border: '2px solid',
                    borderColor: '#36429A',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid',
                    borderColor: 'primary.main',
                  },
                },
              }}
              {...params}
              fullWidth
              size={size}
              label={label || ''}
              variant='outlined'
              error={!!error}
            />
          )}
          disabled={disabled}
        />
      )}
      rules={{
        required: required || requiredText,
      }}
    />
  )
}

export default AutocompleteField
