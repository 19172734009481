import React, { FC, useCallback, useState } from 'react'
import { Box, CircularProgress, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableHead, Toolbar, TableRow } from 'components/terminal/ContainerBatches'
import { useAppSelector } from 'store/hooks/useAppSelector'
import {
  selectBatches,
  selectContainerBatchesIsLoading,
} from 'store/selectors/containerBatchSelectors'
import { ContainerBatchModal } from 'components/modals'
import { selectProductBatchesIsLoading } from 'store/selectors/productBatchSelectors'

interface ContainerBatchProps {
  productId?: number
  containerId?: number
}

const ContainerBatches: FC<ContainerBatchProps> = ({ containerId, productId }) => {
  const conteinerButches = useAppSelector(selectBatches)
  const containerBatchesIsLoading = useAppSelector(selectContainerBatchesIsLoading)
  const productBatchesIsLoading = useAppSelector(selectProductBatchesIsLoading)
  const [batchModal, setBatchModal] = useState<boolean>(false)

  const headCells = [
    {
      id: 1,
      label: 'Партия',
    },
    {
      id: 2,
      label: 'Остаток',
    },
  ]

  const handleBatchModalOpen = useCallback(() => {
    setBatchModal(true)
  }, [batchModal])

  const handleBatchModalClose = useCallback(() => {
    setBatchModal(false)
  }, [batchModal])

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#EDEDED',
          borderRadius: '10px',
          overflow: 'hidden',
          height: '390px',
          position: 'relative',
        }}
      >
        <Toolbar
          title='Партии товара'
          buttonLabel='+ Добавить'
          handleModalOpen={handleBatchModalOpen}
        />
        <TableContainer sx={{ maxHeight: '325px' }}>
          <MUITable size='small' stickyHeader>
            <TableHead headCells={headCells} />
            <TableBody>
              {productBatchesIsLoading && containerBatchesIsLoading ? (
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '45%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              ) : (
                <>
                  {conteinerButches.map((row) => (
                    <TableRow
                      key={row.id}
                      id={row.id}
                      quantity={row.quantity}
                      containerId={row.container}
                      productBatchLabel={row.productBatchLabel}
                      batch={row.batch}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Box>
      <ContainerBatchModal
        isOpen={batchModal}
        onClose={handleBatchModalClose}
        modalType='create'
        containerId={containerId}
        productId={productId}
      />
    </>
  )
}

export default ContainerBatches
