import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Order, OrdersData } from 'types/Orders'
import { getOrders } from './ActionCreators'

interface OrdersState {
  ordersData: {
    revenue: number
    count: number
    results: Order[]
  }
  isLoading: boolean
  error: string
}

const initialState: OrdersState = {
  ordersData: {
    revenue: 0,
    count: 0,
    results: [],
  },
  isLoading: false,
  error: '',
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: {
    [getOrders.fulfilled.type]: (state, action: PayloadAction<OrdersData>) => {
      state.isLoading = false
      state.error = ''
      state.ordersData.count = action.payload.count
      state.ordersData.results = action.payload.results
      state.ordersData.revenue = action.payload.revenue
    },
    [getOrders.pending.type]: (state) => {
      state.isLoading = true
    },
    [getOrders.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default ordersSlice.reducer
