import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Cell, CellsData } from 'types/Cells'
import { createCell, deleteCell, getCells, partialUpdateCell, updateCell } from './ActionCreators'

interface CellState {
  cellData: {
    count: number
    results: Cell[]
  }
  isLoadingCells: boolean
  isLoadingCell: boolean
  error: string[]
}

const initialState: CellState = {
  cellData: {
    count: 0,
    results: [],
  },
  isLoadingCells: false,
  isLoadingCell: false,
  error: [],
}

export const cellSlice = createSlice({
  name: 'cell',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = []
    },
  },
  extraReducers: {
    [getCells.fulfilled.type]: (state, action: PayloadAction<CellsData>) => {
      state.isLoadingCells = false
      state.error = []
      state.cellData.count = action.payload.count
      state.cellData.results = action.payload.results
    },
    [getCells.pending.type]: (state) => {
      state.isLoadingCells = true
    },
    [getCells.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingCells = false
      state.error = action.payload
    },
    [updateCell.fulfilled.type]: (state, action: PayloadAction<Cell>) => {
      state.isLoadingCell = false
      state.error = []
      state.cellData.results = state.cellData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [updateCell.pending.type]: (state) => {
      state.isLoadingCell = true
    },
    [updateCell.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingCell = false
      state.error = action.payload
    },
    [deleteCell.fulfilled.type]: (state, action: AnyAction) => {
      state.isLoadingCell = false
      state.error = []
      state.cellData.results = state.cellData.results.filter(
        (item) => item.id !== action.meta.arg.id,
      )
    },
    [deleteCell.pending.type]: (state) => {
      state.isLoadingCell = true
    },
    [deleteCell.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingCell = false
      state.error = action.payload
    },
    [partialUpdateCell.fulfilled.type]: (state, action: PayloadAction<Cell>) => {
      state.isLoadingCell = false
      state.error = []
      state.cellData.results = state.cellData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [partialUpdateCell.pending.type]: (state) => {
      state.isLoadingCell = true
    },
    [partialUpdateCell.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingCell = false
      state.error = action.payload
    },
    [createCell.fulfilled.type]: (state, action: PayloadAction<Cell>) => {
      state.isLoadingCell = false
      state.error = []
      state.cellData.results.push(action.payload)
    },
    [createCell.pending.type]: (state) => {
      state.isLoadingCell = true
    },
    [createCell.rejected.type]: (state, action: PayloadAction<any>) => {
      state.isLoadingCell = false
      state.error = action.payload
    },
  },
})

export default cellSlice.reducer
