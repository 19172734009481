import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectProductName } from 'store/selectors/productsSelector'
import { SelectFieldType } from 'types/Other'
import { CellModal } from 'components/modals'
import { CellContainer } from 'types/Cells'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  code: number
  cookingTime: number
  productId: number
  price: number
  productList: SelectFieldType[]
  title: string
  containers: CellContainer[]
  quantityNotification: number
  needQuantityNotification: boolean
  isComposite: boolean
  placement: string
  quantity: number
}

const TableRow: FC<TableRowProps> = ({
  id,
  code,
  cookingTime,
  productId,
  price,
  productList,
  title,
  containers,
  quantityNotification,
  needQuantityNotification,
  isComposite,
  placement,
  quantity,
}) => {
  const productLabel = useAppSelector(selectProductName(productId))
  const [cellModal, setCellModal] = useState<boolean>(false)
  const isHidden = useMediaQuery({ maxWidth: 900 })

  const handleCellModalOpen = useCallback(() => {
    setCellModal(true)
  }, [cellModal])

  const handleCellModalClose = useCallback(() => {
    setCellModal(false)
  }, [cellModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root ': {
            color: quantity <= quantityNotification ? 'error.main' : 'text.primary',
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleCellModalOpen}
      >
        {!isHidden && (
          <TableCell component='th' scope='row'>
            {code || '-'}
          </TableCell>
        )}
        <TableCell component='th' scope='row'>
          {productLabel}
        </TableCell>
        <TableCell component='th' scope='row'>
          {price}
        </TableCell>
        {!isHidden && (
          <>
            <TableCell component='th' scope='row'>
              {isComposite ? 'Да' : 'Нет'}
            </TableCell>
            <TableCell component='th' scope='row'>
              {placement}
            </TableCell>
            <TableCell component='th' scope='row'>
              {quantity}
            </TableCell>
            <TableCell component='th' scope='row'>
              {quantityNotification}
            </TableCell>
          </>
        )}
      </MUITableRow>
      <CellModal
        isOpen={cellModal}
        onClose={handleCellModalClose}
        modalType='change'
        productList={productList}
        id={id}
        title={title}
        price={price}
        code={code}
        productId={productId}
        cookingTime={cookingTime}
        containers={containers}
        quantityNotification={quantityNotification}
        needQuantityNotification={needQuantityNotification}
      />
    </>
  )
}

export default TableRow
