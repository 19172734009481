import { createTheme } from '@mui/material/styles'

const useStyles = () => {
  const theme = createTheme({
    direction: 'ltr',
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Montserrat"',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      body1: {
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '300',
      },
      button: {
        textTransform: 'inherit',
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '400',
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#6271DD',
      },
      secondary: {
        main: '#ffffff',
      },
      text: {
        primary: '#1C2A4B',
        secondary: '#9A9A9A',
      },
      success: {
        main: '#6EB870',
      },
      error: {
        main: '#DD684E',
      },
    },

    components: {
      MuiTablePagination: {
        defaultProps: {
          labelRowsPerPage: 'Размер страницы',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            fontWeight: '300',
            padding: '10px 27px',
            borderRadius: '5px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
          },
          iconSizeSmall: {
            '& > *:first-of-type': {
              fontSize: 20,
            },
          },
          iconSizeMedium: {
            '& > *:first-of-type': {
              fontSize: 34,
            },
          },
          iconSizeLarge: {
            '& > *:first-of-type': {
              fontSize: 40,
            },
          },
        },
      },
    },
  })

  return theme
}

export default useStyles
