import React, { FC } from 'react'
import { Box } from '@mui/material'

interface TabSectionProps {
  index: number
  value: number
  children?: React.ReactNode
}

const TabSection: FC<TabSectionProps> = ({ index, value, children }) => {
  return (
    <Box sx={{ position: 'relative', height: '90%' }} role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

export default TabSection
