import React, { FC, memo, useMemo } from 'react'
import { Box, TableCell, TableRow as MUITableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Error as ErrorIcon } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  address: string
  containersNeedRevision: number
  modelName: string
}

const TableRow: FC<TableRowProps> = ({ id, address, containersNeedRevision, modelName }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const handleNavigateToTerminal = () => {
    navigate(`/admin/terminals/${id}`)
  }

  const containersRevision = useMemo(() => {
    return containersNeedRevision > 0 ? 'Да' : 'Нет'
  }, [containersNeedRevision])

  return (
    <MUITableRow
      sx={{
        cursor: 'pointer',
        '& .MuiTableCell-root': {
          color: containersNeedRevision > 0 ? 'error.main' : 'text.primary',
          borderBottom: 'none',
          padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
          '&:first-of-type': {
            padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
          },
          verticalAlign: 'middle',
        },
      }}
      hover
      role='row'
      tabIndex={-1}
      component='tr'
      onClick={handleNavigateToTerminal}
    >
      <TableCell component='th' scope='row'>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {containersNeedRevision > 0 ? (
            <ErrorIcon sx={{ marginRight: '5px' }} fontSize='small' />
          ) : (
            <Box sx={{ width: '25px' }} />
          )}
          {address}
        </Box>
      </TableCell>
      {!isMobile && (
        <>
          <TableCell component='th' scope='row'>
            {containersRevision}
          </TableCell>
          <TableCell component='th' scope='row'>
            {modelName}
          </TableCell>
        </>
      )}
    </MUITableRow>
  )
}

export default memo(TableRow)
