import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Login } from 'types/Login'
import { http } from 'utils/httpConfig'

export const login = createAsyncThunk(
  'auth/login',
  async (body: { username: string; password: string }, thunkAPI) => {
    try {
      const response = await http.post<Login>('/api/admin/login', body)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed login, error${error}`])
      }
    }
  },
)
