import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ingredient, IngredientsData } from 'types/Ingredients'
import {
  createIngredient,
  deleteIngredient,
  getIngredients,
  partialUpdateIngredient,
  updateIngredient,
} from './ActionCreators'

interface ProductsState {
  ingredientsData: {
    count: number
    results: Ingredient[]
  }
  isLoading: boolean
  error: string
}

const initialState: ProductsState = {
  ingredientsData: {
    count: 0,
    results: [],
  },
  isLoading: false,
  error: '',
}

export const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {},
  extraReducers: {
    [getIngredients.fulfilled.type]: (state, action: PayloadAction<IngredientsData>) => {
      state.isLoading = false
      state.error = ''
      state.ingredientsData.count = action.payload.count
      state.ingredientsData.results = action.payload.results
    },
    [getIngredients.pending.type]: (state) => {
      state.isLoading = true
    },
    [getIngredients.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateIngredient.fulfilled.type]: (state, action: PayloadAction<Ingredient>) => {
      state.isLoading = false
      state.error = ''
      state.ingredientsData.results = state.ingredientsData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [updateIngredient.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateIngredient.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [deleteIngredient.fulfilled.type]: (state, action: AnyAction) => {
      state.isLoading = false
      state.error = ''
      state.ingredientsData.results = state.ingredientsData.results.filter(
        (item) => item.id !== action.meta.arg.id,
      )
    },
    [deleteIngredient.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteIngredient.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [partialUpdateIngredient.fulfilled.type]: (state, action: PayloadAction<Ingredient>) => {
      state.isLoading = false
      state.error = ''
      state.ingredientsData.results = state.ingredientsData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [partialUpdateIngredient.pending.type]: (state) => {
      state.isLoading = true
    },
    [partialUpdateIngredient.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createIngredient.fulfilled.type]: (state, action: PayloadAction<Ingredient>) => {
      state.isLoading = false
      state.error = ''
      state.ingredientsData.results.push(action.payload)
    },
    [createIngredient.pending.type]: (state) => {
      state.isLoading = true
    },
    [createIngredient.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default ingredientsSlice.reducer
