import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import { SelectFilterField } from 'components/generic'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectMarketplacesList } from 'store/selectors/marketplaceSelectors'
import { selectGroupsList } from 'store/selectors/groupsSelectors'
import { SelectFieldType } from 'types/Other'
import { selectTerminalModelsFilterList } from 'store/selectors/terminalsSelector'

interface FilterProps {
  isOpen: boolean
  handleFilterClose: () => void
  marketplace: string
  group: string
  cleaning: string
  terminalType: string
  handleFilterMarketplace: (event: { target: { value: React.SetStateAction<string> } }) => void
  handleFilterGroup: (event: { target: { value: React.SetStateAction<string> } }) => void
  handleFilterCleaning: (event: { target: { value: React.SetStateAction<string> } }) => void
  handleFilterTerminalType: (event: { target: { value: React.SetStateAction<string> } }) => void
}

const Filter: FC<FilterProps> = ({
  isOpen,
  handleFilterClose,
  marketplace,
  group,
  cleaning,
  terminalType,
  handleFilterMarketplace,
  handleFilterGroup,
  handleFilterCleaning,
  handleFilterTerminalType,
}) => {
  const marketplaceList = useAppSelector(selectMarketplacesList)
  const groupList = useAppSelector(selectGroupsList)
  const terminalModels = useAppSelector(selectTerminalModelsFilterList)

  const cleaningList: SelectFieldType[] = [
    {
      label: 'Да',
      value: 'true',
    },
    {
      label: 'Нет',
      value: 'false',
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: {
          xs: isOpen ? '240px' : '0px',
          sm: isOpen ? '130px' : '0px',
          lg: isOpen ? '70px' : '0px',
        },
        transition: 'all 0.3s ease-in-out',
        padding: isOpen ? { xs: '15px 20px', sm: '15px 35px' } : { xs: '0px 20px', sm: '0px 35px' },
        backgroundColor: '#6271DD',
      }}
    >
      <Box
        sx={{
          opacity: isOpen ? '1' : '0',
          transition: 'all 0.3s ease-in-out',
          width: { xs: '100%', lg: '75%' },
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            md: '1fr 1fr 1fr',
            lg: '1fr 1fr 1fr 1fr',
          },
          columnGap: { xs: '15px', md: '20px' },
          rowGap: { xs: '15px', md: '20px' },
        }}
      >
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={marketplace}
            label='Точка продаж'
            handleChange={handleFilterMarketplace}
            selectItem={marketplaceList}
          />
        </Box>
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={group}
            label='Группа автоматов'
            handleChange={handleFilterGroup}
            selectItem={groupList}
          />
        </Box>
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={cleaning}
            label='Требуется сервис'
            handleChange={handleFilterCleaning}
            selectItem={cleaningList}
          />
        </Box>
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={terminalType}
            label='Модель автомата'
            handleChange={handleFilterTerminalType}
            selectItem={terminalModels}
          />
        </Box>
      </Box>
      <Button
        sx={{
          display: { xs: 'none', lg: 'inline-flex' },
          opacity: isOpen ? '1' : '0',
          transition: 'all 0.3s ease-in-out',
        }}
        variant='contained'
        onClick={handleFilterClose}
      >
        Свернуть
      </Button>
    </Box>
  )
}

export default Filter
