import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import {
  createProduct,
  getProducts,
  partialUpdateProduct,
  updateProduct,
} from 'store/reducers/ProductsSlice/ActionCreators'
import { ProductFormTypes } from 'types/FormTypes'
import { pickBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { getCategory } from 'store/reducers/CategoriesSlice/ActionCreators'
import { selectCategory } from 'store/selectors/categoriesSelectors'
import { selectProductErrors } from 'store/selectors/productsSelector'
import { productsSlice } from 'store/reducers/ProductsSlice/ProductsSlice'

const useProductModal = (
  modalType: 'create' | 'change',
  onClose: () => void,
  id?: number,
  icon?: string,
) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<ProductFormTypes>()
  const { reset, watch } = methods
  const categoryValue = watch('category')
  const [image, setImage] = useState<any>(icon || null)
  const [imageFile, setImageFile] = useState<any>(null)
  const { clearErrors } = productsSlice.actions
  const productErrors = useAppSelector<string[]>(selectProductErrors)
  const removeEmptyFields = (values: ProductFormTypes | any) => {
    return pickBy(values, (value) => value !== '')
  }
  const { categoryId, categoryName, categoryIcon, categoryIsVisible } =
    useAppSelector(selectCategory)
  const [categoryCreateModal, setCategoryCreateModal] = useState<boolean>(false)
  const [categoryChangeModal, setCtegoryChangeModal] = useState<boolean>(false)

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/products/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/products/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const handleCategoryCreateModalOpen = useCallback(() => {
    setCategoryCreateModal(true)
  }, [categoryCreateModal])

  const handleCategoryCreateModalClose = useCallback(() => {
    setCategoryCreateModal(false)
  }, [categoryCreateModal])

  const handleCategoryChangeModalOpen = useCallback(() => {
    setCtegoryChangeModal(true)
  }, [categoryChangeModal])

  const handleCategoryChangeModalClose = useCallback(() => {
    setCtegoryChangeModal(false)
  }, [categoryChangeModal])

  const handleAddImage = (event: any) => {
    setImage(URL.createObjectURL(event.target.files[0]) || null)
    setImageFile(event.target.files[0])
    if (modalType === 'change') {
      const formData = new FormData()
      formData.append('icon', event.target.files[0] || null)

      dispatch(partialUpdateProduct({ id, formValues: formData }))
        .unwrap()
        .then((res) => {
          if (res.icon) {
            setImage(res.icon)
          }
        })
    }
  }

  const handleDeleteImage = () => {
    if (modalType === 'change') {
      const formData = new FormData()
      formData.append('icon', '')
      dispatch(partialUpdateProduct({ id, formValues: formData }))
        .unwrap()
        .then(() => setImage(null))
    }
    if (modalType === 'create') {
      setImageFile(null)
      setImage(null)
    }
  }

  const onSubmit = (formValues: any) => {
    if (modalType === 'change') {
      const filteredValues = removeEmptyFields(formValues)
      const formData = new FormData()
      for (const key in filteredValues) {
        if (key !== 'ingredients') {
          formData.append(key, filteredValues[key])
        }
      }
      dispatch(updateProduct({ id, formValues: formData }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getProducts({ page: page, page_size: pageSize }))
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      const filteredValues = removeEmptyFields(formValues)
      const formData = new FormData()
      for (const key in filteredValues) {
        formData.append(key, filteredValues[key])
      }
      if (imageFile) {
        formData.append('icon', imageFile)
      }

      dispatch(createProduct({ formValues: formData }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getProducts({ page: page, page_size: pageSize }))
            onClose()
            reset()
          }
        })
    }
  }

  useEffect(() => {
    if (categoryValue) {
      dispatch(getCategory({ id: categoryValue }))
    }
  }, [categoryValue])

  useEffect(() => {
    setImage(icon)
  }, [icon])

  useEffect(() => {
    if (productErrors.length > 0) {
      productErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [productErrors])

  return {
    methods,
    image,
    handleAddImage,
    handleDeleteImage,
    onSubmit,
    categoryId,
    categoryName,
    categoryIcon,
    categoryIsVisible,
    categoryCreateModal,
    categoryChangeModal,
    handleCategoryCreateModalOpen,
    handleCategoryCreateModalClose,
    handleCategoryChangeModalOpen,
    handleCategoryChangeModalClose,
  }
}

export default useProductModal
