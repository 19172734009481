import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { CategoriesData, Category } from 'types/Categories'
import { CategoryFormType } from 'types/FormTypes'
import { http } from 'utils/httpConfig'

export const getCategories = createAsyncThunk(
  'categories/get_categories',
  async (params: { page?: number; page_size?: number; ordering?: string }, thunkAPI) => {
    const { page, page_size, ordering } = params
    try {
      const response = await http.get<CategoriesData>('/api/admin/category/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get categories data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get categories data, ${error}`])
      }
    }
  },
)

export const getCategory = createAsyncThunk(
  'categories/get_category',
  async (params: { id: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<Category>(`/api/admin/category/${id}/`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get category data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get category data, error${error}`])
      }
    }
  },
)

export const updateCategory = createAsyncThunk(
  'categories/update_category',
  async (params: { id?: number; formValues: CategoryFormType }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Category>(`/api/admin/category/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update category, error${error}`])
      }
    }
  },
)

export const deleteCategory = createAsyncThunk(
  'categories/delete_category',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/category/${id}/`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(`Failed to delete category, ${error}`)
    }
  },
)

export const partialUpdateCategory = createAsyncThunk(
  'categories/partial_update_category',
  async (
    params: {
      formValues:
        | {
            name?: string
            icon?: string
            is_visible?: boolean
          }
        | FormData
      id?: number
    },
    thunkAPI,
  ) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<any>(`/api/admin/category/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update category, error${error}`])
      }
    }
  },
)

export const createCategory = createAsyncThunk(
  'categories/create_category',
  async (
    params: {
      formData: FormData
    },
    thunkAPI,
  ) => {
    const { formData } = params
    try {
      const response = await http.post<Category>('/api/admin/category/', formData)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create category, error${error}`])
      }
    }
  },
)
