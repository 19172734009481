import React, { FC } from 'react'
import { TextField as MUITextField } from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'

interface TextFieldProps {
  control: Control<FieldValues>
  name: string
  label?: string
  required?: boolean
  requiredText?: string
  disabled?: boolean
  defaultValue?: string
}

const TextArea: FC<TextFieldProps> = ({
  control,
  name,
  label,
  required,
  requiredText,
  disabled,
  defaultValue,
}) => {
  const defaultText = requiredText || ''
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MUITextField
          sx={{
            '& .MuiInputLabel-root': {
              color: '#BDBDBD',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#ffffff',
            },
            '& .MuiInputLabel-shrink': {
              color: '#ffffff',
              backgroundColor: 'primary.main',
              padding: '0px 5px',
              left: '-5px',
              top: '2px',
              fontWeight: '600',
              fontSize: '13px',
              lineHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '6px',
              '& fieldset': {
                border: '2px solid',
                borderColor: 'primary.main',
                '& legend': {
                  width: '38px',
                },
              },
              '&:hover fieldset': {
                border: '2px solid',
                borderColor: '#36429A',
              },
              '&.Mui-focused fieldset': {
                border: '2px solid',
                borderColor: 'primary.main',
              },
            },
          }}
          fullWidth
          size='small'
          label={label || ''}
          variant='outlined'
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
          multiline
          rows={4}
          disabled={disabled}
        />
      )}
      rules={{
        required: required ? defaultText : false,
      }}
    />
  )
}

export default TextArea
