import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContainerBatchFormTypes, ContainerFormType } from 'types/FormTypes'
import { ContainerBatch, ContainerBatchData } from 'types/ContainerBatch'
import { http } from 'utils/httpConfig'

export const getContainerBatches = createAsyncThunk(
  'container-batch/get_container-batches',
  async (
    params: { page?: number; page_size?: number; ordering?: string; container?: number },
    thunkAPI,
  ) => {
    const { page, page_size, ordering, container } = params
    try {
      const response = await http.get<ContainerBatchData>('/api/admin/container-batch/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          container,
        },
      })
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get container-batches data, ${e}`)
    }
  },
)

export const updateContainerBatch = createAsyncThunk(
  'container-batch/update_container-batch',
  async (params: { id?: number; formValues: ContainerFormType }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<ContainerBatch>(
        `/api/admin/container-batch/${id}/`,
        formValues,
      )
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update container-batch, ${e}`)
    }
  },
)

export const deleteContainerBatch = createAsyncThunk(
  'container-batch/delete_container-batch',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/container-batch/${id}/`)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to delete container-batch, ${e}`)
    }
  },
)

export const partialUpdateContainerBatch = createAsyncThunk(
  'container-batch/partial_update_container-batch',
  async (params: { id?: number; formValues: ContainerBatchFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<ContainerBatch>(
        `/api/admin/container-batch/${id}/`,
        formValues,
      )
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update container-batch, ${e}`)
    }
  },
)

export const createContainerBatch = createAsyncThunk(
  'container-batch/create_container-batch',
  async (params: { formValues: ContainerBatchFormTypes }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<ContainerBatch>('/api/admin/container-batch/', formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to create container-batch, ${e}`)
    }
  },
)
