import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { OrdersData } from 'types/Orders'
import { http } from 'utils/httpConfig'

export const getOrders = createAsyncThunk(
  'orders/get_orders',
  async (
    params: {
      page?: number
      page_size?: number
      ordering?: string
      product?: string
      terminal?: string
      orderDateRange?: string
    },
    thunkAPI,
  ) => {
    const { page, page_size, ordering, product, terminal, orderDateRange } = params
    try {
      const response = await http.get<OrdersData>('/api/admin/order-item/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering: ordering ? ordering : '-order__created_at',
          product,
          terminal,
          order__created_at__range: orderDateRange,
        },
      })

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue(`Failed to get orders, error${error}`)
      }
    }
  },
)
