import React, { FC } from 'react'
import { Box, Table as MUITable, TableBody, TableContainer, Stack } from '@mui/material'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { TableRow } from 'components/groups'
import { GroupModal } from 'components/modals'
import { useGroups } from 'hooks/groups'
import { RowData } from 'types/Table'

const Groups: FC = () => {
  const {
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    headCells,
    groupsIsLoading,
    count,
    groupModal,
    handleModalOpen,
    handleModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useGroups()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Toolbar
          title='Группы автоматов'
          buttonLabel='+ Добавить группу'
          handleModalOpen={handleModalOpen}
          isLoadingData={groupsIsLoading}
        />
        <TableContainer
          sx={{
            maxHeight: { xs: 'auto', sm: 'calc(100vh - 275px)' },
            minHeight: { xs: 'auto', sm: 'calc(100vh - 275px)' },
          }}
        >
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => {
                return (
                  <TableRow
                    key={row.id}
                    id={row.id}
                    name={row.name}
                    externalId={row.external_id}
                    mainTerminal={row.main_terminal}
                  />
                )
              })}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            padding: '10px 24px',
            borderTop: '1px solid',
            borderColor: 'divider',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </Stack>
      </Box>
      <GroupModal isOpen={groupModal} onClose={handleModalClose} modalType='create' />
    </>
  )
}

export default Groups
