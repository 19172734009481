import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'utils/httpConfig'
import { СompaniesData } from 'types/Companies'
import axios from 'axios'

export const getСompanies = createAsyncThunk('company/get_companies', async (_, thunkAPI) => {
  try {
    const response = await http.get<СompaniesData>('/api/admin/company/')
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue([`Failed to get companies, ${error.message}`])
    } else {
      return thunkAPI.rejectWithValue([`Failed to get companies, error${error}`])
    }
  }
})
