import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Container, ContainersData } from 'types/Containers'
import {
  createContainer,
  getContainer,
  getContainers,
  partialUpdateContainer,
  updateContainer,
} from './ActionCreators'

interface ContainersState {
  containersData: {
    count: number
    results: Container[]
  }
  container: Container
  containersIsLoading: boolean
  containerIsLoading: boolean
  error: string[]
}

const initialState: ContainersState = {
  containersData: {
    count: 0,
    results: [],
  },
  container: {
    id: 0,
    terminal: 0,
    product: 0,
    size: 0,
    unit: '',
    quantity: 0,
    quantity_notification: 0,
    need_quantity_notification: false,
    code: '',
    type: '',
    batches: [],
    cleaning_interval: '',
    cleaned_at: '',
    need_cleaning: false,
    is_active: false,
    orders_for_cleaning: 0,
    orders_count: 0,
    franke_properties: {
      id: 0,
      type: '',
      container: 0,
    },
    need_revision: false,
  },
  containersIsLoading: false,
  containerIsLoading: false,
  error: [],
}

export const containersSlice = createSlice({
  name: 'containers',
  initialState,
  reducers: {
    clearContainers: (state) => {
      state.containersData = {
        count: 0,
        results: [],
      }
    },
    clearErrors: (state) => {
      state.error = []
    },
  },
  extraReducers: {
    [getContainers.fulfilled.type]: (state, action: PayloadAction<ContainersData>) => {
      state.containersIsLoading = false
      state.error = []
      state.containersData.count = action.payload.count
      state.containersData.results = action.payload.results
    },
    [getContainers.pending.type]: (state) => {
      state.containersIsLoading = true
    },
    [getContainers.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.containersIsLoading = false
      state.error = action.payload
    },
    [getContainer.fulfilled.type]: (state, action: PayloadAction<Container>) => {
      state.containerIsLoading = false
      state.error = []
      state.container = action.payload
    },
    [getContainer.pending.type]: (state) => {
      state.containerIsLoading = true
    },
    [getContainer.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.containerIsLoading = false
      state.error = action.payload
    },
    [updateContainer.fulfilled.type]: (state, action: PayloadAction<Container>) => {
      state.containerIsLoading = false
      state.error = []
      state.container = action.payload
      state.containersData.results = state.containersData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [updateContainer.pending.type]: (state) => {
      state.containerIsLoading = true
    },
    [updateContainer.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.containerIsLoading = false
      state.error = action.payload
    },
    [partialUpdateContainer.fulfilled.type]: (state, action: PayloadAction<Container>) => {
      state.containerIsLoading = false
      state.error = []
      state.container = action.payload
      state.containersData.results = state.containersData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [partialUpdateContainer.pending.type]: (state) => {
      state.containerIsLoading = true
    },
    [partialUpdateContainer.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.containerIsLoading = false
      state.error = action.payload
    },
    [createContainer.fulfilled.type]: (state, action: PayloadAction<Container>) => {
      state.containerIsLoading = false
      state.error = []
      state.containersData.results.push(action.payload)
    },
    [createContainer.pending.type]: (state) => {
      state.containerIsLoading = true
    },
    [createContainer.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.containerIsLoading = false
      state.error = action.payload
    },
  },
})

export default containersSlice.reducer
