import React, { FC, memo, useCallback, useState } from 'react'
import { TableRow as MUITableRow, TableCell } from '@mui/material'
import { ContainerBatchModal } from 'components/modals'

interface TableRowProps {
  id: number
  productBatchLabel: string
  quantity: number
  containerId: number
  batch: number
}

const TableRow: FC<TableRowProps> = ({ id, productBatchLabel, quantity, containerId, batch }) => {
  const [containerBatchModal, setContainerBatchModal] = useState<boolean>(false)

  const handleContainerBatchModalOpen = useCallback(() => {
    setContainerBatchModal(true)
  }, [containerBatchModal])

  const handleContainerBatchModalClose = useCallback(() => {
    setContainerBatchModal(false)
  }, [containerBatchModal])

  return (
    <>
      <MUITableRow
        sx={{ cursor: 'pointer' }}
        role='row'
        tabIndex={-1}
        component='tr'
        hover
        onClick={handleContainerBatchModalOpen}
      >
        <TableCell
          sx={{ borderBottom: 'none', padding: '5px 0px 5px 20px' }}
          component='th'
          scope='row'
        >
          {productBatchLabel}
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', padding: '5px 0px 5px 20px' }} align='left'>
          {quantity}
        </TableCell>
      </MUITableRow>
      <ContainerBatchModal
        isOpen={containerBatchModal}
        onClose={handleContainerBatchModalClose}
        modalType='change'
        id={id}
        batch={batch}
        quantity={quantity}
        containerId={containerId}
      />
    </>
  )
}

export default memo(TableRow)
