import React, { FC, useEffect, useState, memo } from 'react'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { DateTimePicker, SelectField, Switch, TextField } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { SelectFieldType } from 'types/Other'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectNonFoodField } from 'store/selectors/productsSelector'
import { selectContainerIsLoading } from 'store/selectors/containerSelectors'

interface ContainerFormProps {
  productList: SelectFieldType[]
  containerTypes: SelectFieldType[]
  unitList: SelectFieldType[]
  needCleaning?: boolean
}

const ContainerForm: FC<ContainerFormProps> = ({
  productList,
  containerTypes,
  unitList,
  needCleaning,
}) => {
  const { control, watch, setValue } = useFormContext()
  const needCleaningValue = watch('need_cleaning')
  const productValue = watch('product')
  const [isCleaning, setIsCleaning] = useState<boolean>(needCleaning || false)
  const isNonFoodProduct = useAppSelector(selectNonFoodField(productValue))
  const containerIsLoading = useAppSelector(selectContainerIsLoading)

  useEffect(() => {
    if (needCleaningValue) {
      setIsCleaning(true)
    } else {
      setIsCleaning(false)
    }
  }, [needCleaning, needCleaningValue])

  useEffect(() => {
    if (isNonFoodProduct) {
      setValue('orders_for_cleaning', 0)
    }
  }, [isNonFoodProduct])

  return (
    <>
      {containerIsLoading ? (
        <Box
          sx={{
            height: isNonFoodProduct ? '152px' : '361px',
            width: '100%',
            position: 'relative',
          }}
        >
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '46%',
              left: '46%',
              transform: 'translate(-46%, -46%)',
            }}
          />
        </Box>
      ) : (
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={2}>
            <Box sx={{ width: { xs: '70%', sm: '50%' } }}>
              <SelectField
                control={control}
                name='product'
                label='Товар *'
                size='small'
                selectItem={productList}
                required
              />
            </Box>
            <Box sx={{ width: { xs: '30%', sm: '50%' } }}>
              <TextField
                control={control}
                name='code'
                label='Код *'
                size='small'
                type='number'
                required
              />
            </Box>
          </Stack>
          <SelectField
            control={control}
            name='type'
            label='Тип *'
            size='small'
            selectItem={containerTypes}
            required
          />
          <Stack direction='row' spacing={2}>
            <TextField
              control={control}
              name='size'
              label='Емкость *'
              size='small'
              type='number'
              required
            />
            <SelectField
              control={control}
              name='unit'
              label='Ед.измерения *'
              size='small'
              selectItem={unitList}
              required
            />
          </Stack>
          {!isNonFoodProduct && (
            <>
              <Stack
                sx={{ alignItems: 'center' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <Switch
                  control={control}
                  name='need_cleaning'
                  color='success'
                  label='Требуется периодическая чистка контейнера'
                />
                <TextField
                  control={control}
                  name='cleaning_interval'
                  label='Интервал в днях '
                  size='small'
                  type='number'
                  disabled={!isCleaning}
                  required={isCleaning}
                />
              </Stack>
              <DateTimePicker
                control={control}
                name='cleaned_at'
                label='Дата и время последней чистки'
                size='small'
                disabled={!isCleaning}
                required={isCleaning}
              />
              <Stack
                sx={{ alignItems: 'center' }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
              >
                <TextField
                  control={control}
                  name='orders_for_cleaning'
                  label='Макс. заказов без чистки *'
                  size='small'
                  type='number'
                  required
                />
                <Typography sx={{ fontSize: '12px', lineHeight: '15px', color: '#9A9A9A' }}>
                  Для контейнеров с отходами. При значении 0 чистка не требуется
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  )
}

export default memo(ContainerForm)
