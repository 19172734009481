import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import { SelectFilterField } from 'components/generic'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectTerminalsList } from 'store/selectors/terminalsSelector'
import { DateRangePicker } from 'components/orders/Filter'
import { selectProductList } from 'store/selectors/productsSelector'

interface FilterProps {
  isOpen: boolean
  product: string | null
  terminal: string | null
  startDate: string | null
  endDate: string | null
  handleFilterProduct: (event: { target: { value: string } }) => void
  handleFilterTerminal: (event: { target: { value: string } }) => void
  handleStartDate: (arg: string | null) => void
  handleEndDate: (arg: string | null) => void
  handleClearFilter: () => void
}

const Filter: FC<FilterProps> = ({
  isOpen,
  product,
  terminal,
  startDate,
  endDate,
  handleFilterProduct,
  handleFilterTerminal,
  handleStartDate,
  handleEndDate,
  handleClearFilter,
}) => {
  const terminalsList = useAppSelector(selectTerminalsList)
  const productList = useAppSelector(selectProductList)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: {
            xs: isOpen ? '235px' : '0px',
            sm: isOpen ? '130px' : '0px',
            lg: isOpen ? '70px' : '0px',
          },
          transition: 'all 0.3s ease-in-out',
          padding: isOpen
            ? { xs: '15px 20px', sm: '15px 35px' }
            : { xs: '0px 20px', sm: '0px 35px' },
          backgroundColor: '#6271DD',
          zIndex: '0',
        }}
      >
        <Box
          sx={{
            opacity: isOpen ? '1' : '0',
            transition: 'all 0.3s ease-in-out',
            width: { xs: '100%', lg: '75%' },
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: '1fr 1fr',
              md: '1fr 1fr',
              lg: '0.5fr 0.5fr 1fr',
            },
            columnGap: { xs: '15px', md: '20px' },
            rowGap: { xs: '15px', md: '20px' },
          }}
        >
          <Box sx={{ minWidth: '150px' }}>
            <SelectFilterField
              value={product}
              label='Товар'
              handleChange={handleFilterProduct}
              selectItem={productList}
            />
          </Box>
          <Box sx={{ minWidth: '150px' }}>
            <SelectFilterField
              value={terminal}
              label='Автомат'
              handleChange={handleFilterTerminal}
              selectItem={terminalsList}
            />
          </Box>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
          />

          <Button
            sx={{
              display: { xs: 'inline-flex', lg: 'none' },
              opacity: isOpen ? '1' : '0',
              transition: 'opacity 0.3s ease-in-out',
            }}
            variant='contained'
            onClick={handleClearFilter}
          >
            Очистить
          </Button>
        </Box>

        <Button
          sx={{
            display: { xs: 'none', lg: 'inline-flex' },
            opacity: isOpen ? '1' : '0',
            transition: 'opacity 0.3s ease-in-out',
          }}
          variant='contained'
          onClick={handleClearFilter}
        >
          Очистить
        </Button>
      </Box>
    </>
  )
}

export default Filter
