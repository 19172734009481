import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import {
  selectProducts,
  selectProductsCount,
  selectProductsIsLoading,
} from 'store/selectors/productsSelector'
import { getProducts } from 'store/reducers/ProductsSlice/ActionCreators'
import { getIngredients } from 'store/reducers/IngredientsSlice/ActionCreators'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { getCategories } from 'store/reducers/CategoriesSlice/ActionCreators'
import { useMediaQuery } from 'react-responsive'
import { useProductFilter } from 'hooks/products'
import useDebounce from 'hooks/useDebounce'

const useProducts = () => {
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    clearPageState,
  } = useTableSort()
  const {
    isIngredient,
    isWarmUp,
    isPopular,
    category,
    searchValue,
    selectedFilterCount,
    handleFilterIsIngredient,
    handleFilterIsWarmUp,
    handleFilterIsPopular,
    handleFilterCategory,
    handleSearch,
    handleClearFilter,
  } = useProductFilter(clearPageState)

  const dispatch = useAppDispatch()
  const count = useAppSelector(selectProductsCount)
  const products = useAppSelector(selectProducts)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const isHidden1000 = useMediaQuery({ maxWidth: 1000 })
  const heightOn1200 = useMediaQuery({ maxWidth: 1200 })
  const productsIsLoading = useAppSelector(selectProductsIsLoading)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)
  const [rows, setRows] = useState<RowData[] | any>([])
  const [modal, setModal] = useState<boolean>(false)
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false)
  const searchDebounceValue = useDebounce<string>(searchValue)

  const headCells: HeadCell[] = [
    {
      id: 'code',
      label: 'Код',
      sort: 'code',
    },
    {
      id: 'name',
      label: 'Название',
      sort: 'name',
    },
    {
      id: 'description',
      label: 'Описание',
      sort: 'description',
    },
    {
      id: 'category',
      label: 'Категория',
      sort: 'category',
    },
    {
      id: 'icon',
      label: 'Изображение',
      sort: '',
    },
  ].filter((item) => {
    if (isHidden1000) {
      return item.id !== 'description' && item.id !== 'id'
    } else {
      return item
    }
  }) as HeadCell[]

  const filterHeight = useMemo(() => {
    if (heightOn1200) {
      return filterIsOpen ? 345 : 215
    }
    return filterIsOpen ? 345 : 275
  }, [filterIsOpen])

  const handleToggleFilter = useCallback(() => {
    setFilterIsOpen(!filterIsOpen)
  }, [filterIsOpen])

  const handleModalOpen = useCallback(() => {
    setModal(true)
  }, [modal])

  const handleModalClose = useCallback(() => {
    setModal(false)
  }, [modal])

  useEffect(() => {
    dispatch(getIngredients({}))
    dispatch(getCategories({}))
  }, [])

  useEffect(() => {
    setRows(
      products.map((item) => {
        return { ...item }
      }),
    )
  }, [products, rowsPerPage, page])

  useEffect(() => {
    dispatch(
      getProducts({
        page: pageQuery,
        page_size: rowsPerPage,
        is_ingredient: isIngredient || undefined,
        is_warm_up: isWarmUp || undefined,
        is_popular: isPopular || undefined,
        category: category || undefined,
        name__icontains: searchDebounceValue || undefined,
        ordering,
      }),
    )
  }, [
    pageQuery,
    rowsPerPage,
    isIngredient,
    isWarmUp,
    isPopular,
    category,
    searchDebounceValue,
    ordering,
  ])

  return {
    headCells,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    modal,
    handleModalOpen,
    handleModalClose,
    isIngredient,
    isWarmUp,
    isPopular,
    category,
    searchValue,
    handleFilterIsIngredient,
    handleFilterIsWarmUp,
    handleFilterIsPopular,
    handleFilterCategory,
    handleSearch,
    productsIsLoading,
    filterIsOpen,
    selectedFilterCount,
    filterHeight,
    handleToggleFilter,
    rowsPerPageOptions,
    labelDisplayedRows,
    handleClearFilter,
  }
}

export default useProducts
