import React, { FC } from 'react'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'
import { TextField } from 'components/generic'
import { FormProvider, useForm } from 'react-hook-form'
import { useAppDispatch } from 'store/hooks/useAppSelector'
import { copyTerminal } from 'store/reducers/TerminalsSlice/ActionCreators'
import { useSnackbar } from 'notistack'

interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  terminalId: number
}

const CopyTerminalModal: FC<ConfirmationModalProps> = ({ isOpen, onClose, terminalId }) => {
  const dispatch = useAppDispatch()
  const methods = useForm()
  const { control, reset } = methods
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (formValue: any) => {
    dispatch(copyTerminal({ ...formValue, terminal_id: terminalId }))
      .unwrap()
      .then((res) => {
        if (res.id) {
          enqueueSnackbar('Автомат успешно скопирован', { variant: 'success' })
          reset()
          onClose()
        }
      })
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '420px',
          width: '100%',
          overflowY: 'inherit',
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', padding: '20px 35px' }}
          component='form'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Typography sx={{ fontSize: '18px', lineHeight: '21px', marginBottom: '20px' }}>
            Копирование автомата
          </Typography>
          <Stack sx={{ marginBottom: '40px' }}>
            <TextField
              control={control}
              name='name'
              label='Название нового автомата'
              size='small'
              required
            />
          </Stack>
          <Stack sx={{ width: '100%' }} direction='row' spacing={2}>
            <Button sx={{ color: 'success.main' }} variant='outlined' color='success' type='submit'>
              Копировать
            </Button>
            <Button sx={{ flexGrow: '1' }} variant='outlined' onClick={onClose}>
              Отмена
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default CopyTerminalModal
