import React, { FC, memo } from 'react'
import { Stack } from '@mui/material'
import { TextField, SelectField } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectTerminalsList } from 'store/selectors/terminalsSelector'

interface GroupFormProps {
  name?: string
  mainTerminal?: number
  externalId?: number
}

const GroupForm: FC<GroupFormProps> = ({ name, mainTerminal, externalId }) => {
  const { control } = useFormContext()
  const terminalsList = useAppSelector(selectTerminalsList)

  return (
    <Stack sx={{ marginBottom: '16px' }} direction='column' spacing={2}>
      <TextField
        control={control}
        name='name'
        label='Название группы *'
        size='small'
        defaultValue={name || ''}
        required
      />
      <SelectField
        control={control}
        name='main_terminal'
        label='Главный автомат'
        size='small'
        selectItem={terminalsList}
        defaultValue={mainTerminal || ''}
      />
      <TextField
        control={control}
        name='external_id'
        label='Внешний идентификатор'
        size='small'
        defaultValue={externalId || ''}
        type='number'
      />
    </Stack>
  )
}

export default memo(GroupForm)
