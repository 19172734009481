import React, { FC, memo } from 'react'
import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { TextField } from 'components/generic'

interface UserFormProps {
  username?: string
  phone?: string
  email?: string
  firstName?: string
  lastName?: string
}

const UserForm: FC<UserFormProps> = ({ username, phone, email, firstName, lastName }) => {
  const { control } = useFormContext()

  return (
    <Stack direction='column' spacing={{ xs: 1, sm: 2 }}>
      <TextField
        control={control}
        name='username'
        label='Имя пользователя *'
        size='small'
        defaultValue={username || ''}
        required
      />
      <TextField
        control={control}
        name='first_name'
        label='Имя'
        size='small'
        defaultValue={firstName || ''}
      />
      <TextField
        control={control}
        name='last_name'
        label='Фамилия'
        size='small'
        defaultValue={lastName || ''}
      />
      <TextField
        control={control}
        name='phone'
        label='Телефон *'
        size='small'
        defaultValue={phone || ''}
        required
      />
      <TextField
        control={control}
        name='email'
        label='Почта *'
        size='small'
        defaultValue={email || ''}
        required
      />
    </Stack>
  )
}

export default memo(UserForm)
