import { CONTAINER_TYPE } from 'constants/Labels'

const useContainerTypeLabel = (type: string) => {
  switch (type) {
    case 'bulk':
      return CONTAINER_TYPE.TYPE_BULK
    case 'liquid':
      return CONTAINER_TYPE.TYPE_LIQUID
    case 'waste':
      return CONTAINER_TYPE.TYPE_WASTE
    default:
      return CONTAINER_TYPE.TYPE_PIECE
  }
}

export default useContainerTypeLabel
