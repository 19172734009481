import React, { FC } from 'react'
import { Box, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { Filter, TableRow } from 'components/products'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { ProductModal } from 'components/modals'
import { useProducts } from 'hooks/products'
import { RowData } from 'types/Table'

const Products: FC = () => {
  const {
    headCells,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    modal,
    handleModalOpen,
    handleModalClose,
    isIngredient,
    isWarmUp,
    isPopular,
    category,
    searchValue,
    handleFilterIsIngredient,
    handleFilterIsWarmUp,
    handleFilterIsPopular,
    handleFilterCategory,
    handleSearch,
    productsIsLoading,
    filterIsOpen,
    selectedFilterCount,
    filterHeight,
    handleToggleFilter,
    rowsPerPageOptions,
    labelDisplayedRows,
    handleClearFilter,
  } = useProducts()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Toolbar
          title='Список товаров'
          buttonLabel='+ Добавить товар'
          handleModalOpen={handleModalOpen}
          isFilterButton
          isOpen={filterIsOpen}
          selectedFilterCount={selectedFilterCount}
          handleToggleFilter={handleToggleFilter}
          isLoadingData={productsIsLoading}
        />
        <Filter
          isOpen={filterIsOpen}
          isIngredient={isIngredient}
          isWarmUp={isWarmUp}
          isPopular={isPopular}
          category={category}
          searchValue={searchValue}
          handleFilterIsIngredient={handleFilterIsIngredient}
          handleFilterIsWarmUp={handleFilterIsWarmUp}
          handleFilterIsPopular={handleFilterIsPopular}
          handleFilterCategory={handleFilterCategory}
          handleSearch={handleSearch}
          handleClearFilter={handleClearFilter}
        />
        <TableContainer
          sx={{
            height: { xs: 'auto', sm: `calc(100vh - ${filterHeight}px)` },
            transition: 'all 0.3s ease-in-out',
            zIndex: '20',
          }}
        >
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  key={row.id}
                  id={row.id}
                  code={row.code}
                  category={row.category}
                  name={row.name}
                  description={row.description}
                  icon={row.icon}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            padding: '10px 24px',
            borderTop: '1px solid',
            borderColor: 'divider',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </Stack>
      </Box>
      <ProductModal isOpen={modal} onClose={handleModalClose} modalType='create' />
    </>
  )
}

export default Products
