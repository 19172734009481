import { createSelector } from '@reduxjs/toolkit'
import { Product } from 'types/Products'
import { RootState } from '../store'
import { selectProducts } from './productsSelector'

const selectIngredientsData = (state: RootState) => state.ingredients
/**
 * Returns groups list
 */
export const selectIngredients = createSelector(
  [selectIngredientsData],
  (ingredients) => ingredients.ingredientsData.results,
)
/**
 * Returns groups count
 */
export const selectIngredientsCount = createSelector(
  [selectIngredientsData],
  (ingredients) => ingredients.ingredientsData.count || 0,
)
/**
 * Returns ingredients list
 */
export const selectIngredientsList = createSelector([selectProducts], (products) => {
  const isIngredientProducts = products.filter((item: Product) => item.is_ingredient === true)

  return isIngredientProducts.map((item: Product) => {
    return {
      label: item.name,
      value: item.id,
    }
  })
})
/**
 * Returns groups count
 */
export const selectIngredientsIsLoading = createSelector(
  [selectIngredientsData],
  (ingredients) => ingredients.isLoading,
)
