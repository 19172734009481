import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { getMarketplaceList } from 'store/reducers/MarketplaceSlice/ActionCreators'
import {
  selectMarketplaceCount,
  selectMarketplaceIsLoading,
  selectMarketplaces,
} from 'store/selectors/marketplaceSelectors'
import { getUsers } from 'store/reducers/UsersSlice/ActionCreators'
import { useMediaQuery } from 'react-responsive'

const useMarketplaces = () => {
  const dispatch = useAppDispatch()
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector(selectMarketplaceCount)
  const marketplaceList = useAppSelector(selectMarketplaces)
  const [marketplaceModal, setMarketplaceModal] = useState<boolean>(false)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const marketPlaceIsLoading = useAppSelector<boolean>(selectMarketplaceIsLoading)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'Название точки продаж',
      sort: 'name',
    },
    {
      id: 'address',
      label: 'Адрес',
      sort: 'address',
    },
  ]

  useEffect(() => {
    setRows(
      marketplaceList.map((item) => {
        return { ...item }
      }),
    )
  }, [marketplaceList, rowsPerPage, page])

  useEffect(() => {
    dispatch(getMarketplaceList({ page: pageQuery, page_size: rowsPerPage, ordering }))
  }, [page, rowsPerPage, pageQuery, ordering])

  useEffect(() => {
    dispatch(getUsers({}))
  }, [])

  const handleMarketplaceModalOpen = useCallback(() => {
    setMarketplaceModal(true)
  }, [marketplaceModal])

  const handleMarketplaceModalClose = useCallback(() => {
    setMarketplaceModal(false)
  }, [marketplaceModal])

  return {
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    headCells,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    marketPlaceIsLoading,
    marketplaceModal,
    handleMarketplaceModalOpen,
    handleMarketplaceModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useMarketplaces
