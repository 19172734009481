import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import {
  selectUsersCount,
  selectUsersIsLoading,
  selectUsersList,
} from 'store/selectors/usersSelectors'
import { getUsers } from 'store/reducers/UsersSlice/ActionCreators'
import { useMediaQuery } from 'react-responsive'

const useUsers = () => {
  const dispatch = useAppDispatch()
  const {
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector(selectUsersCount)
  const usersList = useAppSelector(selectUsersList)
  const [userModal, setUserModal] = useState<boolean>(false)
  const isHidden800 = useMediaQuery({ maxWidth: 800 })
  const usersIsLoading = useAppSelector<boolean>(selectUsersIsLoading)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden800)

  const headCells: HeadCell[] = [
    {
      id: 'username',
      label: 'Имя пользователя',
      sort: '',
    },
    {
      id: 'email',
      label: 'E-mail',
      sort: '',
    },
    {
      id: 'first_name',
      label: 'Имя',
      sort: '',
    },
    {
      id: 'last_name',
      label: 'Фамилия',
      sort: '',
    },
  ].filter((item) => {
    if (isHidden800) {
      return item.id !== 'last_name' && item.id !== 'email'
    } else {
      return item
    }
  }) as HeadCell[]

  useEffect(() => {
    setRows(
      usersList.map((item) => {
        return { ...item }
      }),
    )
  }, [usersList, rowsPerPage, page])

  useEffect(() => {
    dispatch(getUsers({ page: pageQuery, page_size: rowsPerPage }))
  }, [page, rowsPerPage, pageQuery])

  const handleUserModalOpen = useCallback(() => {
    setUserModal(true)
  }, [userModal])

  const handleUserModalClose = useCallback(() => {
    setUserModal(false)
  }, [userModal])

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    headCells,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    usersIsLoading,
    userModal,
    handleUserModalOpen,
    handleUserModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useUsers
