import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { selectIngredients } from './ingredientsSelectors'

const selectContainersData = (state: RootState) => state.containers
/**
 * Returns containers list
 */
export const selectContainers = createSelector(
  [selectContainersData],
  (containers) => containers.containersData.results,
)
/**
 * Returns container
 */
export const selectContainer = createSelector(
  [selectContainersData],
  (containers) => containers.container,
)
/**
 * Returns containers count
 */
export const selectContainersCount = createSelector(
  [selectContainersData],
  (containers) => containers.containersData.count || 0,
)
/**
 * Returns containers isLoading
 */
export const selectContainersDataIsLoading = createSelector(
  [selectContainersData],
  (containers) => containers.containersIsLoading,
)
/**
 * Returns container is Loading
 */
export const selectContainerIsLoading = createSelector(
  [selectContainersData],
  (containers) => containers.containerIsLoading,
)
/**
 * Returns containers errors
 */
export const selectContainersErrors = createSelector(
  [selectContainersData],
  (containers) => containers.error,
)
/**
 * Returns containers fields
 */
export const selectContainersFiels = createSelector(
  [selectContainersData, selectIngredients],
  (containers, ingredients) => {
    if (ingredients.length <= 0) {
      const a = ingredients.map((item) => {
        return {
          key: item.ingredient,
          value: containers.containersData.results.map((item) => item.code).join(','),
        }
      })
      return a
    } else {
      return []
    }
  },
)
