import React, { FC } from 'react'
import { MenuItem, TextField } from '@mui/material'
import { SelectFieldType } from 'types/Other'

export interface SelectFilterField {
  value: string | null
  handleChange: (event: { target: { value: string } }) => void
  selectItem: SelectFieldType[]
  label: string
}

const SelectFilterField: FC<SelectFilterField> = ({ value, handleChange, selectItem, label }) => {
  return (
    <TextField
      sx={{
        '& .MuiInputLabel-root': {
          color: '#BDBDBD',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'secondary.main',
        },
        '& .MuiInputLabel-shrink': {
          color: 'secondary.main',
          backgroundColor: 'primary.main',
          padding: '0px 5px',
          left: '-5px',
          top: '2px',
          fontWeight: '600',
          fontSize: '13px',
          lineHeight: '20px',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '6px',
          backgroundColor: 'secondary.main',
          '& fieldset': {
            border: '2px solid',
            borderColor: 'secondary.main',
            '& legend': {
              width: '38px',
            },
          },
          '&:hover fieldset': {
            border: '1px solid',
            borderColor: 'primary.main',
          },
          '&.Mui-focused fieldset': {
            border: '2px solid',
            borderColor: 'secondary.main',
          },
        },
      }}
      select
      fullWidth
      label={label}
      variant='outlined'
      value={value}
      onChange={handleChange}
      size='small'
      SelectProps={{
        MenuProps: { PaperProps: { sx: { maxHeight: '200px' } } },
      }}
    >
      <MenuItem value=''>-</MenuItem>
      {selectItem.map((item: SelectFieldType) => (
        <MenuItem value={item.value} key={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectFilterField
