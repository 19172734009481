import React, { FC } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Stack, TextField, TextFieldProps } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ruLocale from 'dayjs/locale/ru'

interface DateRangePickerProps {
  startDate: string | null
  endDate: string | null
  handleStartDate: (arg: string | null) => void
  handleEndDate: (arg: string | null) => void
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
}) => {
  return (
    <Stack direction='row' spacing={2}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ruLocale}>
        <DesktopDatePicker
          inputFormat='DD/MM/YYYY'
          value={startDate}
          label='От'
          onChange={(newValue) => {
            handleStartDate(newValue)
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              sx={{
                '& .MuiInputLabel-root': {
                  color: '#BDBDBD',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'secondary.main',
                },
                '& .MuiInputLabel-shrink': {
                  color: 'secondary.main',
                  backgroundColor: 'primary.main',
                  padding: '0px 5px',
                  left: '-5px',
                  top: '2px',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '20px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  backgroundColor: 'secondary.main',
                  '& fieldset': {
                    border: '2px solid',
                    borderColor: 'secondary.main',
                    '& legend': {
                      width: '38px',
                    },
                  },
                  '&:hover fieldset': {
                    border: '0px solid',
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid',
                    borderColor: 'secondary.main',
                  },
                },
              }}
              size='small'
              fullWidth
              {...params}
            />
          )}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat='DD/MM/YYYY'
          value={endDate}
          label='До'
          minDate={startDate}
          onChange={(newValue) => {
            handleEndDate(newValue)
          }}
          disabled={!startDate}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              sx={{
                '& .MuiInputLabel-root': {
                  color: '#BDBDBD',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'secondary.main',
                },
                '& .MuiInputLabel-shrink': {
                  color: 'secondary.main',
                  backgroundColor: 'primary.main',
                  padding: '0px 5px',
                  left: '-5px',
                  top: '2px',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '20px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '6px',
                  backgroundColor: 'secondary.main',
                  '& fieldset': {
                    border: '2px solid',
                    borderColor: 'secondary.main',
                    '& legend': {
                      width: '38px',
                    },
                  },
                  '&:hover fieldset': {
                    border: '0px solid',
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused fieldset': {
                    border: '2px solid',
                    borderColor: 'secondary.main',
                  },
                },
              }}
              size='small'
              fullWidth
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </Stack>
  )
}

export default DateRangePicker
