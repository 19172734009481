import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  authReducer,
  statReducer,
  usersReducer,
  companyReducer,
  marketplaceReducer,
  productsReducer,
  categoriesReducer,
  ingredientsReducer,
  groupsReducer,
  terminalReducer,
  registerReducer,
  containersReducer,
  cellReducer,
  containerBatchReducer,
  productBatchReducer,
  ordersReducer,
} from 'store/reducers'

const userPersistConfig = {
  key: 'user',
  version: 1,
  storage,
  whitelist: ['user'],
}

const rootReducer = combineReducers({
  registration: registerReducer,
  auth: authReducer,
  users: persistReducer(userPersistConfig, usersReducer),
  company: companyReducer,
  stat: statReducer,
  marketplace: marketplaceReducer,
  products: productsReducer,
  productBatch: productBatchReducer,
  categories: categoriesReducer,
  ingredients: ingredientsReducer,
  groups: groupsReducer,
  terminals: terminalReducer,
  containers: containersReducer,
  containerBatch: containerBatchReducer,
  cells: cellReducer,
  orders: ordersReducer,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
