import { createSelector } from '@reduxjs/toolkit'
import { Terminal } from 'types/Terminals'
import { RootState } from '../store'

const selectTerminalsData = (state: RootState) => state.terminals
/**
 * Returns terminal list
 */
export const selectTerminals = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminalsData.results,
)
/**
 * Returns terminals count
 */
export const selectTerminalsCount = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminalsData.count || 0,
)
/**
 * Returns terminals list for select textfield
 */
export const selectTerminalsList = createSelector([selectTerminals], (terminals) => {
  return terminals.map((item: Terminal) => {
    return {
      label: item.address,
      value: item.id,
    }
  })
})
/**
 * Returns terminal data
 */
export const selectTerminal = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminal,
)
/**
 * Returns terminal name
 */
export const selectTerminalName = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminal.address,
)
/**
 * Returns terminal isLoading
 */
export const selectTerminalIsLoading = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminalIsLoading,
)
/**
 * Returns terminals isLoading
 */
export const selectTerminaslIsLoading = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminalsDataIsLoading,
)
/**
 * Returns terminal address
 */
export const selectTerminalAddress = (terminalId: string | undefined | number) =>
  createSelector(
    [selectTerminalsData],
    (terminals) => {
      const a = terminals.terminalsData.results.filter((item) => item.id === Number(terminalId))[0]

      return a
    },

    // terminals.terminalsData.results.filter((item) => item.id === Number(terminalId))[0]
    // ?.address || '',
  )
/**
 * Returns terminal models
 */
export const selectTerminalModels = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminalModels,
)
/**
 * Returns terminal types
 */
export const selectTerminalTypes = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.terminalTypes,
)
/**
 * Returns terminal models list
 */
export const selectTerminalModelsList = createSelector([selectTerminalsData], (terminals) =>
  terminals.terminalModels.map((model) => {
    return {
      label: model.name,
      value: model.id,
    }
  }),
)
/**
 * Returns terminal types list
 */
export const selectTerminalTypesList = createSelector([selectTerminalTypes], (types) =>
  types.map((type) => {
    return {
      label: type.name,
      value: type.id,
    }
  }),
)
/**
 * Returns terminal model type
 */
export const selectModelType = (id: string | number) =>
  createSelector(
    [selectTerminalsData],
    (terminals) => terminals.terminalModels.filter((model) => model.id === id)[0]?.type || '',
  )
/**
 * Returns terminal errors
 */
export const selectTerminalErrors = createSelector(
  [selectTerminalsData],
  (terminals) => terminals.error,
)
/**
 * Returns terminal models list for filter
 */
export const selectTerminalModelsFilterList = createSelector([selectTerminalsData], (terminals) =>
  terminals.terminalModels.map((model) => {
    return {
      label: model.name,
      value: model.type,
    }
  }),
)
