import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='167'
      height='43'
      viewBox='0 0 167 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.74614 8.726C6.91823 7.01918 10.2352 6.47465 12.6716 8.77858C14.4087 10.4213 14.9855 12.4035 15.3283 13.5819C15.383 13.7699 15.4318 13.9374 15.4783 14.0799C15.5959 14.4397 15.8301 14.6442 15.9632 14.6479C15.9632 14.6479 16.0017 14.6933 16.3975 14.6375C16.6979 14.5951 17.0711 14.394 17.7969 13.5601C17.9668 13.365 18.1674 13.1185 18.393 12.8411C18.9692 12.1332 19.7089 11.2242 20.5185 10.4553C21.7459 9.2897 23.3328 8.20484 25.5445 7.61692C27.736 7.0344 30.4189 6.97192 33.8039 7.64695C35.8824 8.06141 37.5785 8.86788 38.7882 10.1308C40.0201 11.4171 40.5892 13.0121 40.6849 14.6793C40.8677 17.8676 39.3386 21.4807 37.3068 24.7523C35.2282 28.0993 32.4195 31.412 29.591 34.0796C26.8063 36.7059 23.8183 38.8819 21.349 39.7375C18.6377 40.6771 16.0905 40.1287 13.8927 38.739C11.7588 37.3897 9.934 35.2459 8.40847 32.8747C5.35127 28.1228 3.15307 21.9148 2.01421 17.3471C1.09633 13.6656 2.50588 10.4864 4.74614 8.726ZM7.04063 11.7344C5.90655 12.6255 5.08946 14.2972 5.62539 16.4467C6.70546 20.7786 8.77163 26.5464 11.5204 30.8189C12.8979 32.9599 14.3615 34.5904 15.8384 35.5242C17.2513 36.4176 18.6374 36.661 20.1188 36.1477C21.8421 35.5505 24.3704 33.8103 27.0445 31.2883C29.6748 28.8077 32.2675 25.7402 34.1516 22.7063C36.0826 19.5969 37.0777 16.8283 36.9651 14.8654C36.913 13.957 36.6295 13.284 36.1313 12.7639C35.6108 12.2204 34.6992 11.682 33.1197 11.367C30.1384 10.7725 28.0409 10.8805 26.5174 11.2855C25.0141 11.6851 23.9515 12.4048 23.0743 13.2378C22.4377 13.8424 21.9746 14.4141 21.4718 15.035C21.2024 15.3678 20.9215 15.7146 20.5963 16.0882C19.7868 17.0182 18.6491 18.152 16.9425 18.3928C15.6989 18.5682 14.477 18.457 13.4771 17.6837C12.5454 16.9631 12.1637 15.9193 11.9437 15.2463C11.8572 14.9815 11.7805 14.7297 11.7072 14.4889C11.3537 13.3284 11.0782 12.4237 10.1484 11.5445C9.44787 10.882 8.24287 10.7897 7.04063 11.7344Z'
        fill='url(#paint0_linear_747_170)'
      />
      <path
        d='M53.3838 12.9919H60.2671C61.547 12.9919 62.5615 13.2729 63.3107 13.8348C64.0756 14.3967 64.458 15.1615 64.458 16.1292C64.458 17.4403 63.7946 18.3847 62.4679 18.9622C64.1068 19.4616 64.9262 20.5152 64.9262 22.1229C64.9262 23.1842 64.5048 24.0193 63.6619 24.628C62.8347 25.2368 61.6953 25.5411 60.2437 25.5411H53.3838V12.9919ZM56.8957 18.0257H59.4008C59.8847 18.0257 60.2515 17.9086 60.5012 17.6745C60.7666 17.4403 60.8992 17.136 60.8992 16.7614C60.8992 16.4336 60.7744 16.1605 60.5246 15.9419C60.2905 15.7234 59.9315 15.6142 59.4477 15.6142H56.8957V18.0257ZM56.8957 22.755H59.7052C60.7822 22.755 61.3207 22.3336 61.3207 21.4907C61.3207 20.6791 60.751 20.2733 59.6116 20.2733H56.8957V22.755Z'
        fill='#151515'
      />
      <path
        d='M73.369 25.8221C71.4648 25.8221 69.8962 25.2211 68.6631 24.0193C67.43 22.8018 66.8135 21.2254 66.8135 19.2899C66.8135 17.4638 67.3988 15.9185 68.5694 14.6542C69.7401 13.39 71.2229 12.7578 73.0179 12.7578C75.0001 12.7578 76.5141 13.4134 77.5599 14.7245C78.6057 16.0356 79.1286 17.6511 79.1286 19.5709C79.1286 19.7582 79.1129 20.0704 79.0817 20.5074H70.3488C70.5205 21.3034 70.8717 21.9122 71.4024 22.3336C71.9331 22.755 72.6042 22.9657 73.4159 22.9657C74.5241 22.9657 75.5386 22.5365 76.4595 21.678L78.4964 23.4808C77.2321 25.0417 75.523 25.8221 73.369 25.8221ZM70.302 18.2832H75.6635C75.5542 17.4716 75.2655 16.8238 74.7972 16.34C74.3446 15.8561 73.7514 15.6142 73.0179 15.6142C72.2999 15.6142 71.6989 15.8561 71.2151 16.34C70.7468 16.8082 70.4425 17.456 70.302 18.2832Z'
        fill='#151515'
      />
      <path
        d='M93.2764 12.9919V25.5411H89.7177V20.7415H85.3395V25.5411H81.7808V12.9919H85.3395V17.8149H89.7177V12.9919H93.2764Z'
        fill='#151515'
      />
      <path
        d='M100.065 22.4975H104.349V16.0356H101.001V16.3165C100.97 19.0168 100.658 21.0771 100.065 22.4975ZM106.199 25.5411H98.3324L98.0749 28.4911H95.078V22.4975H96.436C97.2476 20.7649 97.6534 17.9554 97.6534 14.0689V12.9919H107.908V22.4975H109.617L109.196 28.4911H106.199V25.5411Z'
        fill='#151515'
      />
      <path
        d='M113.048 12.9919H123.139V25.5411H119.58V16.0356H116.396C116.396 17.6901 116.334 19.087 116.209 20.2264C116.084 21.3659 115.912 22.2946 115.694 23.0126C115.475 23.7305 115.171 24.2846 114.781 24.6749C114.39 25.0651 113.969 25.3304 113.516 25.4709C113.064 25.6114 112.502 25.6816 111.831 25.6816C111.191 25.6816 110.59 25.6114 110.028 25.4709L110.262 22.5911C110.543 22.638 110.793 22.6614 111.011 22.6614C111.386 22.6614 111.682 22.5755 111.901 22.4038C112.119 22.2165 112.322 21.8107 112.509 21.1864C112.712 20.5464 112.853 19.5709 112.931 18.2598C113.009 16.9331 113.048 15.1771 113.048 12.9919Z'
        fill='#151515'
      />
      <path
        d='M129.878 25.7752C128.645 25.7752 127.615 25.4319 126.788 24.7451C125.976 24.0583 125.57 23.1062 125.57 21.8887C125.57 20.562 126.023 19.5631 126.928 18.8919C127.849 18.2208 129.082 17.8852 130.628 17.8852C131.673 17.8852 132.696 18.0569 133.695 18.4003V18.1896C133.695 16.6599 132.797 15.8951 131.002 15.8951C129.925 15.8951 128.77 16.1214 127.537 16.5741L126.647 13.8582C128.177 13.187 129.8 12.8515 131.517 12.8515C133.406 12.8515 134.818 13.3197 135.755 14.2562C136.66 15.1615 137.113 16.496 137.113 18.2598V25.5411H133.671V24.1832C132.719 25.2446 131.455 25.7752 129.878 25.7752ZM130.955 23.3169C131.783 23.3169 132.454 23.114 132.969 22.7082C133.484 22.2868 133.741 21.7561 133.741 21.1161V20.484C133.07 20.1718 132.313 20.0157 131.47 20.0157C130.69 20.0157 130.081 20.1718 129.644 20.484C129.223 20.7805 129.012 21.2176 129.012 21.7951C129.012 22.2633 129.192 22.638 129.551 22.9189C129.91 23.1842 130.378 23.3169 130.955 23.3169Z'
        fill='#151515'
      />
      <path
        d='M146.426 22.755C147.316 22.755 148.042 22.4507 148.604 21.8419C149.166 21.2176 149.447 20.445 149.447 19.5241C149.447 18.6188 149.15 17.8462 148.557 17.2062C147.979 16.5663 147.254 16.2463 146.38 16.2463C145.49 16.2463 144.764 16.5663 144.202 17.2062C143.64 17.8306 143.359 18.6032 143.359 19.5241C143.359 20.4294 143.648 21.1942 144.226 21.8185C144.819 22.4428 145.552 22.755 146.426 22.755ZM146.38 25.8221C144.257 25.8221 142.634 25.1821 141.51 23.9022C140.386 22.6067 139.824 20.7493 139.824 18.33C139.824 17.4716 139.855 16.7146 139.918 16.059C139.98 15.3878 140.113 14.6777 140.316 13.9284C140.534 13.1792 140.823 12.5393 141.182 12.0086C141.557 11.4779 142.072 11.0019 142.727 10.5804C143.398 10.159 144.187 9.86245 145.092 9.69076L151.811 8.44989L152.373 11.2594L145.49 12.6173C143.586 13.0076 142.571 13.9909 142.446 15.5673C142.743 15.0523 143.289 14.5762 144.085 14.1392C144.881 13.6865 145.857 13.4602 147.012 13.4602C148.635 13.4602 150.032 14.0377 151.203 15.1927C152.373 16.3478 152.958 17.7759 152.958 19.4772C152.958 21.241 152.334 22.7394 151.085 23.9725C149.837 25.2055 148.268 25.8221 146.38 25.8221Z'
        fill='#151515'
      />
      <path
        d='M161.417 25.8221C159.544 25.8221 157.984 25.1899 156.735 23.9256C155.502 22.6614 154.885 21.1161 154.885 19.2899C154.885 17.4794 155.51 15.9419 156.758 14.6777C158.007 13.3978 159.576 12.7578 161.464 12.7578C163.478 12.7578 165.14 13.4914 166.451 14.9586L164.367 17.0657C163.493 16.2385 162.518 15.8249 161.441 15.8249C160.567 15.8249 159.841 16.1605 159.263 16.8316C158.686 17.5028 158.397 18.3222 158.397 19.2899C158.397 20.2733 158.686 21.1005 159.263 21.7717C159.857 22.4272 160.629 22.755 161.581 22.755C162.565 22.755 163.525 22.3102 164.461 21.4205L166.545 23.5276C165.858 24.2768 165.124 24.8465 164.344 25.2368C163.564 25.627 162.588 25.8221 161.417 25.8221Z'
        fill='#151515'
      />
      <path
        d='M63.2969 39.0211H65.4114C65.9805 39.0211 66.4218 38.8901 66.7355 38.6282C67.0523 38.3663 67.2108 38.0042 67.2108 37.5418V37.5321C67.2108 37.3058 67.159 37.1102 67.0556 36.9453C66.9553 36.7772 66.8179 36.6414 66.6433 36.5379C66.4687 36.4344 66.2683 36.3698 66.0419 36.3439V36.2857C66.3071 36.2307 66.5269 36.0949 66.7015 35.8783C66.8761 35.6617 66.9634 35.4127 66.9634 35.1314V35.1217C66.9634 34.7176 66.8244 34.3958 66.5463 34.1566C66.2715 33.9173 65.8867 33.7977 65.392 33.7977H63.2969V39.0211ZM63.9371 38.4827V36.6155H65.2611C65.6782 36.6155 65.9999 36.6899 66.2262 36.8386C66.4526 36.9841 66.5657 37.212 66.5657 37.5224V37.5321C66.5657 37.8393 66.4655 38.0753 66.265 38.2402C66.0678 38.4019 65.7752 38.4827 65.3872 38.4827H63.9371ZM63.9371 36.082V34.3312H65.3096C65.6459 34.3312 65.8997 34.4104 66.071 34.5688C66.2424 34.724 66.3281 34.9277 66.3281 35.1799V35.1896C66.3281 35.4709 66.2327 35.6908 66.0419 35.8492C65.8544 36.0044 65.578 36.082 65.2126 36.082H63.9371Z'
        fill='#FFB35A'
      />
      <path
        d='M70.7803 39.1083C71.0681 39.1083 71.33 39.0728 71.566 39.0017C71.8053 38.9273 72.0154 38.8287 72.1965 38.7058C72.3775 38.5797 72.5263 38.4374 72.6427 38.279C72.7623 38.1173 72.848 37.9476 72.8997 37.7698L72.9143 37.731H72.2692L72.2547 37.7698C72.1965 37.9023 72.1011 38.0284 71.9685 38.1481C71.836 38.2645 71.6695 38.3582 71.469 38.4294C71.2718 38.5005 71.047 38.5361 70.7949 38.5361C70.4424 38.5361 70.1369 38.4552 69.8782 38.2936C69.6196 38.1319 69.4191 37.9007 69.2768 37.6C69.1346 37.2993 69.0634 36.9404 69.0634 36.5233V36.2808C69.0634 35.8573 69.1346 35.4968 69.2768 35.1993C69.4223 34.8986 69.6196 34.6707 69.8685 34.5155C70.1175 34.3603 70.4036 34.2827 70.727 34.2827C71.047 34.2827 71.3283 34.3587 71.5708 34.5106C71.8166 34.6626 72.0073 34.8873 72.1431 35.1848C72.2822 35.479 72.3517 35.8444 72.3517 36.2808V36.5233L72.6669 36.0529H68.7433V36.5767H72.9919V36.3051C72.9919 35.791 72.8997 35.34 72.7154 34.952C72.5344 34.5607 72.2741 34.2568 71.9346 34.0402C71.5983 33.8203 71.1958 33.7104 70.727 33.7104C70.2614 33.7104 69.854 33.8236 69.5048 34.0499C69.1588 34.273 68.8904 34.5882 68.6997 34.9956C68.5089 35.3998 68.4135 35.8735 68.4135 36.4166V36.4263C68.4135 36.9728 68.5089 37.4464 68.6997 37.8474C68.8904 38.2483 69.162 38.5587 69.5145 38.7786C69.8701 38.9984 70.2921 39.1083 70.7803 39.1083Z'
        fill='#FFB35A'
      />
      <path
        d='M77.9921 39.0211H78.6323V33.7977H77.9921V36.0432H75.1016V33.7977H74.4614V39.0211H75.1016V36.5767H77.9921V39.0211Z'
        fill='#FFB35A'
      />
      <path
        d='M80.3055 40.4033V39.0211H84.6899V40.4033H85.2864V38.4827H84.4425V33.7977H80.9748L80.7663 36.7513C80.7404 37.1425 80.6774 37.4982 80.5771 37.8183C80.4769 38.1351 80.3298 38.3566 80.1358 38.4827H79.709V40.4033H80.3055ZM83.8023 38.4827H80.8924V38.4245C81.0314 38.2531 81.1381 38.022 81.2125 37.731C81.2868 37.44 81.337 37.1215 81.3628 36.7755L81.5423 34.3312H83.8023V38.4827Z'
        fill='#FFB35A'
      />
      <path
        d='M86.4601 39.0211H87.1003L90.1557 34.5737L89.7047 34.8162H90.0539V39.0211H90.6941V33.7977H90.0539L86.9984 38.2402L87.4495 38.0026H87.1003V33.7977H86.4601V39.0211Z'
        fill='#FFB35A'
      />
      <path
        d='M95.9562 39.0211H96.5964V33.7977H95.9562V36.0432H93.0657V33.7977H92.4255V39.0211H93.0657V36.5767H95.9562V39.0211Z'
        fill='#FFB35A'
      />
      <path d='M101.635 34.3312V33.7977H98.323V39.0211H98.9632V34.3312H101.635Z' fill='#FFB35A' />
      <path
        d='M105.637 39.0211H106.277V34.3312H109.119V39.0211H109.759V33.7977H105.637V39.0211Z'
        fill='#FFB35A'
      />
      <path
        d='M113.6 39.1083C114.088 39.1083 114.509 39 114.861 38.7834C115.217 38.5635 115.49 38.2515 115.681 37.8474C115.875 37.4432 115.972 36.9647 115.972 36.4118V36.4021C115.972 35.8492 115.875 35.3707 115.681 34.9665C115.49 34.5624 115.217 34.252 114.861 34.0353C114.509 33.8187 114.088 33.7104 113.6 33.7104C113.115 33.7104 112.695 33.8187 112.339 34.0353C111.984 34.252 111.709 34.5624 111.515 34.9665C111.324 35.3707 111.229 35.8492 111.229 36.4021V36.4118C111.229 36.9647 111.324 37.4432 111.515 37.8474C111.709 38.2515 111.984 38.5635 112.339 38.7834C112.695 39 113.115 39.1083 113.6 39.1083ZM113.6 38.5409C113.241 38.5409 112.933 38.4552 112.674 38.2839C112.418 38.1125 112.221 37.8684 112.082 37.5515C111.946 37.2314 111.879 36.8515 111.879 36.4118V36.4021C111.879 35.9591 111.946 35.5792 112.082 35.2624C112.221 34.9455 112.418 34.703 112.674 34.5349C112.933 34.3635 113.241 34.2778 113.6 34.2778C113.959 34.2778 114.266 34.3635 114.522 34.5349C114.78 34.703 114.978 34.9471 115.113 35.2672C115.252 35.5841 115.322 35.9624 115.322 36.4021V36.4118C115.322 36.8515 115.252 37.2314 115.113 37.5515C114.978 37.8684 114.78 38.1125 114.522 38.2839C114.266 38.4552 113.959 38.5409 113.6 38.5409Z'
        fill='#FFB35A'
      />
      <path d='M117.325 36.4942H120.56V35.8977H117.325V36.4942Z' fill='#FFB35A' />
      <path
        d='M125.706 39.0211H126.346V33.7977H125.706V36.0432H122.815V33.7977H122.175V39.0211H122.815V36.5767H125.706V39.0211Z'
        fill='#FFB35A'
      />
      <path
        d='M130.187 39.1083C130.675 39.1083 131.096 39 131.448 38.7834C131.804 38.5635 132.077 38.2515 132.268 37.8474C132.462 37.4432 132.559 36.9647 132.559 36.4118V36.4021C132.559 35.8492 132.462 35.3707 132.268 34.9665C132.077 34.5624 131.804 34.252 131.448 34.0353C131.096 33.8187 130.675 33.7104 130.187 33.7104C129.702 33.7104 129.282 33.8187 128.926 34.0353C128.57 34.252 128.296 34.5624 128.102 34.9665C127.911 35.3707 127.815 35.8492 127.815 36.4021V36.4118C127.815 36.9647 127.911 37.4432 128.102 37.8474C128.296 38.2515 128.57 38.5635 128.926 38.7834C129.282 39 129.702 39.1083 130.187 39.1083ZM130.187 38.5409C129.828 38.5409 129.519 38.4552 129.261 38.2839C129.005 38.1125 128.808 37.8684 128.669 37.5515C128.533 37.2314 128.465 36.8515 128.465 36.4118V36.4021C128.465 35.9591 128.533 35.5792 128.669 35.2624C128.808 34.9455 129.005 34.703 129.261 34.5349C129.519 34.3635 129.828 34.2778 130.187 34.2778C130.546 34.2778 130.853 34.3635 131.108 34.5349C131.367 34.703 131.564 34.9471 131.7 35.2672C131.839 35.5841 131.909 35.9624 131.909 36.4021V36.4118C131.909 36.8515 131.839 37.2314 131.7 37.5515C131.564 37.8684 131.367 38.1125 131.108 38.2839C130.853 38.4552 130.546 38.5409 130.187 38.5409Z'
        fill='#FFB35A'
      />
      <path
        d='M134.028 39.0211H136.143C136.712 39.0211 137.153 38.8901 137.467 38.6282C137.784 38.3663 137.942 38.0042 137.942 37.5418V37.5321C137.942 37.3058 137.89 37.1102 137.787 36.9453C137.687 36.7772 137.549 36.6414 137.375 36.5379C137.2 36.4344 136.999 36.3698 136.773 36.3439V36.2857C137.038 36.2307 137.258 36.0949 137.433 35.8783C137.607 35.6617 137.695 35.4127 137.695 35.1314V35.1217C137.695 34.7176 137.556 34.3958 137.278 34.1566C137.003 33.9173 136.618 33.7977 136.123 33.7977H134.028V39.0211ZM134.668 38.4827V36.6155H135.992C136.409 36.6155 136.731 36.6899 136.957 36.8386C137.184 36.9841 137.297 37.212 137.297 37.5224V37.5321C137.297 37.8393 137.197 38.0753 136.996 38.2402C136.799 38.4019 136.506 38.4827 136.118 38.4827H134.668ZM134.668 36.082V34.3312H136.041C136.377 34.3312 136.631 34.4104 136.802 34.5688C136.974 34.724 137.059 34.9277 137.059 35.1799V35.1896C137.059 35.4709 136.964 35.6908 136.773 35.8492C136.586 36.0044 136.309 36.082 135.944 36.082H134.668Z'
        fill='#FFB35A'
      />
      <path
        d='M141.516 39.1083C142.005 39.1083 142.425 39 142.777 38.7834C143.133 38.5635 143.406 38.2515 143.597 37.8474C143.791 37.4432 143.888 36.9647 143.888 36.4118V36.4021C143.888 35.8492 143.791 35.3707 143.597 34.9665C143.406 34.5624 143.133 34.252 142.777 34.0353C142.425 33.8187 142.005 33.7104 141.516 33.7104C141.031 33.7104 140.611 33.8187 140.255 34.0353C139.9 34.252 139.625 34.5624 139.431 34.9665C139.24 35.3707 139.145 35.8492 139.145 36.4021V36.4118C139.145 36.9647 139.24 37.4432 139.431 37.8474C139.625 38.2515 139.9 38.5635 140.255 38.7834C140.611 39 141.031 39.1083 141.516 39.1083ZM141.516 38.5409C141.157 38.5409 140.849 38.4552 140.59 38.2839C140.335 38.1125 140.137 37.8684 139.998 37.5515C139.863 37.2314 139.795 36.8515 139.795 36.4118V36.4021C139.795 35.9591 139.863 35.5792 139.998 35.2624C140.137 34.9455 140.335 34.703 140.59 34.5349C140.849 34.3635 141.157 34.2778 141.516 34.2778C141.875 34.2778 142.182 34.3635 142.438 34.5349C142.697 34.703 142.894 34.9471 143.03 35.2672C143.169 35.5841 143.238 35.9624 143.238 36.4021V36.4118C143.238 36.8515 143.169 37.2314 143.03 37.5515C142.894 37.8684 142.697 38.1125 142.438 38.2839C142.182 38.4552 141.875 38.5409 141.516 38.5409Z'
        fill='#FFB35A'
      />
      <path
        d='M145.998 39.0211V34.9568H146.066L147.943 38.9192H148.437L150.304 34.9568H150.372V39.0211H151.013V33.7977H150.275L148.234 38.1384H148.175L146.124 33.7977H145.358V39.0211H145.998Z'
        fill='#FFB35A'
      />
      <path
        d='M153.103 40.8252C153.384 40.8252 153.62 40.7832 153.811 40.6991C154.005 40.6183 154.171 40.4793 154.31 40.282C154.453 40.088 154.589 39.8197 154.718 39.4769L156.832 33.7977H156.158L154.437 38.7495L154.703 38.3324H154.393L154.66 38.7495L152.933 33.7977H152.249L154.209 39.055L154.078 39.4478C153.971 39.7615 153.838 39.9797 153.68 40.1026C153.525 40.2287 153.326 40.2917 153.083 40.2917C153.041 40.2917 152.996 40.2901 152.948 40.2869C152.902 40.2836 152.859 40.2788 152.817 40.2723V40.8058C152.862 40.8123 152.91 40.8171 152.962 40.8204C153.014 40.8236 153.061 40.8252 153.103 40.8252Z'
        fill='#FFB35A'
      />
      <defs>
        <linearGradient
          id='paint0_linear_747_170'
          x1='3.89923'
          y1='27.4708'
          x2='38.3559'
          y2='18.8798'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E06250' />
          <stop offset='1' stopColor='#F1B766' />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

export default Logo
