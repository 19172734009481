import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { setupStore } from 'store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { AuthProvider } from 'hooks/useAuth'
import { SnackbarProvider } from 'notistack'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const store = setupStore()
const persistor = persistStore(store)

root.render(
  <SnackbarProvider maxSnack={3}>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </SnackbarProvider>,
)
