import React, { useContext, ReactNode, createContext, useState, useMemo, useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { login } from 'store/reducers/AuthSlice/ActionCreators'
import { authSlice } from 'store/reducers/AuthSlice/AuthSlice'
import { selectAuthErrors } from 'store/selectors/authSelectors'

type FormValues = {
  username: string
  password: string
}

interface AuthContextType {
  methods: UseFormReturn<FormValues, any>
  isAuth: boolean
  logIn: (loginData: { username: string; password: string }) => void
  logOut: () => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const methods = useForm<FormValues>()
  const { setError } = methods
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isAuth, setIsAuth] = useState<boolean>(false)
  const authErrors = useAppSelector<string[]>(selectAuthErrors)
  const { clearErrors } = authSlice.actions

  const logIn = (loginData: { username: string; password: string }) => {
    dispatch(login(loginData))
      .unwrap()
      .then((res) => {
        if (res.token) {
          localStorage.setItem('token', res.token)
          setIsAuth(true)
          navigate('/admin')
        }
      })
  }

  const logOut = () => {
    setIsAuth(false)
    navigate('/')
    localStorage.clear()
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuth(true)
    }
  }, [])

  useEffect(() => {
    if (authErrors.length > 0 && authErrors[0] == 'User not found') {
      setError('username', {
        type: 'server',
        message: 'Пользователь не найден или указан не верно, поле чувствительно к регистру.',
      })
      dispatch(clearErrors())
    }

    if (authErrors.length > 0 && authErrors[0] == 'Wrong password') {
      setError('password', {
        type: 'server',
        message: 'Неверный пароль, поле чувствительно к регистру.',
      })
      dispatch(clearErrors())
    }
  }, [authErrors])

  const value = useMemo(
    () => ({
      methods,
      isAuth,
      logIn,
      logOut,
    }),
    [isAuth],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
