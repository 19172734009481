import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { MarketplaceModal } from 'components/modals'
import { MarketplacePosition } from 'types/Marketplace'

interface TableRowProps {
  id: number
  name: string
  address: string
  administrators: number[]
  position: MarketplacePosition
}

const TableRow: FC<TableRowProps> = ({ id, name, address, administrators, position }) => {
  const [marketplaceModal, setMarketplaceModal] = useState<boolean>(false)

  const handleMarketplaceModalOpen = useCallback(() => {
    setMarketplaceModal(true)
  }, [marketplaceModal])

  const handleMarketplaceModalClose = useCallback(() => {
    setMarketplaceModal(false)
  }, [marketplaceModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleMarketplaceModalOpen}
      >
        <TableCell component='th' scope='row'>
          {name}
        </TableCell>
        <TableCell component='th' align='left'>
          {address}
        </TableCell>
      </MUITableRow>
      <MarketplaceModal
        id={id}
        title={name}
        administrators={administrators}
        address={address}
        position={position}
        isOpen={marketplaceModal}
        onClose={handleMarketplaceModalClose}
        type='change'
      />
    </>
  )
}

export default TableRow
