import { useCallback, useMemo, useState } from 'react'
import { useAppDispatch } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import { MarketplacePosition } from 'types/Marketplace'
import {
  createMarketplace,
  deleteMarketplace,
  getMarketplaceList,
  updateMarketplace,
} from 'store/reducers/MarketplaceSlice/ActionCreators'
import { MarketplaceFormTypes } from 'types/FormTypes'
import { useSnackbar } from 'notistack'

const useMarketplaceModal = (
  onClose: () => void,
  type: 'change' | 'create',
  id?: number,
  title?: string,
  address?: string,
  position?: MarketplacePosition,
) => {
  const dispatch = useAppDispatch()
  const methods = useForm<MarketplaceFormTypes>({
    defaultValues: {
      position: position,
      name: title,
      address: address,
    },
  })
  const { enqueueSnackbar } = useSnackbar()
  const { reset } = methods
  const [confirmModal, setConfirmModal] = useState<boolean>(false)

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/marketplace/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/marketplace/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const handleDelete = () => {
    dispatch(deleteMarketplace({ id }))
      .unwrap()
      .then(() => {
        dispatch(getMarketplaceList({ page: page, page_size: pageSize }))
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
        handleConfirmModalClose()
        onClose()
      })
  }

  const onSubmit = (formValues: MarketplaceFormTypes) => {
    if (type === 'change') {
      dispatch(updateMarketplace({ id, formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getMarketplaceList({ page: page, page_size: pageSize }))
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (type === 'create') {
      dispatch(createMarketplace({ formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getMarketplaceList({ page: page, page_size: pageSize }))
            onClose()
            reset()
            enqueueSnackbar('Точка продаж успешно создана', { variant: 'success' })
          }
        })
    }
  }

  return {
    methods,
    handleConfirmModalOpen,
    handleDelete,
    onSubmit,
    confirmModal,
    handleConfirmModalClose,
  }
}

export default useMarketplaceModal
