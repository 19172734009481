import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { getIngredients } from 'store/reducers/IngredientsSlice/ActionCreators'
import {
  selectIngredients,
  selectIngredientsCount,
  selectIngredientsIsLoading,
} from 'store/selectors/ingredientsSelectors'
import { Ingredient } from 'types/Ingredients'
import { selectProduct } from 'store/selectors/productsSelector'
import { Product } from 'types/Products'
import { useMediaQuery } from 'react-responsive'

const useIngredients = (id: number) => {
  const dispatch = useAppDispatch()
  const [rows, setRows] = useState<RowData[] | any>([])
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const count = useAppSelector<number>(selectIngredientsCount)
  const ingredients = useAppSelector<Ingredient[]>(selectIngredients)
  const [ingredientModal, setIngredientModal] = useState<boolean>(false)
  const ingredientsIsLoading = useAppSelector(selectIngredientsIsLoading)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const { unit } = useAppSelector<Product>(selectProduct)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'product',
      label: 'Товар',
      sort: 'product',
    },
    {
      id: 'consumption',
      label: 'Расход',
      sort: 'consumption',
    },
    {
      id: 'unit',
      label: 'Ед.изм.',
      sort: 'unit',
    },
  ]

  useEffect(() => {
    setRows(
      ingredients.map((item) => {
        return { ...item }
      }),
    )
  }, [ingredients, rowsPerPage, page])

  useEffect(() => {
    dispatch(getIngredients({ page: pageQuery, page_size: rowsPerPage, product: id, ordering }))
  }, [page, rowsPerPage, pageQuery, id, ordering])

  const handleIngredientModalOpen = useCallback(() => {
    setIngredientModal(true)
  }, [ingredientModal])

  const handleIngredientModalClose = useCallback(() => {
    setIngredientModal(false)
  }, [ingredientModal])

  return {
    order,
    orderBy,
    rows,
    page,
    count,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    ingredientsIsLoading,
    unit,
    rowsPerPageOptions,
    labelDisplayedRows,
    headCells,
    ingredientModal,
    handleIngredientModalOpen,
    handleIngredientModalClose,
  }
}

export default useIngredients
