import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, useTableSort, usePageOptions } from 'hooks'
import { getProductBatches } from 'store/reducers/ProductBatchSlice/ActionCreators'
import {
  selectProductBatchCount,
  selectProductBatches,
  selectProductBatchesIsLoading,
} from 'store/selectors/productBatchSelectors'
import { ProductBatch } from 'types/ProductBatch'
import { useMediaQuery } from 'react-responsive'
import { selectCurrencySymbol } from 'store/selectors/companySelector'

const useBatches = (id: number) => {
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const dispatch = useAppDispatch()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector<number>(selectProductBatchCount)
  const productBatches = useAppSelector<ProductBatch[]>(selectProductBatches)
  const [batchModal, setBatchModal] = useState<boolean>(false)
  const productBatchesIsLoading = useAppSelector<boolean>(selectProductBatchesIsLoading)
  const currency_symbol = useAppSelector(selectCurrencySymbol)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'code',
      label: 'Партия',
      sort: 'code',
    },
    {
      id: 'expire_date',
      label: 'Годен до',
      sort: 'expire_date',
    },
    {
      id: 'buying_price',
      label: `Себестоимость за ед. ${currency_symbol}`,
      sort: 'buying_price',
    },
  ]

  useEffect(() => {
    setRows(
      productBatches.map((item) => {
        return { ...item }
      }),
    )
  }, [productBatches, rowsPerPage, page])

  useEffect(() => {
    dispatch(getProductBatches({ page: pageQuery, page_size: rowsPerPage, product: id, ordering }))
  }, [page, rowsPerPage, pageQuery, id, ordering])

  const handleBatchModalOpen = useCallback(() => {
    setBatchModal(true)
  }, [batchModal])

  const handleBatchModalClose = useCallback(() => {
    setBatchModal(false)
  }, [batchModal])

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    productBatchesIsLoading,
    headCells,
    batchModal,
    handleBatchModalOpen,
    handleBatchModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useBatches
