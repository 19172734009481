import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProductBatch, ProductBatchData } from 'types/ProductBatch'
import { ProductBatchFormTypes } from 'types/FormTypes'
import { http } from 'utils/httpConfig'

export const getProductBatches = createAsyncThunk(
  'product-batch/get_product-batches',
  async (
    params: { page?: number; page_size?: number; ordering?: string; product?: number },
    thunkAPI,
  ) => {
    const { page, page_size, ordering, product } = params
    try {
      const response = await http.get<ProductBatchData>('/api/admin/product-batch/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          product,
        },
      })
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get product-batches data, ${e}`)
    }
  },
)

export const updateProductBatch = createAsyncThunk(
  'product-batch/update_product-batch',
  async (params: { id?: number; formValues: any }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<ProductBatch>(`/api/admin/product-batch/${id}/`, formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update product-batch, ${e}`)
    }
  },
)

export const deleteProductBatch = createAsyncThunk(
  'product-batch/delete_product-batch',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/product-batch/${id}/`)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to delete product-batch, ${e}`)
    }
  },
)

export const partialUpdateProductBatch = createAsyncThunk(
  'product-batch/partial_update_product-batch',
  async (params: { id?: number; formValues: ProductBatchFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<ProductBatch>(`/api/admin/product-batch/${id}/`, formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update product-batch, ${e}`)
    }
  },
)

export const createProductBatch = createAsyncThunk(
  'product-batch/create_product-batch',
  async (params: { formValues: ProductBatchFormTypes }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<ProductBatch>('/api/admin/product-batch/', formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to create product-batch, ${e}`)
    }
  },
)
