import React, { FC } from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { LogInForm } from 'components/logIn'
import { useAuth } from 'hooks'
import { NavLink } from 'react-router-dom'
import { Logo } from 'components/icons'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectAuthIsLoading } from 'store/selectors/authSelectors'

const LogIn: FC = () => {
  const { methods, logIn } = useAuth()
  const isLoading = useAppSelector(selectAuthIsLoading)

  const onSubmit = (loginData: { username: string; password: string }) => {
    logIn(loginData)
  }

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          width: '420px',
          padding: '40px 20px 20px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
          zIndex: '10',
          margin: '25px',
        }}
        component='form'
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Logo sx={{ width: '166px', height: '43px', marginBottom: '50px' }} />
        <Typography
          sx={{
            fontSize: '26px',
            lineHeight: '26px',
            fontWeight: '700',
            marginBottom: '20px',
            textDecoration: 'none',
          }}
        >
          Вход
        </Typography>
        <Box sx={{ width: '100%' }}>
          <LogInForm />
        </Box>

        <Typography
          sx={{
            alignSelf: 'flex-end',
            marginBottom: '27px',
            color: 'text.primary',
            textDecoration: 'none',
          }}
          variant='body1'
          component={NavLink}
          to='/recovery_password'
        >
          Я не помню пароль
        </Typography>
        <Box sx={{ marginBottom: '44px' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button type='submit' variant='contained'>
              Войти в панель управления
            </Button>
          )}
        </Box>

        <Typography
          sx={{ color: 'text.primary', textDecoration: 'none' }}
          variant='body1'
          component={NavLink}
          to='/registration'
        >
          Отправить заявку на регистрацию
        </Typography>
      </Box>
    </FormProvider>
  )
}

export default LogIn
