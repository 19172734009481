import { createAsyncThunk } from '@reduxjs/toolkit'
import { Group, GroupsData } from 'types/Groups'
import { GroupFormTypes } from 'types/FormTypes'
import { http } from 'utils/httpConfig'
import axios from 'axios'

export const getGroups = createAsyncThunk(
  'groups/get_groups',
  async (params: { page?: number; page_size?: number; ordering?: string }, thunkAPI) => {
    const { page, page_size, ordering } = params
    try {
      const response = await http.get<GroupsData>('/api/admin/terminalgroup/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get groups, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get groups, error${error}`])
      }
    }
  },
)

export const getGroup = createAsyncThunk(
  'groups/get_group',
  async (params: { id: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<GroupsData>(`/api/admin/terminalgroup/${id}/`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get group, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get group, error${error}`])
      }
    }
  },
)

export const updateGroup = createAsyncThunk(
  'groups/update_group',
  async (params: { id?: number; formData: GroupFormTypes }, thunkAPI) => {
    const { id, formData } = params
    try {
      const response = await http.put<Group>(`/api/admin/terminalgroup/${id}/`, formData)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update group, error${error}`])
      }
    }
  },
)

export const deleteGroup = createAsyncThunk(
  'groups/delete_group',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/terminalgroup/${id}/`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(`Failed to delete group, ${error}`)
    }
  },
)

export const partialUpdateGroup = createAsyncThunk(
  'groups/partial_update_group',
  async (params: { id?: number; formData: GroupFormTypes }, thunkAPI) => {
    const { id, formData } = params
    try {
      const response = await http.put<Group>(`/api/admin/terminalgroup/${id}/`, formData)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update group, error${error}`])
      }
    }
  },
)

export const createGroup = createAsyncThunk(
  'group/create_group',
  async (params: { formData: GroupFormTypes }, thunkAPI) => {
    const { formData } = params
    try {
      const response = await http.post<Group>('/api/admin/terminalgroup/', formData)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create group, error${error}`])
      }
    }
  },
)
