import React, { FC, memo } from 'react'
import { Box, Stack, Button, CircularProgress } from '@mui/material'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { GeneralForm } from 'components/product/General'
import { ProductFormTypes } from 'types/FormTypes'
import { selectisLoadingProduct } from 'store/selectors/productsSelector'

interface GeneralProps {
  id: number
  icon: string
  onSubmit: (formValues: ProductFormTypes) => void
  handleConfirmModalOpen: () => void
  handleBackRoute: () => void
  methods: UseFormReturn<ProductFormTypes, any>
  nonFood: boolean
}

const General: FC<GeneralProps> = ({
  id,
  icon,
  onSubmit,
  handleConfirmModalOpen,
  handleBackRoute,
  methods,
  nonFood,
}) => {
  const isLoading = useAppSelector(selectisLoadingProduct)

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <FormProvider {...methods}>
          <Box component='form'>
            <Box
              sx={{
                padding: '20px 24px',
                maxHeight: 'calc(100vh - 256px)',
                minHeight: 'calc(100vh - 256px)',
                overflowY: 'auto',
              }}
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              ) : (
                <GeneralForm id={id} icon={icon} nonFood={nonFood} />
              )}
            </Box>

            <Stack
              sx={{
                width: '100%',
                padding: '15px 24px',
                borderTop: '1px solid',
                borderColor: 'divider',
                justifyContent: 'flex-end',
                minHeight: '73px',
              }}
              direction='row'
              spacing={2}
            >
              <Button
                sx={{ color: 'error.main' }}
                variant='outlined'
                onClick={handleConfirmModalOpen}
                color='error'
              >
                Удалить
              </Button>
              <Button variant='outlined' onClick={handleBackRoute}>
                Закрыть
              </Button>
              <Button variant='contained' onClick={methods.handleSubmit(onSubmit)}>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Box>
    </>
  )
}

export default memo(General)
