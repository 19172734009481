import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import {
  createCategory,
  deleteCategory,
  getCategories,
  partialUpdateCategory,
  updateCategory,
} from 'store/reducers/CategoriesSlice/ActionCreators'
import { CategoryFormType } from 'types/FormTypes'
import { useSnackbar } from 'notistack'
import { selectCategoriesErrors } from 'store/selectors/categoriesSelectors'
import { categoriesSlice } from 'store/reducers/CategoriesSlice/CategoriesSlice'

const useCategoryModal = (
  modalType: 'change' | 'create',
  onClose: () => void,
  id?: number,
  icon?: string,
) => {
  const dispatch = useAppDispatch()
  const methods = useForm<CategoryFormType>()
  const { reset } = methods
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [image, setImage] = useState<any>(icon || null)
  const [imageFile, setImageFile] = useState<any>(null)
  const { clearErrors } = categoriesSlice.actions
  const categoriesErrors = useAppSelector(selectCategoriesErrors)

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/categories/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/categories/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  useEffect(() => {
    setImage(icon)
  }, [icon])

  const handleAddImage = (event: any) => {
    setImage(URL.createObjectURL(event.target.files[0]) || null)
    setImageFile(event.target.files[0])
    if (modalType === 'change') {
      const formData = new FormData()
      formData.append('icon', event.target.files[0] || null)

      dispatch(partialUpdateCategory({ id, formValues: formData }))
        .unwrap()
        .then((res) => {
          if (res.icon) {
            setImage(res.icon)
          }
        })
    }
  }

  const handleDeleteImage = () => {
    if (modalType === 'change') {
      const formData = new FormData()
      formData.append('icon', '')
      dispatch(partialUpdateCategory({ id, formValues: formData }))
        .unwrap()
        .then(() => setImage(null))
    }
    if (modalType === 'create') {
      setImageFile(null)
      setImage(null)
    }
  }

  const handleDelete = () => {
    dispatch(deleteCategory({ id }))
      .unwrap()
      .then(() => {
        dispatch(getCategories({ page: page, page_size: pageSize }))
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
        onClose()
      })
  }

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const onSubmit = (formValues: CategoryFormType) => {
    if (modalType === 'change') {
      dispatch(updateCategory({ id, formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getCategories({ page: page, page_size: pageSize }))
            onClose()
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
          }
        })
    }
    if (modalType === 'create') {
      const formData = new FormData()
      formData.append('name', formValues.name)
      formData.append('is_visible', formValues.is_visible.toString())
      if (imageFile) {
        formData.append('icon', imageFile)
      }

      dispatch(createCategory({ formData }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            onClose()
            reset()
            setImage(null)
            setImageFile(null)
            dispatch(getCategories({ page: page, page_size: pageSize }))
            enqueueSnackbar('Успешно cоздано', { variant: 'success' })
          }
        })
    }
  }

  useEffect(() => {
    if (categoriesErrors.length > 0) {
      categoriesErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [categoriesErrors])

  return {
    methods,
    image,
    handleAddImage,
    handleDeleteImage,
    handleDelete,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    onSubmit,
    confirmModal,
  }
}

export default useCategoryModal
