import React, { FC } from 'react'
import { TextField } from 'components/generic'
import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { selectRegistrationIsLoading } from 'store/selectors/registrationSelectors'
import { useAppSelector } from 'store/hooks/useAppSelector'

const RegistratinForm: FC = () => {
  const { control } = useFormContext()
  const isLoading = useAppSelector(selectRegistrationIsLoading)
  return (
    <Stack sx={{ marginBottom: '20px' }} direction='column' spacing={2}>
      <TextField
        control={control}
        name='first_name'
        label='Имя'
        size='small'
        required
        disabled={isLoading}
      />
      <TextField
        control={control}
        name='last_name'
        label='Фамилия'
        size='small'
        required
        disabled={isLoading}
      />
      <TextField
        control={control}
        name='middle_name'
        label='Отчество'
        size='small'
        required
        disabled={isLoading}
      />

      <TextField
        control={control}
        name='company'
        label='Компания'
        size='small'
        required
        disabled={isLoading}
      />
      <TextField
        control={control}
        name='phone'
        label='Номер телефона'
        size='small'
        required
        mask='+7(999)999 99 99'
        pattern={/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/}
        disabled={isLoading}
      />
      <TextField
        control={control}
        name='email'
        label='Email'
        size='small'
        required
        pattern={/^\S+@\S+\.\S+$/}
        disabled={isLoading}
      />
    </Stack>
  )
}

export default RegistratinForm
