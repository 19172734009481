import React, { FC } from 'react'
import { Dialog } from '@mui/material'
import { Content } from 'components/modals/CellModal'
import { SelectFieldType } from 'types/Other'
import { CellContainer } from 'types/Cells'

interface CellModalProps {
  isOpen: boolean
  onClose: () => void
  modalType: 'change' | 'create'
  productList: SelectFieldType[]
  cookingTime?: number
  productId?: number
  id?: number
  title?: string
  price?: number
  code?: number
  containers?: CellContainer[]
  quantityNotification?: number
  needQuantityNotification?: boolean
}

const CellModal: FC<CellModalProps> = ({
  id,
  isOpen,
  onClose,
  modalType,
  productList,
  cookingTime,
  productId,
  title,
  price,
  code,
  containers,
  quantityNotification,
  needQuantityNotification,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '450px',
          width: '100%',
          overflowY: 'auto',
          margin: '0px',
        },
      }}
    >
      <Content
        id={id}
        onClose={onClose}
        modalType={modalType}
        productList={productList}
        cookingTime={cookingTime}
        productId={productId}
        title={title}
        price={price}
        code={code}
        containers={containers}
        quantityNotification={quantityNotification}
        needQuantityNotification={needQuantityNotification}
      />
    </Dialog>
  )
}

export default CellModal
