import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectProductName } from 'store/selectors/productsSelector'
import { useUnitLabel } from 'hooks'
import { IngredientModal } from 'components/modals'

interface TableRowProps {
  id: number
  ingredient: number
  product: number
  consumption: number
  unit: string
}

const TableRow: FC<TableRowProps> = ({ id, ingredient, product, consumption, unit }) => {
  const productName = useAppSelector(selectProductName(ingredient))
  const unitLabel = useUnitLabel(unit)
  const [ingredientModal, setIngredientModal] = useState<boolean>(false)

  const handleIngredientModalOpen = useCallback(() => {
    setIngredientModal(true)
  }, [ingredientModal])

  const handleIngredientModalClose = useCallback(() => {
    setIngredientModal(false)
  }, [ingredientModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleIngredientModalOpen}
      >
        <TableCell component='th' scope='row'>
          {productName}
        </TableCell>
        <TableCell align='left'>{consumption}</TableCell>
        <TableCell align='left'>{unitLabel}</TableCell>
      </MUITableRow>
      <IngredientModal
        isOpen={ingredientModal}
        onClose={handleIngredientModalClose}
        modalType='change'
        id={id}
        product={product}
        productName={productName}
        ingredient={ingredient}
        consumption={consumption}
        unit={unit}
      />
    </>
  )
}

export default TableRow
