import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectCompanyData = (state: RootState) => state.company
/**
 * Returns company data
 */
export const selectCurrencySymbol = createSelector(
  [selectCompanyData],
  (company) => company.company.currency_symbol || '',
)
