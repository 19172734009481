import React, { FC, memo, useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { LeafletMap, TextField } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { Table } from 'components/modals/MarketplaceModal'
import { MarketplacePosition } from 'types/Marketplace'

interface MarketplaceFormProps {
  administrators: number[]
  position?: MarketplacePosition
  title?: string
  address?: string
}

const MarketplaceForm: FC<MarketplaceFormProps> = ({
  administrators,
  title,
  address,
  position,
}) => {
  const { control, setValue } = useFormContext()

  useEffect(() => {
    setValue('position', position)
  }, [position])

  useEffect(() => {
    setValue('name', title)
  }, [title])

  useEffect(() => {
    setValue('address', address)
  }, [address])

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Stack sx={{ flexGrow: '1' }} direction='column' spacing={2}>
          <TextField
            control={control}
            name='name'
            label='Название точки продаж *'
            size='small'
            defaultValue={title || ''}
            required
          />
          <TextField
            control={control}
            name='address'
            label='Адрес'
            size='small'
            defaultValue={address || ''}
            required
          />
          <TextField
            control={control}
            sx={{ display: 'none' }}
            name='administrators'
            label='Администраторы'
            defaultValue={administrators}
            required
          />
        </Stack>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            minWidth: '367px',
            width: '100%',
            height: '260px',
            backgroundColor: '#cccccc',
          }}
        >
          <LeafletMap coordinates={position ? position.coordinates : undefined} />
        </Box>
      </Box>

      <Table administrators={administrators} />
    </Stack>
  )
}

export default memo(MarketplaceForm)
