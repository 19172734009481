import React, { FC, memo } from 'react'
import { Stack, Tooltip, IconButton } from '@mui/material'
import { TextField, SelectField } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectProductBatchesList } from 'store/selectors/productBatchSelectors'
import { SelectFieldType } from 'types/Other'
import { Add as AddIcon } from '@mui/icons-material'

interface ContainerBatchFormProps {
  modalType: 'create' | 'change'
  batch?: number
  quantity?: number
  handleCreateBatchModalOpen: () => void
}

const ContainerBatchForm: FC<ContainerBatchFormProps> = ({
  modalType,
  batch,
  quantity,
  handleCreateBatchModalOpen,
}) => {
  const { control } = useFormContext()
  const productBatchesList = useAppSelector<SelectFieldType[]>(selectProductBatchesList)

  return (
    <Stack sx={{ marginBottom: '16px' }} direction='column' spacing={2}>
      <Stack direction='row' spacing={2}>
        <SelectField
          control={control}
          name='batch'
          label='Партия'
          size='small'
          selectItem={productBatchesList}
          defaultValue={batch || ''}
          required
        />

        {modalType === 'create' && (
          <Tooltip title='Добавить партию' placement='top-start'>
            <IconButton color='primary' onClick={handleCreateBatchModalOpen}>
              <AddIcon color='primary' />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <TextField
        control={control}
        name='quantity'
        label='Остаток'
        size='small'
        defaultValue={quantity || ''}
        type='number'
        required
      />
    </Stack>
  )
}

export default memo(ContainerBatchForm)
