import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectRegistration = (state: RootState) => state.registration
/**
 * Returns registration errors
 */
export const selectRegistrationErrors = createSelector(
  [selectRegistration],
  (registration) => registration.errors,
)
/**
 * Returns request response
 */
export const selectRequestResponse = createSelector(
  [selectRegistration],
  (registration) => registration.request,
)
/**
 * Returns request is loading
 */
export const selectRegistrationIsLoading = createSelector(
  [selectRegistration],
  (registration) => registration.isLoading,
)
