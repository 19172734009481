import React, { useEffect, useMemo } from 'react'
import { Box, Typography, Stack, Skeleton } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getTerminals } from 'store/reducers/TerminalsSlice/ActionCreators'
import { selectTerminalIsLoading, selectTerminals } from 'store/selectors/terminalsSelector'
import { NavLink } from 'react-router-dom'

const Notifications = () => {
  const dispatch = useAppDispatch()
  const terminals = useAppSelector(selectTerminals)
  const isLoading = useAppSelector(selectTerminalIsLoading)

  useEffect(() => {
    dispatch(getTerminals({ containers_need_revision__gt: '0' }))
  }, [])

  const bgColor = useMemo(() => {
    return terminals.length > 0 ? '#EB5757' : '#6FCF97'
  }, [terminals])

  const title = useMemo(() => {
    return terminals.length > 0 ? 'Внимание!!!' : 'Все хорошо!'
  }, [terminals])

  const notice = useMemo(() => {
    return terminals.length > 0 ? 'Требуется ревизия' : 'Нет автоматов требующих внимания'
  }, [terminals])

  return (
    <Box
      sx={{
        borderRadius: '10px',
        padding: '15px 30px',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        minWidth: '354px',
        minHeight: '200px',
        zIndex: '3',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: bgColor,
          opacity: '0.1',
          zIndex: '-1',
        },
      }}
    >
      <Typography sx={{ fontSize: '28px', lineHeight: '33px', marginBottom: '6px' }}>
        {title}
      </Typography>
      <Typography sx={{ fontWeight: '400', marginBottom: '10px' }} variant='body1'>
        {notice}
      </Typography>
      <Box sx={{ height: '100%', maxHeight: '380px', overflowX: 'auto' }}>
        {isLoading ? (
          <Stack direction='column' spacing={1}>
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
          </Stack>
        ) : (
          <Stack direction='column' spacing={1}>
            {terminals.map((terminal, index) => (
              <Stack
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  padding: '5px 10px',
                  borderRadius: '10px',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: '#F3F4F9',
                  },
                }}
                key={terminal.id}
                direction='row'
                spacing={1}
                component={NavLink}
                to={`/admin/terminals/${terminal.id}`}
              >
                <Typography sx={{ color: '#9A9A9A' }}>{index + 1}</Typography>
                <Typography sx={{ color: 'error.main' }} variant='body2'>
                  {terminal.address}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  )
}

export default Notifications
