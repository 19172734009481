import { useCallback, useEffect, useState, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import {
  createGroup,
  deleteGroup,
  getGroups,
  updateGroup,
} from 'store/reducers/GroupsSlice/ActionCreators'
import { GroupFormTypes } from 'types/FormTypes'
import { useSnackbar } from 'notistack'
import { selectGroupErrors } from 'store/selectors/groupsSelectors'
import { groupsSlice } from 'store/reducers/GroupsSlice/GroupsSlice'

const useGroupModal = (
  modalType: 'change' | 'create',
  onClose: () => void,
  id?: number,
  externalId?: number,
) => {
  const dispatch = useAppDispatch()
  const methods = useForm<GroupFormTypes>({ defaultValues: { external_id: externalId || null } })
  const groupErrors = useAppSelector<string[]>(selectGroupErrors)
  const { reset } = methods
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { clearErrors } = groupsSlice.actions

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/groups/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/groups/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const handleDelete = () => {
    dispatch(deleteGroup({ id }))
      .unwrap()
      .then(() => {
        dispatch(getGroups({ page: page, page_size: pageSize }))
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
        onClose()
      })
  }

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const onSubmit = (formValues: GroupFormTypes) => {
    if (modalType === 'change') {
      dispatch(updateGroup({ id, formData: formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getGroups({ page: page, page_size: pageSize }))
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      dispatch(createGroup({ formData: formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getGroups({ page: page, page_size: pageSize }))
            enqueueSnackbar('Группа успешно создана', { variant: 'success' })
            onClose()
            reset()
          }
        })
    }
  }

  useEffect(() => {
    if (groupErrors.length > 0) {
      groupErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [groupErrors])

  return {
    confirmModal,
    methods,
    onSubmit,
    handleDelete,
    handleConfirmModalOpen,
    handleConfirmModalClose,
  }
}

export default useGroupModal
