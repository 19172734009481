import React, { FC, MouseEvent } from 'react'
import { Switch } from '@mui/material'

interface TableSwitchProps {
  isCheck: boolean
  handleChange: () => void
}

const TableSwitch: FC<TableSwitchProps> = ({ isCheck, handleChange }) => {
  const stopPropagation = (event: MouseEvent<Element, globalThis.MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    handleChange()
  }

  return <Switch color='success' checked={isCheck} onClick={(event) => stopPropagation(event)} />
}

export default TableSwitch
