import React, { FC } from 'react'
import { Box, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/marketplace'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { MarketplaceModal } from 'components/modals'
import { useMarketplaces } from 'hooks/marketplaces'
import { RowData } from 'types/Table'

const Marketplace: FC = () => {
  const {
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    headCells,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    marketPlaceIsLoading,
    marketplaceModal,
    handleMarketplaceModalOpen,
    handleMarketplaceModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useMarketplaces()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Toolbar
          title='Точки продаж'
          buttonLabel='+ Добавить точку продаж'
          handleModalOpen={handleMarketplaceModalOpen}
          isLoadingData={marketPlaceIsLoading}
        />
        <TableContainer
          sx={{
            height: { xs: 'auto', sm: 'calc(100vh - 275px)' },
          }}
        >
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  id={row.id}
                  key={row.id}
                  name={row.name}
                  address={row.address}
                  administrators={row.administrators}
                  position={row.position}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            padding: '10px 24px',
            borderTop: '1px solid',
            borderColor: 'divider',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </Stack>
      </Box>

      <MarketplaceModal
        isOpen={marketplaceModal}
        onClose={handleMarketplaceModalClose}
        type='create'
      />
    </>
  )
}

export default Marketplace
