import React, { FC, memo } from 'react'
import { Dialog } from '@mui/material'
import { Content } from 'components/modals/ContainerModal'

interface ContainerModalProps {
  isOpen: boolean
  onClose: () => void
  modalType: 'change' | 'create'
  id?: number
  title?: string
  productId?: number
}

const ContainerModal: FC<ContainerModalProps> = ({
  isOpen,
  onClose,
  modalType,
  id,
  title,
  productId,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: modalType === 'change' ? '900px' : '500px',
          overflowY: 'auto',
          margin: '0px',
        },
      }}
    >
      <Content
        onClose={onClose}
        modalType={modalType}
        id={id}
        title={title}
        productId={productId}
      />
    </Dialog>
  )
}

export default memo(ContainerModal)
