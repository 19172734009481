import React, { FC, useMemo } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { South as SouthIcon, North as NorthIcon } from '@mui/icons-material'

interface MetricsCardProps {
  title: string
  stat: number
  precent: string
  trend: 'up' | 'down' | 'neutral'
  rangeLabel: string
}

const MetricsCard: FC<MetricsCardProps> = ({ title, stat, precent, trend, rangeLabel }) => {
  const color = useMemo(() => {
    switch (trend) {
      case 'up':
        return 'success.main'
      case 'down':
        return 'error.main'
      default:
        return ''
    }
  }, [trend])

  const icon = useMemo(() => {
    switch (trend) {
      case 'up':
        return <NorthIcon fontSize='small' color='success' />
      case 'down':
        return <SouthIcon fontSize='small' color='error' />
      default:
        return <Box sx={{ height: '20px' }} />
    }
  }, [trend])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F7FAFF',
        padding: '15px',
        borderRadius: '10px',
        filter: 'drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.25))',
      }}
    >
      <Typography
        sx={{ textTransform: 'uppercase', marginBottom: '10px', color: 'text.primary' }}
        variant='body1'
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '600',
          flexGrow: '1',
          color: 'text.primary',
        }}
      >
        {stat}
      </Typography>
      <Divider sx={{ margin: '10px 0' }} />
      <Stack direction='column' spacing={{ xs: 0.2, md: 1 }}>
        <Typography sx={{ color: 'text.secondary' }} variant='body2'>
          {rangeLabel}
        </Typography>
        <Stack sx={{ alignItems: 'center' }} direction='row' spacing={0}>
          {icon}
          <Typography sx={{ color: color }}>{precent}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default MetricsCard
