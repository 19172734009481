import React, { FC, memo, useState, useCallback } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { Toolbar } from 'components/generic'
import { TerminalFooter } from 'components/terminal'
import { FormProvider } from 'react-hook-form'
import { SettingsForm } from 'components/terminal/Settings'
import { selectMarketplace, selectMarketplacesList } from 'store/selectors/marketplaceSelectors'
import { selectGroup, selectGroupsList } from 'store/selectors/groupsSelectors'
import { SelectFieldType } from 'types/Other'
import { Marketplace } from 'types/Marketplace'
import { Group } from 'types/Groups'
import { selectTerminalIsLoading } from 'store/selectors/terminalsSelector'
import { GroupModal, MarketplaceModal } from 'components/modals'
import { useTerminal } from 'hooks/terminals'

const Settings: FC = () => {
  const {
    methods,
    onSubmit,
    address,
    secret_key,
    model,
    handleConfirmModalOpen,
    handleCloseTerminal,
    handleCopyTerminalModalOpen,
  } = useTerminal()
  const [marketplaceCreateModal, setMarketplaceCreateModal] = useState<boolean>(false)
  const [marketplaceChangeModal, setMarketplaceChangeModal] = useState<boolean>(false)
  const [groupCreateModal, setGroupCreateModal] = useState<boolean>(false)
  const [groupChangeModal, setGroupChangeModal] = useState<boolean>(false)
  const terminalIsLoading = useAppSelector<boolean>(selectTerminalIsLoading)
  const marketplacesList = useAppSelector<SelectFieldType[]>(selectMarketplacesList)
  const groupList = useAppSelector<SelectFieldType[]>(selectGroupsList)
  const marketplaceData = useAppSelector<Marketplace>(selectMarketplace)
  const groupData = useAppSelector<Group>(selectGroup)

  const handleMarketplaceCreateModalOpen = useCallback(() => {
    setMarketplaceCreateModal(true)
  }, [marketplaceCreateModal])

  const handleMarketplaceCreateModalClose = useCallback(() => {
    setMarketplaceCreateModal(false)
  }, [marketplaceCreateModal])

  const handleMarketplaceChangeModalOpen = useCallback(() => {
    setMarketplaceChangeModal(true)
  }, [marketplaceChangeModal])

  const handleMarketplaceChangeModalClose = useCallback(() => {
    setMarketplaceChangeModal(false)
  }, [marketplaceChangeModal])

  const handleGroupCreateModalOpen = useCallback(() => {
    setGroupCreateModal(true)
  }, [groupCreateModal])

  const handleGroupCreateModalClose = useCallback(() => {
    setGroupCreateModal(false)
  }, [groupCreateModal])

  const handleGroupChangeModalOpen = useCallback(() => {
    setGroupChangeModal(true)
  }, [groupChangeModal])

  const handleGroupChangeModalClose = useCallback(() => {
    setGroupChangeModal(false)
  }, [groupChangeModal])

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '90%',
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar title={address} isLoading={terminalIsLoading} />
        <Box
          sx={{
            padding: { xs: '20px 20px', sm: '20px 35px' },
            maxHeight: { xs: 'auto', sm: 'calc(100vh - 320px)' },
            minHeight: { xs: 'auto', sm: 'calc(100vh - 320px)' },
            overflowY: 'auto',
          }}
        >
          <FormProvider {...methods}>
            <Box component='form'>
              {terminalIsLoading ? (
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              ) : (
                <SettingsForm
                  marketplacesList={marketplacesList}
                  groupList={groupList}
                  secretKey={secret_key}
                  model={model}
                  handleMarketplaceCreateModalOpen={handleMarketplaceCreateModalOpen}
                  handleMarketplaceChangeModalOpen={handleMarketplaceChangeModalOpen}
                  handleGroupCreateModalOpen={handleGroupCreateModalOpen}
                  handleGroupChangeModalOpen={handleGroupChangeModalOpen}
                />
              )}
            </Box>
          </FormProvider>
        </Box>
        <TerminalFooter
          handleConfirmModalOpen={handleConfirmModalOpen}
          handleCloseTerminal={handleCloseTerminal}
          methods={methods}
          onSubmit={onSubmit}
          handleCopyTerminalModalOpen={handleCopyTerminalModalOpen}
        />
      </Box>
      <MarketplaceModal
        isOpen={marketplaceCreateModal}
        onClose={handleMarketplaceCreateModalClose}
        type='create'
      />
      <MarketplaceModal
        isOpen={marketplaceChangeModal}
        onClose={handleMarketplaceChangeModalClose}
        type='change'
        administrators={marketplaceData.administrators}
        position={marketplaceData.position}
        id={marketplaceData.id}
        title={marketplaceData.name}
        address={marketplaceData.address}
      />
      <GroupModal
        isOpen={groupCreateModal}
        onClose={handleGroupCreateModalClose}
        modalType='create'
      />
      <GroupModal
        isOpen={groupChangeModal}
        onClose={handleGroupChangeModalClose}
        modalType='change'
        id={groupData.id}
        name={groupData.name}
        externalId={groupData.external_id}
        mainTerminal={groupData.main_terminal}
      />
    </>
  )
}

export default memo(Settings)
