import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectOrdersData = (state: RootState) => state.orders
/**
 * Returns orders list
 */
export const selectOrders = createSelector(
  [selectOrdersData],
  (orders) => orders.ordersData.results,
)
/**
 * Returns orders count
 */
export const selectOrdersCount = createSelector(
  [selectOrdersData],
  (orders) => orders.ordersData.count || 0,
)
/**
 * Returns groups count
 */
export const selectOrdersIsLoading = createSelector(
  [selectOrdersData],
  (orders) => orders.isLoading,
)
/**
 * Returns orders revenue
 */
export const selectRevenue = createSelector(
  [selectOrdersData],
  (orders) => orders.ordersData.revenue,
)
