import React, { FC } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { CategoryForm } from 'components/modals/CategoryModal'
import { ConfirmationModal } from 'components/modals'
import { useCategoryModal } from 'hooks/categories'

interface CategoryModalProps {
  isOpen: boolean
  modalType: 'change' | 'create'
  onClose: () => void
  title?: string
  icon?: string
  id?: number
  isVisible?: boolean
}

const CategoryModal: FC<CategoryModalProps> = ({
  isOpen,
  modalType,
  onClose,
  title,
  icon,
  id,
  isVisible,
}) => {
  const {
    methods,
    image,
    handleAddImage,
    handleDeleteImage,
    handleDelete,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    onSubmit,
    confirmModal,
  } = useCategoryModal(modalType, onClose, id, icon)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '380px',
          width: '100%',
          overflowY: 'auto',
          margin: '0px',
        },
      }}
    >
      <Stack sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }} direction='column' spacing={3}>
        {modalType === 'change' ? (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>
            Редактирование категории - {title}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Создать категорию</Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <CategoryForm
                title={title}
                image={image}
                isVisible={isVisible}
                handleAddImage={handleAddImage}
                handleDeleteImage={handleDeleteImage}
              />
            </Box>

            <Stack sx={{ justifyContent: 'flex-end' }} direction='row' spacing={2}>
              {modalType === 'change' && (
                <Button
                  color='error'
                  sx={{ flexGrow: '1' }}
                  variant='outlined'
                  onClick={handleConfirmModalOpen}
                >
                  Удалить
                </Button>
              )}

              <Button variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
    </Dialog>
  )
}

export default CategoryModal
