import React, { FC, useCallback, useState, useMemo } from 'react'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import placeholder from 'assets/img/image-placeholder.png'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { partialUpdateProduct } from 'store/reducers/ProductsSlice/ActionCreators'
import { useSnackbar } from 'notistack'
import { selectisLoadingProduct } from 'store/selectors/productsSelector'
import { ConfirmationModal } from 'components/modals'
import { UseFormReturn } from 'react-hook-form'
import { ProductFormTypes } from 'types/FormTypes'

interface VideoProps {
  video: string
  id: number
  methods: UseFormReturn<ProductFormTypes, any>
  onSubmit: (formValues: ProductFormTypes) => void
  handleConfirmModalOpen: () => void
  handleBackRoute: () => void
}

const Video: FC<VideoProps> = ({
  video,
  id,
  methods,
  onSubmit,
  handleConfirmModalOpen,
  handleBackRoute,
}) => {
  const dispatch = useAppDispatch()
  const isLoadingProduct = useAppSelector<boolean>(selectisLoadingProduct)
  const { enqueueSnackbar } = useSnackbar()
  const [confirmModal, setConfirmModal] = useState<boolean>(false)

  const buttonLabel = useMemo(() => {
    return video ? 'Изменить' : 'Загрузить'
  }, [video])

  const handleConfirmDeleteVideoModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmDeleteVideoModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const handleAddVideo = (event: any) => {
    const formData = new FormData()
    formData.append('video', event.target.files ? event.target.files[0] : null)
    dispatch(partialUpdateProduct({ id, formValues: formData }))
      .unwrap()
      .then((res) => {
        if (res.video) {
          enqueueSnackbar('Успешно загружено', { variant: 'success' })
        }
      })
  }

  const handleDeleteVideo = () => {
    const formData = new FormData()
    formData.append('video', '')
    dispatch(partialUpdateProduct({ id, formValues: formData }))
      .unwrap()
      .then((res) => {
        if (!res.video) {
          enqueueSnackbar('Успешно удалено', { variant: 'success' })
          handleConfirmDeleteVideoModalClose()
        }
      })
  }

  return (
    <>
      <Box
        sx={{
          padding: '20px 24px',
          maxHeight: 'calc(100vh - 256px)',
          minHeight: 'calc(100vh - 256px)',
          overflowY: 'auto',
        }}
      >
        <Stack sx={{ marginBottom: '20px' }} direction='row' spacing={2.4}>
          {video ? (
            <Box
              sx={{ border: '0', width: '600px', height: '350px' }}
              component='iframe'
              src={video}
            />
          ) : (
            <Box sx={{ width: '600px', height: '350px' }}>
              <Box
                sx={{ objectFit: 'contain', width: '100%', minHeight: '100%' }}
                src={placeholder}
                component='img'
              />
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Stack direction='column' spacing={2}>
              {video && (
                <Button
                  variant='outlined'
                  color='error'
                  onClick={handleConfirmDeleteVideoModalOpen}
                  disabled={isLoadingProduct}
                >
                  Удалить
                </Button>
              )}

              <Button variant='contained' component='label' disabled={isLoadingProduct}>
                {buttonLabel}
                <input
                  name='video'
                  type='file'
                  hidden
                  onChange={(event: any) => handleAddVideo(event)}
                />
              </Button>
              {isLoadingProduct && <CircularProgress color='primary' />}
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Stack
        sx={{
          width: '100%',
          padding: '15px 24px',
          borderTop: '1px solid',
          borderColor: 'divider',
          justifyContent: 'flex-end',
          minHeight: '73px',
        }}
        direction='row'
        spacing={2}
      >
        <Stack direction='row' spacing={2}>
          <Button
            sx={{ color: 'error.main' }}
            variant='outlined'
            onClick={handleConfirmModalOpen}
            color='error'
            disabled={isLoadingProduct}
          >
            Удалить
          </Button>
          <Button variant='outlined' onClick={handleBackRoute} disabled={isLoadingProduct}>
            Закрыть
          </Button>
          <Button
            variant='contained'
            onClick={methods.handleSubmit(onSubmit)}
            disabled={isLoadingProduct}
          >
            Сохранить
          </Button>
        </Stack>
      </Stack>

      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmDeleteVideoModalClose}
        confirm={handleDeleteVideo}
      />
    </>
  )
}

export default Video
