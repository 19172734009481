import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Marketplace as MarketplaceType } from 'types/Stat'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectCurrencySymbol } from 'store/selectors/companySelector'

interface MarketplaceProps {
  data: MarketplaceType[]
}

const Marketplace: FC<MarketplaceProps> = ({ data }) => {
  const currency_symbol = useAppSelector(selectCurrencySymbol)

  return (
    <Box sx={{ padding: '25px 30px', borderRadius: '10px', backgroundColor: 'secondary.main' }}>
      <Typography
        sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: '400', marginBottom: '35px' }}
      >
        ТОП Точек продаж (неделя) {currency_symbol}
      </Typography>

      <Stack direction='column' spacing={2}>
        {data.slice(0, 10).map((item, index) => (
          <Stack sx={{ justifyContent: 'space-between' }} key={item.marketplace.id} direction='row'>
            <Stack direction='row' spacing={3.1}>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: '400',
                  color: '#9A9A9A',
                }}
              >
                {index + 1}
              </Typography>
              <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400' }}>
                {item.marketplace.name}
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400' }}>
              {item.sum}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}

export default Marketplace
