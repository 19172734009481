import React, { FC, useState, useCallback } from 'react'
import { Box, TableCell, TableRow as MUITableRow } from '@mui/material'
import { CategoryModal } from 'components/modals'
import { useAppDispatch } from 'store/hooks/useAppSelector'
import { partialUpdateCategory } from 'store/reducers/CategoriesSlice/ActionCreators'
import { TableSwitch } from 'components/generic'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  name: string
  icon: string
  isVisible: boolean
}

const TableRow: FC<TableRowProps> = ({ id, name, icon, isVisible }) => {
  const dispatch = useAppDispatch()
  const [changeCategoryModal, setChangeCategoryModal] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(isVisible)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const handleChangeCategoryModalOpen = useCallback(() => {
    setChangeCategoryModal(true)
  }, [changeCategoryModal])

  const handleChangeCategoryModalClose = useCallback(() => {
    setChangeCategoryModal(false)
  }, [changeCategoryModal])

  const handleSwitchChange = useCallback(() => {
    dispatch(partialUpdateCategory({ id, formValues: { is_visible: !isChecked } }))
    setIsChecked(!isChecked)
  }, [isChecked])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root ': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleChangeCategoryModalOpen}
      >
        {!isMobile && (
          <TableCell component='th' scope='row'>
            {id}
          </TableCell>
        )}
        <TableCell align='left'>
          <Box sx={{ width: '50px', height: '50px', overflow: 'hidden' }}>
            <Box
              sx={{
                objectFit: 'cover',
                width: '100%',
                minHeight: '100%',
                objectPosition: 'center',
              }}
              src={icon}
              component='img'
            />
          </Box>
        </TableCell>
        <TableCell align='left'>{name}</TableCell>
        {!isMobile && (
          <TableCell align='left'>
            <TableSwitch isCheck={isChecked} handleChange={handleSwitchChange} />
          </TableCell>
        )}
      </MUITableRow>
      <CategoryModal
        id={id}
        title={name}
        icon={icon}
        isVisible={isVisible}
        isOpen={changeCategoryModal}
        onClose={handleChangeCategoryModalClose}
        modalType='change'
      />
    </>
  )
}

export default TableRow
