import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductBatch, ProductBatchData } from 'types/ProductBatch'
import {
  createProductBatch,
  deleteProductBatch,
  getProductBatches,
  partialUpdateProductBatch,
  updateProductBatch,
} from './ActionCreators'

interface ProductBatchState {
  productBatchData: {
    count: number
    results: ProductBatch[]
  }
  isLoading: boolean
  error: string
}

const initialState: ProductBatchState = {
  productBatchData: {
    count: 0,
    results: [],
  },
  isLoading: false,
  error: '',
}

export const productBatchSlice = createSlice({
  name: 'product-batch',
  initialState,
  reducers: {},
  extraReducers: {
    [getProductBatches.fulfilled.type]: (state, action: PayloadAction<ProductBatchData>) => {
      state.isLoading = false
      state.error = ''
      state.productBatchData.count = action.payload.count
      state.productBatchData.results = action.payload.results
    },
    [getProductBatches.pending.type]: (state) => {
      state.isLoading = true
    },
    [getProductBatches.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateProductBatch.fulfilled.type]: (state, action: PayloadAction<ProductBatch>) => {
      state.isLoading = false
      state.error = ''
      state.productBatchData.results = state.productBatchData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [updateProductBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateProductBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [deleteProductBatch.fulfilled.type]: (state, action: AnyAction) => {
      state.isLoading = false
      state.error = ''
      state.productBatchData.results = state.productBatchData.results.filter(
        (item) => item.id !== action.meta.arg.id,
      )
    },
    [deleteProductBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteProductBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [partialUpdateProductBatch.fulfilled.type]: (state, action: PayloadAction<ProductBatch>) => {
      state.isLoading = false
      state.error = ''
      state.productBatchData.results = state.productBatchData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [partialUpdateProductBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [partialUpdateProductBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createProductBatch.fulfilled.type]: (state, action: PayloadAction<ProductBatch>) => {
      state.isLoading = false
      state.error = ''
      state.productBatchData.results.push(action.payload)
    },
    [createProductBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [createProductBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default productBatchSlice.reducer
