import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Container, ContainersData } from 'types/Containers'
import { ContainerFormType } from 'types/FormTypes'
import { http } from 'utils/httpConfig'

export const getContainers = createAsyncThunk(
  'containers/get_containers',
  async (
    params: {
      page?: number
      page_size?: number
      ordering?: string
      terminal?: string | number
      product?: number
      product__in?: string
    },
    thunkAPI,
  ) => {
    const { page, page_size, ordering, terminal, product, product__in } = params
    try {
      const response = await http.get<ContainersData>('/api/admin/container/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          terminal,
          product,
          product__in,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get containers data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue(`Failed to get containers data, ${error}`)
      }
    }
  },
)

export const getContainer = createAsyncThunk(
  'containers/get_container',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<ContainersData>(`/api/admin/container/${id}/`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get container data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue(`Failed to get container data, ${error}`)
      }
    }
  },
)

export const updateContainer = createAsyncThunk(
  'containers/update_container',
  async (params: { id?: number; formValues: ContainerFormType }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Container>(`/api/admin/container/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue(`Failed to create cell, ${error}`)
      }
    }
  },
)

export const partialUpdateContainer = createAsyncThunk(
  'containers/partial_update_container',
  async (params: { id: number; formValues: { is_active?: boolean } | FormData }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<Container>(`/api/admin/container/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue(`Failed to create cell, ${error}`)
      }
    }
  },
)

export const createContainer = createAsyncThunk(
  'containers/create_container',
  async (params: { formValues: ContainerFormType }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<Container>('/api/admin/container/', formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue(`Failed to create cell, ${error}`)
      }
    }
  },
)
