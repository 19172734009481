import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getCategories } from 'store/reducers/CategoriesSlice/ActionCreators'
import {
  selectCategories,
  selectCategoriesCount,
  selectCategoriesIsLoading,
} from 'store/selectors/categoriesSelectors'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { useMediaQuery } from 'react-responsive'

const useCategories = () => {
  const dispatch = useAppDispatch()
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector(selectCategoriesCount)
  const categories = useAppSelector(selectCategories)
  const [createCategoryModal, setCreateCategoryModal] = useState<boolean>(false)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const categoriesIsLoading = useAppSelector<boolean>(selectCategoriesIsLoading)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'id',
      label: 'Код',
      sort: 'id',
    },
    {
      id: 'icon',
      label: 'Изображение',
      sort: '',
    },
    {
      id: 'name',
      label: 'Категории',
      sort: 'name',
    },
    {
      id: 'is_visible',
      label: 'Показывать',
      sort: 'is_visible',
    },
  ].filter((item) => {
    if (isHidden600) {
      return item.id !== 'is_visible' && item.id !== 'id'
    } else {
      return item
    }
  }) as HeadCell[]

  useEffect(() => {
    setRows(
      categories.map((item) => {
        return { ...item }
      }),
    )
  }, [categories, rowsPerPage, page])

  useEffect(() => {
    dispatch(getCategories({ page: pageQuery, page_size: rowsPerPage, ordering }))
  }, [page, rowsPerPage, pageQuery, ordering])

  const handleCreateCategoryModalOpen = useCallback(() => {
    setCreateCategoryModal(true)
  }, [createCategoryModal])

  const handleCreateCategoryModalClose = useCallback(() => {
    setCreateCategoryModal(false)
  }, [createCategoryModal])

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    headCells,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    categoriesIsLoading,
    createCategoryModal,
    handleCreateCategoryModalOpen,
    handleCreateCategoryModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useCategories
