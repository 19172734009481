import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { selectProductBatches } from './productBatchSelectors'

const selectContainerBatchData = (state: RootState) => state.containerBatch
/**
 * Returns container-batches
 */
export const selectContainerBatches = createSelector(
  [selectContainerBatchData],
  (containersBatch) => containersBatch.containerBatchData.results,
)
/**
 * Returns container-batches is loading
 */
export const selectContainerBatchesIsLoading = createSelector(
  [selectContainerBatchData],
  (containersBatch) => containersBatch.isLoading,
)
/**
 * Returns container-batches
 */
export const selectBatches = createSelector(
  [selectContainerBatches, selectProductBatches],
  (containersBatches, productBatches) =>
    containersBatches.map((containerBatch) => {
      const productBatch = productBatches.find((item) => item.id === containerBatch.batch)
      const labelDate = new Date(productBatch?.expire_date || '').toLocaleDateString()

      return {
        id: containerBatch.id,
        quantity: containerBatch.quantity,
        productBatchLabel: `${productBatch?.code + ' от ' + labelDate}`,
        container: containerBatch.container,
        batch: containerBatch.batch,
      }
    }),
)
