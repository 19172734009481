import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectCellsData = (state: RootState) => state.cells
/**
 * Returns cells list
 */
export const selectCells = createSelector([selectCellsData], (cells) => cells.cellData.results)
/**
 * Returns cells count
 */
export const selectCellsCount = createSelector(
  [selectCellsData],
  (cells) => cells.cellData.count || 0,
)
/**
 * Returns cells isLoading
 */
export const selectCellsIsLoading = createSelector(
  [selectCellsData],
  (cells) => cells.isLoadingCells,
)
/**
 * Returns cell isLoading
 */
export const selectCellIsLoading = createSelector([selectCellsData], (cells) => cells.isLoadingCell)
/**
 * Returns cells errors
 */
export const selectCellsErrors = createSelector([selectCellsData], (cells) => cells.error)
