import { createAsyncThunk } from '@reduxjs/toolkit'
import { Marketplace, MarketplaceData } from 'types/Marketplace'
import { MarketplaceFormTypes } from 'types/FormTypes'
import { http } from 'utils/httpConfig'

export const getMarketplaceList = createAsyncThunk(
  'marketplace/get_marketplace_list',
  async (params: { page?: number; page_size?: number; ordering?: string }, thunkAPI) => {
    const { page, page_size, ordering } = params
    try {
      const response = await http.get<MarketplaceData>('/api/admin/marketplace/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
        },
      })
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get marketplace data, ${e}`)
    }
  },
)

export const getMarketplace = createAsyncThunk(
  'marketplace/get_marketplace',
  async (params: { id: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<MarketplaceData>(`/api/admin/marketplace/${id}/`)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get marketplace, ${e}`)
    }
  },
)

export const updateMarketplace = createAsyncThunk(
  'marketplace/update_marketplace',
  async (params: { id?: number; formValues: MarketplaceFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Marketplace>(`/api/admin/marketplace/${id}/`, formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update marketplace, error${e}`)
    }
  },
)

export const deleteMarketplace = createAsyncThunk(
  'marketplace/delete_marketplace',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/marketplace/${id}/`)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to delete marketplace, ${e}`)
    }
  },
)

export const partialUpdateMarketplace = createAsyncThunk(
  'marketplace/partial_update_marketplace',
  async (
    params: {
      id?: number
      formData: FormData
    },
    thunkAPI,
  ) => {
    const { id, formData } = params
    try {
      const response = await http.put<Marketplace>(`/api/admin/marketplace/${id}/`, formData)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update marketplace, error${e}`)
    }
  },
)

export const createMarketplace = createAsyncThunk(
  'marketplace/create_marketplace',
  async (params: { formValues: MarketplaceFormTypes }, thunkAPI) => {
    const { formValues } = params
    const response = await http
      .post<Marketplace>('/api/admin/marketplace/', formValues)
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error?.response?.data || error))

    return response
  },
)
