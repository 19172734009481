import React, { FC } from 'react'
import { LinearProgress as MUILinearProgress } from '@mui/material'

const LinearProgress: FC = () => {
  return (
    <MUILinearProgress
      sx={{ position: 'absolute', bottom: '0', left: '0', zIndex: '5', width: '100%' }}
      variant='indeterminate'
    />
  )
}

export default LinearProgress
