import { CONTAINER_TYPE, FRANKE_TYPE, UNIT } from './Labels'

export const containerFrankeList = [
  {
    label: FRANKE_TYPE.TYPE_COFFEE_HOPPER_1,
    value: 'coffee_hopper_1',
  },
  {
    label: FRANKE_TYPE.TYPE_COFFEE_HOPPER_2,
    value: 'coffee_hopper_2',
  },
  {
    label: FRANKE_TYPE.TYPE_POWDER_HOPPER_1,
    value: 'powder_hopper_1',
  },
  {
    label: FRANKE_TYPE.TYPE_POWDER_HOPPER_2,
    value: 'powder_hopper_2',
  },
  {
    label: FRANKE_TYPE.TYPE_WATER_TANK,
    value: 'water_tank',
  },
  {
    label: FRANKE_TYPE.TYPE_DRIP_TRAY,
    value: 'drip_tray',
  },
  {
    label: FRANKE_TYPE.TYPE_WASTE_BIN,
    value: 'waste_bin',
  },
  {
    label: FRANKE_TYPE.TYPE_MILK_TANK,
    value: 'milk_tank',
  },
  {
    label: FRANKE_TYPE.TYPE_FLAVOR_1,
    value: 'flavor_1',
  },
  {
    label: FRANKE_TYPE.TYPE_FLAVOR_2,
    value: 'flavor_2',
  },
  {
    label: FRANKE_TYPE.TYPE_FLAVOR_3,
    value: 'flavor_3',
  },
  {
    label: FRANKE_TYPE.TYPE_FLAVOR_4,
    value: 'flavor_4',
  },
  {
    label: FRANKE_TYPE.TYPE_FLAVOR_5,
    value: 'flavor_5',
  },
  {
    label: FRANKE_TYPE.TYPE_FLAVOR_6,
    value: 'flavor_6',
  },
]

export const containerTypes = [
  {
    label: CONTAINER_TYPE.TYPE_BULK,
    value: 'bulk',
  },
  {
    label: CONTAINER_TYPE.TYPE_LIQUID,
    value: 'liquid',
  },
  {
    label: CONTAINER_TYPE.TYPE_PIECE,
    value: 'piece',
  },
  {
    label: CONTAINER_TYPE.TYPE_WASTE,
    value: 'waste',
  },
]

export const units = [
  {
    label: UNIT.UNIT_GRAM,
    value: 'gr',
  },
  {
    label: UNIT.UNIT_MILLILITRE,
    value: 'ml',
  },
  {
    label: UNIT.UNIT_PIECE,
    value: 'piece',
  },
]
