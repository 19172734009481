import React, { FC, memo, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { TextField, Switch } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import placeholder from 'assets/img/image-placeholder.png'

interface CategoryFormProps {
  handleAddImage: (event: any) => void
  handleDeleteImage: () => void
  isVisible?: boolean
  title?: string
  image?: string
}

const CategoryForm: FC<CategoryFormProps> = ({
  handleAddImage,
  handleDeleteImage,
  isVisible,
  title,
  image,
}) => {
  const { control, setValue } = useFormContext()

  useEffect(() => {
    setValue('name', title || '')
  }, [title])

  useEffect(() => {
    setValue('is_visible', isVisible || false)
  }, [isVisible])

  return (
    <Stack sx={{ marginBottom: '16px' }} direction='column' spacing={3}>
      <TextField
        control={control}
        name='name'
        label='Название *'
        size='small'
        defaultValue={title || ''}
        required
      />
      <Typography sx={{ fontSize: '14px', lineHeight: '16px', marginBottom: '10px' }}>
        Изображение(PNG не более 1000х1000 пикс. и не более 1,5Мб)
      </Typography>
      <Stack sx={{ marginBottom: '20px' }} direction='row' spacing={2.4}>
        <Box sx={{ width: '150px', height: '150px', overflow: 'hidden' }}>
          <Box
            sx={{ objectFit: 'cover', width: '100%', minHeight: '100%' }}
            component='img'
            src={image || placeholder}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Stack direction='column' spacing={2}>
            <Button variant='contained' component='label'>
              {image ? 'Изменить' : 'Загрузить'}
              <input
                name='icon'
                type='file'
                hidden
                onChange={(event: any) => handleAddImage(event)}
              />
            </Button>
            {image && (
              <Button variant='contained' onClick={handleDeleteImage}>
                Удалить
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>
      <Switch
        control={control}
        name='is_visible'
        label='Показывать в автомате'
        size='small'
        color='success'
        defaultValue={isVisible}
      />
    </Stack>
  )
}

export default memo(CategoryForm)
