import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectProductBatchData = (state: RootState) => state.productBatch
/**
 * Returns product-batches
 */
export const selectProductBatches = createSelector(
  [selectProductBatchData],
  (productBatch) => productBatch.productBatchData.results,
)
/**
 * Returns product batches count
 */
export const selectProductBatchCount = createSelector(
  [selectProductBatchData],
  (productBatch) => productBatch.productBatchData.count || 0,
)
/**
 * Returns product-batch list for select field
 */
export const selectProductBatchesList = createSelector([selectProductBatches], (productBatches) =>
  productBatches.map((item) => {
    const date = new Date(item.expire_date).toLocaleDateString()

    return {
      label: item.code + ' от ' + date,
      value: item.id,
    }
  }),
)
/**
 * Returns product-batches is loading
 */
export const selectProductBatchesIsLoading = createSelector(
  [selectProductBatchData],
  (productBatch) => productBatch.isLoading,
)
