import React, { FC } from 'react'
import { Dialog } from '@mui/material'
import { Content } from 'components/modals/GroupModal'

interface GroupModalProps {
  isOpen: boolean
  modalType: 'change' | 'create'
  onClose: () => void
  id?: number
  name?: string
  externalId?: number
  mainTerminal?: number
  pageQuery?: number
  rowsPerPage?: number
}

const GroupModal: FC<GroupModalProps> = ({
  isOpen,
  modalType,
  onClose,
  id,
  name,
  externalId,
  mainTerminal,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '380px',
          width: '100%',
          overflowY: 'inherit',
          margin: '0px',
        },
      }}
    >
      <Content
        modalType={modalType}
        onClose={onClose}
        id={id}
        externalId={externalId}
        name={name}
        mainTerminal={mainTerminal}
      />
    </Dialog>
  )
}

export default GroupModal
