import React, { FC } from 'react'
import { Box } from '@mui/material'
import { Chart, Notifications, Products, Metrics, Marketplace } from 'components/statistics'
import { useStat } from 'hooks/statistics'

const Statistics: FC = () => {
  const { productData, marketplaceData, chartButtons, active, options, chartData, rangeLabel } =
    useStat()

  return (
    <Box>
      <Metrics rangeLabel={rangeLabel} />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '2fr 1fr' },
          columnGap: { xs: '20px', md: '40px' },
          rowGap: { xs: '20px', md: '30px' },
          marginBottom: { xs: '20px', md: '30px' },
        }}
      >
        <Chart
          chartButtons={chartButtons}
          active={active}
          options={options}
          chartData={chartData}
        />
        <Notifications />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
          columnGap: { xs: '20px', md: '40px' },
          rowGap: { xs: '20px', md: '30px' },
        }}
      >
        <Products data={productData} />
        <Marketplace data={marketplaceData} />
      </Box>
    </Box>
  )
}

export default Statistics
