import React, { FC, memo } from 'react'
import { Box, TableCell, TableRow as MUITableRow } from '@mui/material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectCategoryName } from 'store/selectors/categoriesSelectors'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  category: number
  name: string
  description: string
  icon: string
  code: string
}

const TableRow: FC<TableRowProps> = ({ id, category, name, description, icon, code }) => {
  const navigate = useNavigate()
  const categoryName = useAppSelector(selectCategoryName(category))
  const isMobile = useMediaQuery({ maxWidth: 1000 })

  const handleNavigateToProduct = () => {
    navigate(`/admin/products/${id}`)
  }

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleNavigateToProduct}
      >
        {!isMobile && (
          <TableCell component='th' scope='row'>
            {code}
          </TableCell>
        )}
        <TableCell component='th' align='left'>
          {name}
        </TableCell>
        {!isMobile && (
          <TableCell component='th' align='left'>
            {description}
          </TableCell>
        )}
        <TableCell component='th' align='left'>
          {categoryName}
        </TableCell>
        <TableCell component='th' align='left'>
          <Box sx={{ width: '50px', height: '50px', overflow: 'hidden' }}>
            <Box
              sx={{
                objectFit: 'cover',
                width: '100%',
                minHeight: '100%',
                objectPosition: 'center',
              }}
              src={icon}
              component='img'
            />
          </Box>
        </TableCell>
      </MUITableRow>
    </>
  )
}

export default memo(TableRow)
