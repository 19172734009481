import React, { FC } from 'react'
import { TextField } from 'components/generic'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

const RecoveryPasswordForm: FC = () => {
  const { control } = useFormContext()
  return (
    <Box sx={{ marginBottom: '16px' }}>
      <TextField control={control} name='email' label='Email' size='small' required />
    </Box>
  )
}

export default RecoveryPasswordForm
