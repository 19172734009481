import React, { FC } from 'react'
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SelectField, TextArea, TextField } from 'components/generic'
import { SelectFieldType } from 'types/Other'
import {
  Add as AddIcon,
  Edit as EditIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectTerminalTypesList } from 'store/selectors/terminalsSelector'
import { TerminalModel } from 'types/Terminals'
import { QRCodeCanvas } from 'qrcode.react'

interface SettingsFormProps {
  secretKey: string
  model: TerminalModel
  marketplacesList: SelectFieldType[]
  groupList: SelectFieldType[]
  handleMarketplaceCreateModalOpen: () => void
  handleMarketplaceChangeModalOpen: () => void
  handleGroupCreateModalOpen: () => void
  handleGroupChangeModalOpen: () => void
}

const SettingsForm: FC<SettingsFormProps> = ({
  secretKey,
  model,
  marketplacesList,
  groupList,
  handleMarketplaceCreateModalOpen,
  handleMarketplaceChangeModalOpen,
  handleGroupCreateModalOpen,
  handleGroupChangeModalOpen,
}) => {
  const { control, watch } = useFormContext()
  const qrCodeValue = watch('code')
  const { enqueueSnackbar } = useSnackbar()
  const terminalType = useAppSelector(selectTerminalTypesList)

  const copyToClipBoard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText)
      enqueueSnackbar('Добавлено в буфер обмена', { variant: 'info' })
    } catch (err) {
      enqueueSnackbar('Ошибка копирования', { variant: 'error' })
    }
  }

  const downloadQR = () => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = 'qr-code.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <>
      <Box>
        <Typography sx={{ marginBottom: '20px' }}>Общее</Typography>
        <Stack
          sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' }, marginBottom: '10px' }}
          direction='column'
          spacing={2}
        >
          <TextField
            control={control}
            name='address'
            label='Наименование *'
            size='small'
            defaultValue={''}
            required
          />
          {model.type === 'tcn' && (
            <TextField
              control={control}
              name=''
              label='Количество контейнеров'
              size='small'
              defaultValue={''}
              disabled
            />
          )}
          <Stack sx={{ padding: '10px 0', alignItems: 'center' }} direction='row' spacing={1}>
            <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '300' }}>
              Секретный ключ:
            </Typography>
            <Typography sx={{ fontSize: '16px', lineHeight: '19px', fontWeight: '600' }}>
              {secretKey}
            </Typography>
            <Tooltip title='Скопировать' placement='top-start'>
              <IconButton color='primary' onClick={() => copyToClipBoard(secretKey)}>
                <ContentCopyIcon color='primary' fontSize='small' />
              </IconButton>
            </Tooltip>
          </Stack>
          <TextArea control={control} name='description' label='Описание' defaultValue={''} />
        </Stack>
        <Typography sx={{ marginBottom: '20px' }}>Размещение</Typography>
        <Stack sx={{ marginBottom: '10px' }} direction='column' spacing={2}>
          <Stack direction='row' spacing={2}>
            <Box sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' } }}>
              <SelectField
                control={control}
                name='marketplace'
                label='Точка продаж *'
                size='small'
                selectItem={marketplacesList}
                defaultValue={''}
                required
              />
            </Box>
            <Tooltip title='Добавить точку продаж' placement='top-start'>
              <IconButton color='primary' onClick={handleMarketplaceCreateModalOpen}>
                <AddIcon color='primary' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Редактировать точку продаж' placement='top-start'>
              <IconButton color='primary' onClick={handleMarketplaceChangeModalOpen}>
                <EditIcon color='primary' />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack direction='row' spacing={2}>
            <Box sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' } }}>
              <SelectField
                control={control}
                name='group'
                label='Группа автоматов'
                size='small'
                selectItem={groupList}
                defaultValue={''}
              />
            </Box>
            <Tooltip title='Добавить группу автоматов' placement='top-start'>
              <IconButton color='primary' onClick={handleGroupCreateModalOpen}>
                <AddIcon color='primary' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Изменить группу автоматов' placement='top-start'>
              <IconButton color='primary' onClick={handleGroupChangeModalOpen}>
                <EditIcon color='primary' />
              </IconButton>
            </Tooltip>
          </Stack>
          <Box sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' } }}>
            <SelectField
              control={control}
              name='type'
              label='Тип автомата'
              size='small'
              selectItem={terminalType}
            />
          </Box>
        </Stack>
        <Typography sx={{ marginBottom: '20px' }}>Прочее</Typography>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={2}>
            <Box sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' } }}>
              <TextField
                control={control}
                name='code'
                label='Код для нанесения на автомат *'
                size='small'
                // defaultValue={''}
                required
                type='number'
              />
            </Box>
            <Button sx={{ whiteSpace: 'nowrap' }} onClick={downloadQR} variant='contained'>
              Скачать QR
            </Button>
            <Box sx={{ display: 'none' }}>
              <QRCodeCanvas level={'H'} includeMargin={true} id='qr-code' value={qrCodeValue} />
            </Box>
          </Stack>
          <Box sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' } }}>
            <TextField
              control={control}
              name='external_id'
              label='Внешний id для внешних систем, например 1С*'
              size='small'
              // defaultValue={''}
              type='number'
              required
            />
          </Box>
        </Stack>
      </Box>
    </>
  )
}

export default SettingsForm
