import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Product, ProductsData } from 'types/Products'
import { http } from 'utils/httpConfig'

export const getProducts = createAsyncThunk(
  'products/get_products',
  async (
    params: {
      page?: number
      page_size?: number
      ordering?: string
      is_popular?: string
      is_warm_up?: string
      is_ingredient?: string
      category?: string
      name__icontains?: string
    },
    thunkAPI,
  ) => {
    const {
      page,
      page_size,
      ordering,
      is_popular,
      is_warm_up,
      is_ingredient,
      category,
      name__icontains,
    } = params
    try {
      const response = await http.get<ProductsData>('/api/admin/product/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          is_popular,
          is_warm_up,
          is_ingredient,
          category,
          name__icontains,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get products data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get products data, ${error}`])
      }
    }
  },
)

export const getProduct = createAsyncThunk(
  'products/get_product',
  async (params: { id?: string | number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<ProductsData>(`/api/admin/product/${id}/`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get product, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get product, ${error}`])
      }
    }
  },
)

export const updateProduct = createAsyncThunk(
  'products/update_product',
  async (params: { id?: number; formValues: any }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Product>(`/api/admin/product/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update product, error${error}`])
      }
    }
  },
)

export const deleteProduct = createAsyncThunk(
  'products/delete_product',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/product/${id}/`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(`Failed to delete product, ${error}`)
    }
  },
)

export const partialUpdateProduct = createAsyncThunk(
  'products/partial_update_product',
  async (params: { formValues: FormData; id?: number }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<any>(`/api/admin/product/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update product, error${error}`])
      }
    }
  },
)

export const createProduct = createAsyncThunk(
  'products/create_product',
  async (params: { formValues: any }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<Product>('/api/admin/product/', formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create product, error${error}`])
      }
    }
  },
)
