import React, { FC, useEffect } from 'react'
import { ThemeProvider } from '@mui/material'
import { useAuth, useStyles } from 'hooks'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AdminLayout, AuthLayout, TerminalsLayout, ProductsLayout, TerminalLayout } from 'layouts'
import {
  Categories,
  Groups,
  Orders,
  Products,
  Marketplace,
  Statistics,
  Users,
  Terminals,
  Product,
} from 'views/dashboard'
import { LogIn, PageNotFoundView, PasswordRecovery, Registration } from 'views/pages'
import { PrivateRoute } from 'components/generic'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getСompanies } from 'store/reducers/CompanySlice/ActionCreators'
import { selectUsersIsLoading } from 'store/selectors/usersSelectors'
import { Cells, Containers, Settings } from 'components/terminal'

const App: FC = () => {
  const theme = useStyles()
  const { isAuth } = useAuth()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectUsersIsLoading)

  useEffect(() => {
    dispatch(getСompanies())
  }, [dispatch, isLoading])

  return (
    <ThemeProvider theme={theme}>
      <Routes location={location}>
        <Route path='/' element={<AuthLayout />}>
          <Route index element={<LogIn />} />
          <Route path='recovery_password' element={<PasswordRecovery />} />
          <Route path='registration' element={<Registration />} />
        </Route>

        {isAuth && (
          <Route
            path='/admin'
            element={
              <PrivateRoute>
                <AdminLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Statistics />} />
            <Route path='users' element={<Users />} />
            <Route path='marketplace' element={<Marketplace />} />
            <Route path='terminals' element={<TerminalsLayout />}>
              <Route index element={<Terminals />} />
              <Route path=':terminalId' element={<TerminalLayout />}>
                <Route path='' element={<Settings />} />
                <Route path='cells' element={<Cells />} />
                <Route path='containers' element={<Containers />} />
              </Route>
            </Route>
            <Route path='groups' element={<Groups />} />
            <Route path='orders' element={<Orders />} />
            <Route path='categories' element={<Categories />} />
            <Route path='products' element={<ProductsLayout />}>
              <Route index element={<Products />} />
              <Route path=':productId' element={<Product />} />
            </Route>
          </Route>
        )}
        <Route path='*' element={<PageNotFoundView />} />
      </Routes>
    </ThemeProvider>
  )
}

export default App
