import { useState, useMemo } from 'react'

const useProductFilter = (clearPageState: () => void) => {
  const [isIngredient, setIsIngredient] = useState<string | null>(
    sessionStorage.getItem('isIngredient'),
  )
  const [isWarmUp, setIsWarmUp] = useState<string | null>(sessionStorage.getItem('isWarm'))
  const [isPopular, setIsPopular] = useState<string | null>(sessionStorage.getItem('isPopular'))
  const [category, setCategory] = useState<string | null>(sessionStorage.getItem('category'))
  const [searchValue, setSearchValue] = useState<string>('')

  const selectedFilterCount = useMemo(() => {
    return [isIngredient, isWarmUp, isPopular, category, searchValue].filter((item) => item).length
  }, [isIngredient, isWarmUp, isPopular, category, searchValue])

  const handleFilterIsIngredient = (event: { target: { value: string } }) => {
    clearPageState()
    setIsIngredient(event.target.value)
    sessionStorage.setItem('isIngredient', event.target.value.toString())
  }
  const handleFilterIsWarmUp = (event: { target: { value: string } }) => {
    clearPageState()
    setIsWarmUp(event.target.value)
    sessionStorage.setItem('isWarm', event.target.value.toString())
  }
  const handleFilterIsPopular = (event: { target: { value: string } }) => {
    clearPageState()
    setIsPopular(event.target.value)
    sessionStorage.setItem('isPopular', event.target.value.toString())
  }
  const handleFilterCategory = (event: { target: { value: string } }) => {
    clearPageState()
    setCategory(event.target.value)
    sessionStorage.setItem('category', event.target.value.toString())
  }
  const handleSearch = (event: { target: { value: string } }) => {
    clearPageState()
    setSearchValue(event.target.value)
  }

  const handleClearFilter = () => {
    setIsIngredient('')
    setIsWarmUp('')
    setIsPopular('')
    setCategory('')
    sessionStorage.removeItem('isIngredient')
    sessionStorage.removeItem('isWarm')
    sessionStorage.removeItem('isPopular')
    sessionStorage.removeItem('category')
  }

  return {
    isIngredient,
    isWarmUp,
    isPopular,
    category,
    searchValue,
    selectedFilterCount,
    handleFilterIsIngredient,
    handleFilterIsWarmUp,
    handleFilterIsPopular,
    handleFilterCategory,
    handleSearch,
    handleClearFilter,
  }
}

export default useProductFilter
