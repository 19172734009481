import { useCallback, useState, useEffect } from 'react'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { HeadCell, RowData } from 'types/Table'
import { getGroups } from 'store/reducers/GroupsSlice/ActionCreators'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import {
  selectGroups,
  selectGroupsCount,
  selectGroupsIsLoading,
} from 'store/selectors/groupsSelectors'
import { getTerminals } from 'store/reducers/TerminalsSlice/ActionCreators'
import { useMediaQuery } from 'react-responsive'

const useGroups = () => {
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const dispatch = useAppDispatch()
  const [rows, setRows] = useState<RowData[] | any>([])
  const [groupModal, setGroupModal] = useState<boolean>(false)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const groupsIsLoading = useAppSelector(selectGroupsIsLoading)
  const count = useAppSelector(selectGroupsCount)
  const groups = useAppSelector(selectGroups)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'external_id',
      label: 'Код',
      sort: 'external_id',
    },
    {
      id: 'name',
      label: 'Название группы',
      sort: 'name',
    },
  ].filter((item) => {
    if (isHidden600) {
      return item.id === 'name'
    } else {
      return item
    }
  }) as HeadCell[]

  useEffect(() => {
    dispatch(getGroups({ page: pageQuery, page_size: rowsPerPage, ordering }))
  }, [page, rowsPerPage, pageQuery, ordering])

  useEffect(() => {
    dispatch(getTerminals({}))
  }, [])

  useEffect(() => {
    setRows(
      groups.map((item) => {
        return { ...item }
      }),
    )
  }, [groups, rowsPerPage, page])

  const handleModalOpen = useCallback(() => {
    setGroupModal(true)
  }, [groupModal])

  const handleModalClose = useCallback(() => {
    setGroupModal(false)
  }, [groupModal])
  return {
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    headCells,
    groupsIsLoading,
    count,
    groupModal,
    handleModalOpen,
    handleModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useGroups
