import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product, ProductsData } from 'types/Products'
import {
  createProduct,
  deleteProduct,
  getProduct,
  getProducts,
  partialUpdateProduct,
  updateProduct,
} from './ActionCreators'

interface ProductsState {
  productsData: {
    count: number
    results: Product[]
  }
  product: Product
  isLoadingProductsData: boolean
  isLoadingProduct: boolean
  errors: string[]
}

const initialState: ProductsState = {
  productsData: {
    count: 0,
    results: [],
  },
  product: {
    id: 0,
    name: '',
    name_en: '',
    description: '',
    description_en: '',
    weight: 0,
    unit: '',
    cooking_time: 0,
    category: 0,
    icon: '',
    video: '',
    energy: 0,
    proteins: 0,
    fats: 0,
    carbs: 0,
    is_popular: false,
    is_warm_up: false,
    is_ingredient: false,
    code: '',
    short_code: '',
    expire_days_notification: 0,
    expire_days_for_sale: 0,
    ingredients: [],
    non_food: false,
  },
  isLoadingProductsData: false,
  isLoadingProduct: false,
  errors: [],
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = []
    },
  },
  extraReducers: {
    [getProducts.fulfilled.type]: (state, action: PayloadAction<ProductsData>) => {
      state.isLoadingProductsData = false
      state.errors = []
      state.productsData.count = action.payload.count
      state.productsData.results = action.payload.results
    },
    [getProducts.pending.type]: (state) => {
      state.isLoadingProductsData = true
    },
    [getProducts.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingProductsData = false
      state.errors = action.payload
    },
    [getProduct.fulfilled.type]: (state, action: PayloadAction<Product>) => {
      state.isLoadingProduct = false
      state.errors = []
      state.product = action.payload
    },
    [getProduct.pending.type]: (state) => {
      state.isLoadingProduct = true
    },
    [getProduct.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingProduct = false
      state.errors = action.payload
    },
    [updateProduct.fulfilled.type]: (state, action: PayloadAction<Product>) => {
      state.isLoadingProduct = false
      state.errors = []
      state.product = action.payload
    },
    [updateProduct.pending.type]: (state) => {
      state.isLoadingProduct = true
    },
    [updateProduct.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingProduct = false
      state.errors = action.payload
    },
    [deleteProduct.fulfilled.type]: (state) => {
      state.isLoadingProduct = false
      state.errors = []
    },
    [deleteProduct.pending.type]: (state) => {
      state.isLoadingProduct = true
    },
    [deleteProduct.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingProduct = false
      state.errors = action.payload
    },
    [partialUpdateProduct.fulfilled.type]: (state, action: PayloadAction<Product>) => {
      state.isLoadingProduct = false
      state.errors = []
      state.product = action.payload
    },
    [partialUpdateProduct.pending.type]: (state) => {
      state.isLoadingProduct = true
    },
    [partialUpdateProduct.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingProduct = false
      state.errors = action.payload
    },
    [createProduct.fulfilled.type]: (state) => {
      state.isLoadingProduct = false
      state.errors = []
    },
    [createProduct.pending.type]: (state) => {
      state.isLoadingProduct = true
    },
    [createProduct.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoadingProduct = false
      state.errors = action.payload
    },
  },
})

export default productsSlice.reducer
