import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { GroupModal } from 'components/modals'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  name?: string
  externalId?: number
  mainTerminal?: number
}

const TableRow: FC<TableRowProps> = ({ id, name, externalId, mainTerminal }) => {
  const [changeGroupModal, setСhangeGroupModal] = useState<boolean>(false)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const handleChangeModalOpen = useCallback(() => {
    setСhangeGroupModal(true)
  }, [changeGroupModal])

  const handleChangeModalClose = useCallback(() => {
    setСhangeGroupModal(false)
  }, [changeGroupModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleChangeModalOpen}
      >
        {!isMobile && (
          <TableCell component='th' scope='row'>
            {externalId || '-'}
          </TableCell>
        )}
        <TableCell component='th' align='left'>
          {name}
        </TableCell>
      </MUITableRow>

      <GroupModal
        id={id}
        name={name}
        externalId={externalId}
        mainTerminal={mainTerminal}
        isOpen={changeGroupModal}
        onClose={handleChangeModalClose}
        modalType='change'
      />
    </>
  )
}

export default TableRow
