import React, { FC } from 'react'
import { Box, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/categories'
import { CategoryModal } from 'components/modals'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { useCategories } from 'hooks/categories'
import { RowData } from 'types/Table'

const Categories: FC = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    headCells,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    categoriesIsLoading,
    createCategoryModal,
    handleCreateCategoryModalOpen,
    handleCreateCategoryModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useCategories()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Box>
          <Toolbar
            title='Список категорий'
            buttonLabel='+ Добавить категорию'
            handleModalOpen={handleCreateCategoryModalOpen}
            isLoadingData={categoriesIsLoading}
          />
          <TableContainer sx={{ height: { xs: 'auto', sm: 'calc(100vh - 275px)' } }}>
            <MUITable stickyHeader size='small'>
              <TableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rows.map((row: RowData) => (
                  <TableRow
                    id={Number(row.id)}
                    key={row.id}
                    name={row.name}
                    icon={row.icon}
                    isVisible={row.is_visible}
                  />
                ))}
              </TableBody>
            </MUITable>
          </TableContainer>
        </Box>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            padding: '10px 24px',
            borderTop: '1px solid',
            borderColor: 'divider',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </Stack>
      </Box>
      <CategoryModal
        isOpen={createCategoryModal}
        onClose={handleCreateCategoryModalClose}
        modalType='create'
      />
    </>
  )
}

export default Categories
