import React, { FC } from 'react'
import {
  Toolbar as MUIToolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Skeleton,
  Stack,
} from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import { Add as AddIcon, FilterAlt as FilterAltIcon } from '@mui/icons-material'
import LinearProgress from '../LinearProgress/LinearProgress'

interface TableToolbarProps {
  title: string
  buttonLabel?: string
  handleModalOpen?: () => void
  isLoading?: boolean
  isFilterButton?: boolean
  isOpen?: boolean
  selectedFilterCount?: number
  handleToggleFilter?: () => void
  isLoadingData?: boolean
}

const Toolbar: FC<TableToolbarProps> = ({
  title,
  buttonLabel,
  handleModalOpen,
  isLoading,
  isFilterButton,
  isOpen,
  selectedFilterCount,
  handleToggleFilter,
  isLoadingData,
}) => {
  const isHidden900 = useMediaQuery({ maxWidth: 900 })

  return (
    <MUIToolbar
      sx={{
        padding: { xs: '0px 20px !important', sm: '0px 35px !important' },
        borderBottom: '1px solid',
        borderColor: 'divider',
        position: 'relative',
      }}
    >
      {isLoadingData && <LinearProgress />}
      <Typography
        sx={{ fontSize: '18px', lineHeight: '21px', flex: '1 1 100%', padding: '10px 0' }}
        variant='h5'
        id='tableTitle'
        component='div'
      >
        {isLoading ? <Skeleton variant='rounded' width={300} height={41} /> : title}
      </Typography>
      <Stack direction='row' spacing={2}>
        {buttonLabel && (
          <>
            {isHidden900 ? (
              <Tooltip title={buttonLabel} placement='top-start'>
                <IconButton color='primary' onClick={handleModalOpen}>
                  <AddIcon fontSize='large' />
                </IconButton>
              </Tooltip>
            ) : (
              <Button sx={{ whiteSpace: 'nowrap' }} variant='text' onClick={handleModalOpen}>
                {buttonLabel}
              </Button>
            )}
          </>
        )}
        {isFilterButton && (
          <>
            {isHidden900 ? (
              <IconButton color='primary' onClick={handleToggleFilter}>
                <FilterAltIcon fontSize='medium' />
              </IconButton>
            ) : (
              <Button
                sx={{ border: '1px solid', whiteSpace: 'nowrap', padding: '9px 11px 9px 8px' }}
                variant={isOpen ? 'contained' : 'outlined'}
                onClick={handleToggleFilter}
              >
                <FilterAltIcon sx={{ marginRight: '5px' }} fontSize='small' />
                Выбрано: {selectedFilterCount}
              </Button>
            )}
          </>
        )}
      </Stack>
    </MUIToolbar>
  )
}

export default Toolbar
