import React, { FC } from 'react'
import { Box, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { Filter, TableRow } from 'components/terminals'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { TerminalModal } from 'components/modals'
import { useTerminals } from 'hooks/terminals'
import { RowData } from 'types/Table'

const Terminals: FC = () => {
  const {
    headCells,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    modal,
    handleModalOpen,
    handleModalClose,
    marketplace,
    handleFilterMarketplace,
    group,
    handleFilterGroup,
    cleaning,
    handleFilterCleaning,
    terminalType,
    handleFilterTerminalType,
    terminalsIsLoading,
    selectedFilterCount,
    filterIsOpen,
    filterHeight,
    handleToggleFilter,
    handleFilterClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useTerminals()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Toolbar
          title='Cписок автоматов'
          buttonLabel='+ Добавить автомат'
          handleModalOpen={handleModalOpen}
          isFilterButton
          isOpen={filterIsOpen}
          selectedFilterCount={selectedFilterCount}
          handleToggleFilter={handleToggleFilter}
          isLoadingData={terminalsIsLoading}
        />
        <Filter
          isOpen={filterIsOpen}
          handleFilterClose={handleFilterClose}
          marketplace={marketplace}
          group={group}
          cleaning={cleaning}
          terminalType={terminalType}
          handleFilterMarketplace={handleFilterMarketplace}
          handleFilterGroup={handleFilterGroup}
          handleFilterCleaning={handleFilterCleaning}
          handleFilterTerminalType={handleFilterTerminalType}
        />
        <TableContainer
          sx={{
            height: { xs: 'auto', sm: `calc(100vh - ${filterHeight}px)` },
            transition: 'all 0.3s ease-in-out',
            zIndex: '20',
          }}
        >
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  key={row.id}
                  id={row.id}
                  address={row.address}
                  containersNeedRevision={row.containers_need_revision}
                  modelName={row.modelName}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            padding: '10px 24px',
            borderTop: '1px solid',
            borderColor: 'divider',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </Stack>
      </Box>
      <TerminalModal isOpen={modal} onClose={handleModalClose} />
    </>
  )
}

export default Terminals
