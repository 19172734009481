import React, { FC } from 'react'
import { FormControlLabel, Switch as MUISwitch, SwitchPropsColorOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { Control, Controller, FieldValues } from 'react-hook-form'

interface SwitchProps {
  control: Control<FieldValues>
  name: string
  defaultValue?: boolean
  label?: string
  required?: boolean
  size?: 'small' | 'medium'
  disabled?: boolean
  color?: OverridableStringUnion<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default',
    SwitchPropsColorOverrides
  >
}

const Switch: FC<SwitchProps> = ({
  control,
  name,
  defaultValue,
  label,
  required,
  size,
  disabled,
  color,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          value={value}
          onChange={onChange}
          disabled={disabled}
          control={<MUISwitch onChange={onChange} size={size} color={color} checked={value} />}
          label={label}
        />
      )}
      rules={{
        required: required,
      }}
    />
  )
}

export default Switch
