import React, { FC } from 'react'
import { Line } from 'react-chartjs-2'
import { Box, Button, Stack, Typography } from '@mui/material'

interface ChartProps {
  chartButtons: {
    id: string
    label: string
    func: () => void
  }[]
  active: string
  options: any
  chartData: any
}

const Сhart: FC<ChartProps> = ({ chartButtons, active, options, chartData }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#1C2A4B',
        padding: { xs: '15px 20px 15px 20px', sm: '25px 40px 15px 40px' },
        borderRadius: '10px',
      }}
    >
      <Stack
        direction='column'
        spacing={2.5}
        sx={{
          marginBottom: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '18px', lineHeight: '24px', color: '#ffffff' }}>
          Выручка
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr 1fr', sm: '1fr 1fr 1fr 1fr' },
            columnGap: { xs: '10px', sm: '20px' },
            rowGap: { xs: '10px', sm: '20px' },
          }}
        >
          {chartButtons.map((button) => (
            <Button
              key={button.id}
              variant='contained'
              color={active === button.id ? 'primary' : 'secondary'}
              onClick={button.func}
            >
              {button.label}
            </Button>
          ))}
        </Box>
      </Stack>
      <Box
        sx={{
          position: 'relative',
          height: { xs: '200px', md: '350px' },
          width: '100%',
        }}
      >
        <Line style={{ position: 'absolute' }} options={options} data={chartData} />
      </Box>
    </Box>
  )
}

export default Сhart
