import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Group, GroupsData } from 'types/Groups'
import { getGroups, updateGroup, deleteGroup, createGroup, getGroup } from './ActionCreators'

interface ProductsState {
  groupsData: {
    count: number
    results: Group[]
  }
  group: Group
  groupsIsLoading: boolean
  groupIsLoading: boolean
  errors: string[]
}

const initialState: ProductsState = {
  groupsData: {
    count: 0,
    results: [],
  },
  group: {
    id: 0,
    name: '',
    external_id: 0,
    main_terminal: 0,
  },
  groupsIsLoading: false,
  groupIsLoading: false,
  errors: [],
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = []
    },
  },
  extraReducers: {
    [getGroups.fulfilled.type]: (state, action: PayloadAction<GroupsData>) => {
      state.groupsIsLoading = false
      state.errors = []
      state.groupsData.count = action.payload.count
      state.groupsData.results = action.payload.results
    },
    [getGroups.pending.type]: (state) => {
      state.groupsIsLoading = true
    },
    [getGroups.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.groupsIsLoading = false
      state.errors = action.payload
    },
    [getGroup.fulfilled.type]: (state, action: PayloadAction<Group>) => {
      state.groupIsLoading = false
      state.errors = []
      state.group = action.payload
    },
    [getGroup.pending.type]: (state) => {
      state.groupIsLoading = true
    },
    [getGroup.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.groupIsLoading = false
      state.errors = action.payload
    },
    [updateGroup.fulfilled.type]: (state) => {
      state.groupIsLoading = false
      state.errors = []
    },
    [updateGroup.pending.type]: (state) => {
      state.groupIsLoading = true
    },
    [updateGroup.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.groupIsLoading = false
      state.errors = action.payload
    },
    [deleteGroup.fulfilled.type]: (state) => {
      state.groupIsLoading = false
      state.errors = []
    },
    [deleteGroup.pending.type]: (state) => {
      state.groupIsLoading = true
    },
    [deleteGroup.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.groupIsLoading = false
      state.errors = action.payload
    },
    [createGroup.fulfilled.type]: (state) => {
      state.groupIsLoading = false
      state.errors = []
    },
    [createGroup.pending.type]: (state) => {
      state.groupIsLoading = true
    },
    [createGroup.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.groupIsLoading = false
      state.errors = action.payload
    },
  },
})

export default groupsSlice.reducer
