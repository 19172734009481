import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import { selectProductList } from 'store/selectors/productsSelector'
import {
  createContainer,
  getContainer,
  getContainers,
  updateContainer,
} from 'store/reducers/ContainersSlice/ActionCreators'
import { ContainerFormType } from 'types/FormTypes'
import { Container } from 'types/Containers'
import { useSnackbar } from 'notistack'
import {
  selectContainer,
  selectContainerIsLoading,
  selectContainersErrors,
} from 'store/selectors/containerSelectors'
import { containersSlice } from 'store/reducers/ContainersSlice/ContrainersSlice'
import { useParams } from 'react-router-dom'

const useContainerModal = (modalType: 'change' | 'create', onClose: () => void, id?: number) => {
  const dispatch = useAppDispatch()
  const { terminalId } = useParams()
  const {
    product,
    size,
    unit,
    code,
    type,
    batches,
    cleaning_interval,
    cleaned_at,
    need_cleaning,
    orders_for_cleaning,
  } = useAppSelector<Container>(selectContainer)
  const containerIsLoading = useAppSelector(selectContainerIsLoading)
  const containerErrors = useAppSelector(selectContainersErrors)
  const { clearErrors } = containersSlice.actions
  const methods = useForm<ContainerFormType>({
    defaultValues: {
      terminal: terminalId,
      cleaning_interval: null,
    },
  })
  const { setValue } = methods
  const productList = useAppSelector(selectProductList)
  const { enqueueSnackbar } = useSnackbar()

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/containers/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/containers/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const onSubmit = (formValues: ContainerFormType) => {
    if (modalType === 'change') {
      dispatch(updateContainer({ id, formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getContainers({ page: page, page_size: pageSize, terminal: terminalId }))
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      dispatch(createContainer({ formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getContainers({ page: page, page_size: pageSize, terminal: terminalId }))
            enqueueSnackbar('Контейнер успешно создан', { variant: 'success' })
            onClose()
          }
        })
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(getContainer({ id }))
    }
  }, [id])

  useEffect(() => {
    if (!containerIsLoading && modalType === 'change') {
      setValue('code', Number(code))
      setValue('product', product)
      setValue('type', type)
      setValue('size', size.toString())
      setValue('unit', unit)
      setValue('need_cleaning', need_cleaning)
      setValue('cleaning_interval', Number(cleaning_interval))
      setValue('orders_for_cleaning', orders_for_cleaning)
      setValue('batches', batches)

      if (cleaned_at) {
        setValue('cleaned_at', new Date(cleaned_at))
      } else {
        setValue('cleaned_at', new Date())
      }
    } else {
      setValue('cleaned_at', new Date())
    }
  }, [
    code,
    product,
    type,
    size,
    unit,
    batches,
    containerIsLoading,
    need_cleaning,
    cleaning_interval,
    cleaned_at,
    orders_for_cleaning,
    modalType,
  ])

  useEffect(() => {
    if (containerErrors.length > 0) {
      containerErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [containerErrors])

  return {
    methods,
    onSubmit,
    productList,
    need_cleaning,
  }
}

export default useContainerModal
