import React, { FC } from 'react'
import { Box } from '@mui/material'
import StatCard from './MetricsCard/MetricsCard'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectMetricsData } from 'store/selectors/statSelectors'

interface MetricsProps {
  rangeLabel: string
}

const Metrics: FC<MetricsProps> = ({ rangeLabel }) => {
  const data = useAppSelector(selectMetricsData)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr 1fr', md: '1fr 1fr 1fr 1fr' },
        columnGap: { xs: '20px', sm: '35px' },
        rowGap: '20px',
        marginBottom: { xs: '20px', sm: '30px' },
      }}
    >
      {data.map((item) => (
        <StatCard
          key={item.id}
          title={item.title}
          stat={item.stat}
          precent={item.precent}
          trend={item.trend}
          rangeLabel={rangeLabel}
        />
      ))}
    </Box>
  )
}

export default Metrics
