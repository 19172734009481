import React, { FC, memo } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { ProductForm } from 'components/modals/ProductModal'
import { useProductModal } from 'hooks/products'
import { CategoryModal } from 'components/modals'

interface ProductModalProps {
  isOpen: boolean
  onClose: () => void
  modalType: 'create' | 'change'
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  category?: number
  shortCode?: string
  code?: string
  unit?: string
  weight?: number
  cookingTime?: number
  energy?: number
  proteins?: number
  fats?: number
  carbs?: number
  icon?: string
  isIngredient?: boolean
  isWarmUp?: boolean
  isPopular?: boolean
  nonFood?: boolean
}

const ProductModal: FC<ProductModalProps> = ({
  isOpen,
  onClose,
  modalType,
  id,
  name,
  nameEn,
  description,
  descriptionEn,
  category,
  shortCode,
  code,
  unit,
  weight,
  cookingTime,
  energy,
  proteins,
  fats,
  carbs,
  icon,
  isIngredient,
  isWarmUp,
  isPopular,
  nonFood,
}) => {
  const {
    methods,
    image,
    handleAddImage,
    handleDeleteImage,
    onSubmit,
    categoryId,
    categoryName,
    categoryIcon,
    categoryIsVisible,
    categoryCreateModal,
    categoryChangeModal,
    handleCategoryCreateModalOpen,
    handleCategoryCreateModalClose,
    handleCategoryChangeModalOpen,
    handleCategoryChangeModalClose,
  } = useProductModal(modalType, onClose, id, icon)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '800px',
          width: '100%',
          overflowY: 'auto',
          margin: '0',
        },
      }}
    >
      <Stack sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }} direction='column' spacing={3}>
        <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Создать товар</Typography>
        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <ProductForm
                name={name}
                nameEn={nameEn}
                description={description}
                descriptionEn={descriptionEn}
                category={category}
                shortCode={shortCode}
                code={code}
                unit={unit}
                weight={weight}
                cookingTime={cookingTime}
                energy={energy}
                proteins={proteins}
                fats={fats}
                carbs={carbs}
                image={image}
                isIngredient={isIngredient}
                isWarmUp={isWarmUp}
                isPopular={isPopular}
                nonFood={nonFood}
                handleAddImage={handleAddImage}
                handleDeleteImage={handleDeleteImage}
                handleCategoryCreateModalOpen={handleCategoryCreateModalOpen}
                handleCategoryChangeModalOpen={handleCategoryChangeModalOpen}
              />
            </Box>

            <Button sx={{ width: '100%' }} variant='contained' type='submit'>
              Сохранить
            </Button>
          </Box>
        </FormProvider>
      </Stack>
      <CategoryModal
        isOpen={categoryCreateModal}
        onClose={handleCategoryCreateModalClose}
        modalType='create'
      />
      <CategoryModal
        id={categoryId}
        title={categoryName}
        icon={categoryIcon}
        isVisible={categoryIsVisible}
        isOpen={categoryChangeModal}
        onClose={handleCategoryChangeModalClose}
        modalType='change'
      />
    </Dialog>
  )
}

export default memo(ProductModal)
