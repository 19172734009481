import React, { FC } from 'react'
import { Box, Button, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/product/Batch'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { ProductBatchModal } from 'components/modals'
import { ProductFormTypes } from 'types/FormTypes'
import { UseFormReturn } from 'react-hook-form'
import { useBatches } from 'hooks/batches'
import { RowData } from 'types/Table'

interface BatchProps {
  id: number
  name: string
  methods: UseFormReturn<ProductFormTypes, any>
  onSubmit: (formValues: ProductFormTypes) => void
  handleConfirmModalOpen: () => void
  handleBackRoute: () => void
}

const Batch: FC<BatchProps> = ({
  id,
  name,
  methods,
  onSubmit,
  handleConfirmModalOpen,
  handleBackRoute,
}) => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    productBatchesIsLoading,
    headCells,
    batchModal,
    handleBatchModalOpen,
    handleBatchModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useBatches(id)

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar
          title={name}
          buttonLabel='+ Добавить партию'
          handleModalOpen={handleBatchModalOpen}
          isLoadingData={productBatchesIsLoading}
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 320px)', minHeight: 'calc(100vh - 320px)' }}>
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  key={row.id}
                  id={row.id}
                  productId={id}
                  code={row.code}
                  expireDate={row.expire_date}
                  buyingPrice={row.buying_price}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            padding: '10px 24px',
            alignItems: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
            justifyContent: 'space-between',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
          <Stack direction='row' spacing={2}>
            <Button
              sx={{ color: 'error.main' }}
              variant='outlined'
              onClick={handleConfirmModalOpen}
              color='error'
            >
              Удалить
            </Button>
            <Button variant='outlined' onClick={handleBackRoute}>
              Закрыть
            </Button>
            <Button variant='contained' onClick={methods.handleSubmit(onSubmit)}>
              Сохранить
            </Button>
          </Stack>
        </Stack>
      </Box>
      <ProductBatchModal
        isOpen={batchModal}
        onClose={handleBatchModalClose}
        modalType='create'
        productId={id}
      />
    </>
  )
}

export default Batch
