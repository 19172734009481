import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectProductsData = (state: RootState) => state.products
/**
 * Returns categories
 */
export const selectProducts = createSelector(
  [selectProductsData],
  (products) => products.productsData.results,
)
/**
 * Returns categories count
 */
export const selectProductsCount = createSelector(
  [selectProductsData],
  (products) => products.productsData.count || 0,
)
/**
 * Returns product data
 */
export const selectProduct = createSelector([selectProductsData], (products) => products.product)
/**
 * Returns products isLoading
 */
export const selectProductsIsLoading = createSelector(
  [selectProductsData],
  (products) => products.isLoadingProductsData,
)
/**
 * Returns product isLoading
 */
export const selectProductIsLoading = createSelector(
  [selectProductsData],
  (products) => products.isLoadingProduct,
)
/**
 * Returns product name
 */
export const selectProductName = (productId: string | undefined | number) =>
  createSelector(
    [selectProductsData],
    (products) =>
      products.productsData.results.find((item) => item.id === Number(productId))?.name || '',
  )
/**
 * Returns products list for select field
 */
export const selectProductList = createSelector([selectProductsData], (products) =>
  products.productsData.results.map((item) => {
    return {
      label: item.name,
      value: item.id,
    }
  }),
)
/**
 * Returns isLoading product
 */
export const selectisLoadingProduct = createSelector(
  [selectProductsData],
  (products) => products.isLoadingProduct,
)
/**
 * Returns product errors
 */
export const selectProductErrors = createSelector(
  [selectProductsData],
  (products) => products.errors,
)
/**
 * Returns product nonfood field
 */
export const selectNonFoodField = (productId: number) =>
  createSelector(
    [selectProducts],
    (products) => products.find((product) => product.id === productId)?.non_food,
  )
