import React, { FC } from 'react'
import { Box, Button, Stack } from '@mui/material'
import { SearchInput, SelectFilterField } from 'components/generic'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { SelectFieldType } from 'types/Other'
import { selectCategiesSelectList } from 'store/selectors/categoriesSelectors'

interface FilterProps {
  isOpen: boolean
  isIngredient: string | null
  isWarmUp: string | null
  isPopular: string | null
  category: string | null
  searchValue: string
  handleFilterIsIngredient: (event: { target: { value: string } }) => void
  handleFilterIsWarmUp: (event: { target: { value: string } }) => void
  handleFilterIsPopular: (event: { target: { value: string } }) => void
  handleFilterCategory: (event: { target: { value: string } }) => void
  handleSearch: (event: { target: { value: string } }) => void
  handleClearFilter: () => void
}

const Filter: FC<FilterProps> = ({
  isOpen,
  isIngredient,
  isWarmUp,
  isPopular,
  category,
  searchValue,
  handleFilterIsIngredient,
  handleFilterIsWarmUp,
  handleFilterIsPopular,
  handleFilterCategory,
  handleSearch,
  handleClearFilter,
}) => {
  const categoriesList = useAppSelector(selectCategiesSelectList)

  const isTrueFalseList: SelectFieldType[] = [
    {
      label: 'Да',
      value: 'true',
    },
    {
      label: 'Нет',
      value: 'false',
    },
  ]

  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: {
          xs: isOpen ? '290px' : '0px',
          sm: isOpen ? '180px' : '0px',
          md: isOpen ? '130px' : '0px',
          lg: isOpen ? '70px' : '0px',
        },
        transition: 'all 0.3s ease-in-out',
        padding: isOpen ? { xs: '15px 20px', sm: '15px 35px' } : { xs: '0px 20px', sm: '0px 35px' },
        backgroundColor: '#6271DD',
      }}
      direction={{ xs: 'column', lg: 'row' }}
      spacing={2}
    >
      <Box sx={{ minWidth: '130px' }}>
        <SearchInput value={searchValue} handleChange={handleSearch} />
      </Box>
      <Box
        sx={{
          opacity: isOpen ? '1' : '0',
          transition: 'all 0.3s ease-in-out',
          width: { xs: '100%', lg: '75%' },
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            md: '1fr 1fr 1fr 1fr',
          },
          columnGap: { xs: '15px', md: '20px' },
          rowGap: { xs: '15px', md: '20px' },
        }}
      >
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={category}
            label='Категория'
            handleChange={handleFilterCategory}
            selectItem={categoriesList}
          />
        </Box>
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={isIngredient}
            label='Может быть ингредиентом'
            handleChange={handleFilterIsIngredient}
            selectItem={isTrueFalseList}
          />
        </Box>
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={isWarmUp}
            label='Разогрев'
            handleChange={handleFilterIsWarmUp}
            selectItem={isTrueFalseList}
          />
        </Box>
        <Box sx={{ minWidth: '150px' }}>
          <SelectFilterField
            value={isPopular}
            label='Популярное'
            handleChange={handleFilterIsPopular}
            selectItem={isTrueFalseList}
          />
        </Box>
      </Box>
      <Button
        sx={{
          display: { xs: 'none', lg: 'inline-flex' },
          opacity: isOpen ? '1' : '0',
          transition: 'all 0.3s ease-in-out',
        }}
        variant='contained'
        onClick={handleClearFilter}
      >
        Очистить
      </Button>
    </Stack>
  )
}

export default Filter
