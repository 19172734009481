import React, { FC } from 'react'
import { Box, TableHead as MUITableHead, TableCell, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { Arrange, HeadCell, RowData } from 'types/Table'

interface TableHeadProps {
  headCells: HeadCell[]
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RowData,
    ordering: string,
  ) => void
  order: Arrange
  orderBy: string
}

const TableHead: FC<TableHeadProps> = ({ headCells, onRequestSort, order, orderBy }) => {
  const createSortHandler =
    (property: keyof RowData, ordering: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property, ordering)
    }

  return (
    <MUITableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              padding: { xs: '8px 0px 8px 20px', sm: '8px 0px 8px 20px' },
              '&:first-of-type': {
                padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
              },
              border: 'none',
              backgroundColor: '#F7F9FC',
            }}
            key={headCell.id}
            align='left'
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <>
                {headCell.label && (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id, headCell.sort)}
                  >
                    {headCell.label}

                    {orderBy === headCell.id ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
              </>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  )
}

export default TableHead
