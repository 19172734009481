import React, { FC } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

const drawerWidth = process.env.REACT_APP_DRAWER_WIDTH

interface DashboardProps {
  isOpen: boolean
}

const Dashboard: FC<DashboardProps> = ({ isOpen }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        marginLeft: { xs: '0px', md: isOpen ? `${drawerWidth}px` : '0px' },
        transition: 'all 0.2s ease-out',
        padding: { xs: '20px 20px', sm: '20px 40px' },
        backgroundColor: '#F3F4F9',
      }}
    >
      <Box
        sx={{
          padding: '20px',
          height: { xs: '153px', sm: '94px' },
          justifyContent: 'flex-end',
          display: 'flex',
          alignItems: 'center',
        }}
      ></Box>
      <Outlet />
    </Box>
  )
}

export default Dashboard
