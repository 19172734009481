import React, { FC, useEffect } from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { useAppDispatch } from 'store/hooks/useAppSelector'
import { FormProvider } from 'react-hook-form'
import { ContainerForm } from 'components/modals/ContainerModal/Content'
import { ContainerBatches } from 'components/terminal'
import { containerTypes, units } from 'constants/SelectFields'
import { useContainerModal } from 'hooks/containers'
import { getContainerBatches } from 'store/reducers/ContainerBatchSlice/ActionCreators'
import { getProductBatches } from 'store/reducers/ProductBatchSlice/ActionCreators'

interface ContentProps {
  onClose: () => void
  modalType: 'change' | 'create'
  productId?: number
  id?: number
  title?: string
}

const Content: FC<ContentProps> = ({ onClose, modalType, productId, id, title }) => {
  const dispatch = useAppDispatch()

  const { methods, onSubmit, productList, need_cleaning } = useContainerModal(
    modalType,
    onClose,
    id,
  )

  useEffect(() => {
    if (productId) {
      dispatch(getProductBatches({ product: productId }))
    }
  }, [productId])

  useEffect(() => {
    if (modalType === 'change') {
      dispatch(getContainerBatches({ container: id }))
    }
  }, [productId, id, modalType])

  return (
    <Stack sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }} direction='column' spacing={3}>
      {modalType === 'change' ? (
        <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>{title}</Typography>
      ) : (
        <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Создать контейнер</Typography>
      )}

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, sm: 4 }}>
        <FormProvider {...methods}>
          <Box sx={{ width: '400px' }} component='form'>
            <ContainerForm
              productList={productList}
              containerTypes={containerTypes}
              unitList={units}
              needCleaning={need_cleaning}
            />
          </Box>
        </FormProvider>

        {modalType === 'change' && (
          <Box sx={{ width: '400px' }}>
            <ContainerBatches containerId={id} productId={productId} />
          </Box>
        )}
      </Stack>
      <Stack sx={{ justifyContent: 'flex-end' }} direction='row' spacing={2}>
        <Button variant='contained' onClick={methods.handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </Stack>
    </Stack>
  )
}

export default Content
