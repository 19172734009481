import React, { FC, memo } from 'react'
import { Stack } from '@mui/material'
import { TextField, DatePicker } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectCurrencySymbol } from 'store/selectors/companySelector'

interface ProductBatchFormProps {
  code?: string
  buyingPrice?: number
  expireDate?: Date | string
}

const ProductBatchForm: FC<ProductBatchFormProps> = ({ code, buyingPrice, expireDate }) => {
  const { control } = useFormContext()
  const currency_symbol = useAppSelector(selectCurrencySymbol)

  return (
    <Stack sx={{ marginBottom: '16px' }} direction='column' spacing={2}>
      <TextField
        control={control}
        name='code'
        label='Партия'
        size='small'
        defaultValue={code || ''}
        required
      />
      <DatePicker
        control={control}
        name='expire_date'
        label='Годен до'
        size='small'
        defaultValue={expireDate || ''}
        required
      />
      <TextField
        control={control}
        name='buying_price'
        label={`Себестоимость за ед. ${currency_symbol}`}
        size='small'
        defaultValue={buyingPrice || ''}
        type='number'
        required
      />
    </Stack>
  )
}

export default memo(ProductBatchForm)
