import React, { FC } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { RecoveryPasswordForm } from 'components/recoveryPassword'
import { NavLink } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks/useAppSelector'
import { recoveryPassword } from 'store/reducers/UsersSlice/ActionCreators'
import { Logo } from 'components/icons'

const PasswordRecovert: FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<{ email: string }>()

  const onSubmit = (formData: { email: string }) => {
    dispatch(recoveryPassword({ formData }))
  }

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          width: '420px',
          padding: '40px 20px 20px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
          zIndex: '10',
          margin: '25px',
        }}
        component='form'
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Logo sx={{ width: '166px', height: '43px', marginBottom: '50px' }} />
        <Typography
          sx={{ fontSize: '26px', lineHeight: '26px', fontWeight: '700', marginBottom: '20px' }}
        >
          Восстановление пароля
        </Typography>
        <Box sx={{ width: '100%', marginBottom: '50px' }}>
          <RecoveryPasswordForm />
        </Box>

        <Button type='submit' sx={{ marginBottom: '44px' }} variant='contained'>
          Отправить
        </Button>
        <Stack direction='row' spacing={3}>
          <Typography
            sx={{
              alignSelf: 'flex-end',
              color: 'text.primary',
              textDecoration: 'none',
            }}
            variant='body1'
            component={NavLink}
            to='/'
          >
            Войти
          </Typography>
          <Typography
            sx={{ color: 'text.primary', textDecoration: 'none' }}
            variant='body1'
            component={NavLink}
            to='/registration'
          >
            Отправить заявку на регистрацию
          </Typography>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default PasswordRecovert
