import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { selectCells, selectCellsCount, selectCellsIsLoading } from 'store/selectors/cellsSelectors'
import { getProducts } from 'store/reducers/ProductsSlice/ActionCreators'
import { getCells } from 'store/reducers/CellSlice/ActionCreators'
import { Cell } from 'types/Cells'
import { selectProductList } from 'store/selectors/productsSelector'
import { useMediaQuery } from 'react-responsive'
import { getCategories } from 'store/reducers/CategoriesSlice/ActionCreators'
import { useParams } from 'react-router-dom'

const useCells = () => {
  const dispatch = useAppDispatch()
  const { terminalId } = useParams()
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const [rows, setRows] = useState<RowData[] | any>([])
  const productList = useAppSelector(selectProductList)
  const cells = useAppSelector<Cell[]>(selectCells)
  const count = useAppSelector<number>(selectCellsCount)
  const [cellModal, setCellModal] = useState<boolean>(false)
  const cellsIsLoading = useAppSelector<boolean>(selectCellsIsLoading)
  const isHidden900 = useMediaQuery({ maxWidth: 900 })
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden900)

  const filteredAddedProductList = useMemo(() => {
    return productList.filter((product) => cells.every((cell) => cell.product !== product.value))
  }, [productList, cells])

  const headCells: HeadCell[] = [
    {
      id: 'code',
      label: 'Код',
      sort: 'code',
    },
    {
      id: 'product',
      label: 'Товар',
      sort: 'product',
    },
    {
      id: 'price',
      label: 'Отпускная цена',
      sort: 'price',
    },
    {
      id: 'is_composite',
      label: 'Составной',
      sort: '',
    },
    {
      id: 'placement',
      label: 'Размещение',
      sort: '',
    },
    {
      id: 'quantity',
      label: 'Наличие',
      sort: 'quantity',
    },
    {
      id: 'quantity_notification',
      label: 'Мин. остаток',
      sort: 'quantity_notification',
    },
  ].filter((item) => {
    if (isHidden900) {
      return (
        item.id !== 'code' &&
        item.id !== 'is_composite' &&
        item.id !== 'placement' &&
        item.id !== 'quantity' &&
        item.id !== 'quantity_notification'
      )
    } else {
      return item
    }
  }) as HeadCell[]

  useEffect(() => {
    setRows(
      cells.map((item) => {
        const is_composite = item.containers.length > 1
        const placement = item.containers.map((item) => item.containers).join(',')

        return {
          ...item,
          is_composite,
          placement,
        }
      }),
    )
  }, [cells, rowsPerPage, page])

  useEffect(() => {
    if (terminalId) {
      dispatch(getCells({ page: pageQuery, page_size: rowsPerPage, terminal: terminalId, ordering }))
    }
  }, [page, rowsPerPage, pageQuery, ordering, terminalId])

  useEffect(() => {
    dispatch(getProducts({}))
    dispatch(getCategories({}))
  }, [])

  const handleCellModalOpen = useCallback(() => {
    setCellModal(true)
  }, [cellModal])

  const handleCellModalClose = useCallback(() => {
    setCellModal(false)
  }, [cellModal])

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    cellsIsLoading,
    filteredAddedProductList,
    headCells,
    cellModal,
    handleCellModalOpen,
    handleCellModalClose,
    productList,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useCells
