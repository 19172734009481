import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  Terminal,
  TerminalModel,
  TerminalModelsData,
  TerminalsData,
  TerminalType,
  TerminalTypesData,
} from 'types/Terminals'
import { TerminalFormTypes } from 'types/FormTypes'
import { http } from 'utils/httpConfig'
import axios from 'axios'

export const getTerminals = createAsyncThunk(
  'terminals/get_terminals',
  async (
    params: {
      page?: number
      page_size?: number
      ordering?: string
      marketplace?: string
      containers_need_revision?: string
      containers_need_revision__gt?: string
      group?: string
      model__type?: string
    },
    thunkAPI,
  ) => {
    const {
      page,
      page_size,
      ordering,
      marketplace,
      containers_need_revision,
      containers_need_revision__gt,
      group,
      model__type,
    } = params
    try {
      const response = await http.get<TerminalsData>('/api/admin/terminal/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          marketplace,
          containers_need_revision,
          containers_need_revision__gt,
          group,
          model__type,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get terminals data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get terminals data, ${error}`])
      }
    }
  },
)

export const getTerminal = createAsyncThunk(
  'terminals/get_terminal',
  async (params: { id?: string }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<Terminal>(
        `${process.env.REACT_APP_BASE_URL}/api/admin/terminal/${id}/`,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get terminal data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get terminal data, ${error}`])
      }
    }
  },
)

export const updateTerminal = createAsyncThunk(
  'terminals/update_terminal',
  async (
    params: {
      id?: number
      formValues: TerminalFormTypes
    },
    thunkAPI,
  ) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Terminal>(`/api/admin/terminal/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update terminal, ${error}`])
      }
    }
  },
)

export const deleteTerminal = createAsyncThunk(
  'terminals/delete_terminal',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/terminal/${id}/`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue([`Failed to delete terminal, ${error}`])
    }
  },
)

export const partialUpdateTerminal = createAsyncThunk(
  'terminals/partial_update_terminal',
  async (params: { id?: number; formValues: any }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<Terminal>(`/api/admin/terminal/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update terminal, ${error}`])
      }
    }
  },
)

export const createTerminal = createAsyncThunk(
  'terminals/create_terminal',
  async (params: { formValues: any }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<Terminal>('/api/admin/terminal/', formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update terminal, ${error}`])
      }
    }
  },
)

export const getTerminalModels = createAsyncThunk(
  'terminals/get_terminal_models',
  async (_, thunkAPI) => {
    try {
      const response = await http.get<TerminalModelsData>('/api/admin/terminal-model/')
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get terminal models data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get terminal models data, ${error}`])
      }
    }
  },
)

export const getTerminalModel = createAsyncThunk(
  'terminals/get_terminal_model',
  async (params: { id?: string }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<TerminalModel>(`/api/admin/terminal-model/${id}/`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get terminal model data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get terminal model data, ${error}`])
      }
    }
  },
)

export const getTerminalTypes = createAsyncThunk(
  'terminals/get_terminal_types',
  async (_, thunkAPI) => {
    try {
      const response = await http.get<TerminalTypesData>('/api/admin/terminal-type/')
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get terminal types data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get terminal types data, ${error}`])
      }
    }
  },
)

export const getTerminalType = createAsyncThunk(
  'terminals/get_terminal_type',
  async (params: { id?: string }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.post<TerminalType>(`/api/admin/terminal-typel/${id}/`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get terminal type data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get terminal type data, ${error}`])
      }
    }
  },
)

export const copyTerminal = createAsyncThunk(
  'terminals/copy_terminal',
  async (body: { name: string; terminal_id: number }, thunkAPI) => {
    try {
      const response = await http.post<Terminal>('/api/admin/terminal/copy/', body)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to copy terminal, ${error}`])
      }
    }
  },
)
