import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Terminal,
  TerminalModel,
  TerminalModelsData,
  TerminalsData,
  TerminalType,
  TerminalTypesData,
} from 'types/Terminals'
import {
  copyTerminal,
  createTerminal,
  deleteTerminal,
  getTerminal,
  getTerminalModel,
  getTerminalModels,
  getTerminals,
  getTerminalTypes,
  partialUpdateTerminal,
  updateTerminal,
} from './ActionCreators'

interface TerminalsState {
  terminalsData: {
    count: number
    results: Terminal[]
  }
  terminal: Terminal
  terminalModels: TerminalModel[]
  terminalModel: TerminalModel
  terminalTypes: TerminalType[]
  terminalType: TerminalType
  terminalsDataIsLoading: boolean
  terminalIsLoading: boolean
  terminalModelsIsLoading: boolean
  terminalModelIsLoading: boolean
  terminalTypesIsLoading: boolean
  terminalTypeIsLoading: boolean
  error: string[]
}

const initialState: TerminalsState = {
  terminalsData: {
    count: 0,
    results: [],
  },
  terminal: {
    id: 0,
    address: '',
    position: {
      type: '',
      coordinates: [],
    },
    secret_key: '',
    description: '',
    external_id: 0,
    marketplace: 0,
    group: 0,
    type: {
      id: 0,
      name: '',
      alias: '',
    },
    model: {
      id: 0,
      name: '',
      type: '',
      container_num: 0,
      container_size: 0,
    },
    code: 0,
    containers_need_revision: 0,
    franke_counters: 0,
    franke_properties: {
      id: 0,
      company_code: '',
      department_code: '',
      secret_key: '',
      terminal: 0,
    },
    tcn_properties: {
      container_num: null,
      container_size: '',
      container_code_rule: 1,
    },
  },
  terminalModels: [],
  terminalModel: {
    id: 0,
    name: '',
    type: '',
    container_num: 0,
    container_size: 0,
  },
  terminalTypes: [],
  terminalType: {
    id: 0,
    name: '',
    alias: '',
  },
  terminalsDataIsLoading: false,
  terminalIsLoading: false,
  terminalModelsIsLoading: false,
  terminalModelIsLoading: false,
  terminalTypesIsLoading: false,
  terminalTypeIsLoading: false,
  error: [],
}

export const terminalsSlice = createSlice({
  name: 'terminals',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = []
    },
  },
  extraReducers: {
    [getTerminals.fulfilled.type]: (state, action: PayloadAction<TerminalsData>) => {
      state.terminalsDataIsLoading = false
      state.error = []
      state.terminalsData.count = action.payload.count
      state.terminalsData.results = action.payload.results
    },
    [getTerminals.pending.type]: (state) => {
      state.terminalsDataIsLoading = true
    },
    [getTerminals.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalsDataIsLoading = false
      state.error = action.payload
    },
    [getTerminal.fulfilled.type]: (state, action: PayloadAction<Terminal>) => {
      state.terminalIsLoading = false
      state.error = []
      state.terminal = action.payload
    },
    [getTerminal.pending.type]: (state) => {
      state.terminalIsLoading = true
    },
    [getTerminal.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalIsLoading = false
      state.error = action.payload
    },
    [updateTerminal.fulfilled.type]: (state, action: PayloadAction<Terminal>) => {
      state.terminalIsLoading = false
      state.error = []
      state.terminal = action.payload
    },
    [updateTerminal.pending.type]: (state) => {
      state.terminalIsLoading = true
    },
    [updateTerminal.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalIsLoading = false
      state.error = action.payload
    },
    [deleteTerminal.fulfilled.type]: (state, action: AnyAction) => {
      state.terminalIsLoading = false
      state.error = []
      state.terminalsData.results = state.terminalsData.results.filter(
        (item) => item.id !== action.meta.arg.id,
      )
    },
    [deleteTerminal.pending.type]: (state) => {
      state.terminalIsLoading = true
    },
    [deleteTerminal.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalIsLoading = false
      state.error = action.payload
    },
    [partialUpdateTerminal.fulfilled.type]: (state, action: PayloadAction<Terminal>) => {
      state.terminalIsLoading = false
      state.error = []
      state.terminal = action.payload
    },
    [partialUpdateTerminal.pending.type]: (state) => {
      state.terminalIsLoading = true
    },
    [partialUpdateTerminal.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalIsLoading = false
      state.error = action.payload
    },
    [createTerminal.fulfilled.type]: (state, action: PayloadAction<Terminal>) => {
      state.terminalIsLoading = false
      state.error = []
      state.terminalsData.results.push(action.payload)
    },
    [createTerminal.pending.type]: (state) => {
      state.terminalIsLoading = true
    },
    [createTerminal.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalIsLoading = false
      state.error = action.payload
    },
    [getTerminalModels.fulfilled.type]: (state, action: PayloadAction<TerminalModelsData>) => {
      state.terminalModelsIsLoading = false
      state.error = []
      state.terminalModels = action.payload.results
    },
    [getTerminalModels.pending.type]: (state) => {
      state.terminalModelsIsLoading = true
    },
    [getTerminalModels.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalModelsIsLoading = false
      state.error = action.payload
    },
    [getTerminalModel.fulfilled.type]: (state, action: PayloadAction<TerminalModel>) => {
      state.terminalModelIsLoading = false
      state.error = []
      state.terminalModel = action.payload
    },
    [getTerminalModel.pending.type]: (state) => {
      state.terminalModelIsLoading = true
    },
    [getTerminalModel.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalModelIsLoading = false
      state.error = action.payload
    },
    [getTerminalTypes.fulfilled.type]: (state, action: PayloadAction<TerminalTypesData>) => {
      state.terminalTypesIsLoading = false
      state.error = []
      state.terminalTypes = action.payload.results
    },
    [getTerminalTypes.pending.type]: (state) => {
      state.terminalTypesIsLoading = true
    },
    [getTerminalTypes.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalTypesIsLoading = false
      state.error = action.payload
    },
    [getTerminalModel.fulfilled.type]: (state, action: PayloadAction<TerminalType>) => {
      state.terminalTypeIsLoading = false
      state.error = []
      state.terminalType = action.payload
    },
    [getTerminalModel.pending.type]: (state) => {
      state.terminalTypeIsLoading = true
    },
    [getTerminalModel.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalTypeIsLoading = false
      state.error = action.payload
    },
    [copyTerminal.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.terminalTypeIsLoading = false
      state.error = []
    },
    [copyTerminal.pending.type]: (state) => {
      state.terminalTypeIsLoading = true
    },
    [copyTerminal.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.terminalTypeIsLoading = false
      state.error = action.payload
    },
  },
})

export default terminalsSlice.reducer
