import React, { FC } from 'react'
import { alpha, Box } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'

interface SearchInputProps {
  value: string
  handleChange: (event: { target: { value: string } }) => void
}

const SearchInput: FC<SearchInputProps> = ({ value, handleChange }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '4px',
        backgroundColor: alpha('#ffffff', 0.15),
        '&:hover': {
          backgroundColor: alpha('#ffffff', 0.25),
        },
        marginLeft: 0,
        width: 'auto',
      }}
    >
      <Box
        sx={{
          padding: '0 16px',
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SearchIcon sx={{ color: 'common.white' }} />
      </Box>
      <InputBase
        sx={{
          color: '#ffffff',
          '& .MuiInputBase-input': {
            padding: '8px 8px 8px 0',
            paddingLeft: 'calc(1em + 32px)',
            width: '100%',
          },
        }}
        placeholder='Поиск…'
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={handleChange}
      />
    </Box>
  )
}

export default SearchInput
