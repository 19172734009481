import React, { FC } from 'react'
import { Toolbar as MUIToolbar, Typography, Button, IconButton } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'

interface ToolbarProps {
  title: string
  buttonLabel: string
  handleModalOpen: () => void
}

const Toolbar: FC<ToolbarProps> = ({ title, buttonLabel, handleModalOpen }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })

  return (
    <>
      <MUIToolbar
        sx={{
          padding: { xs: '0px 10px !important', sm: '0px 20px !important' },
          minHeight: 'auto',
        }}
      >
        <Typography sx={{ flex: '1 1 100%', fontSize: '18px', lineHeight: '21px' }} component='div'>
          {title}
        </Typography>
        {buttonLabel && (
          <>
            {isMobile ? (
              <IconButton color='primary' onClick={handleModalOpen}>
                <AddIcon fontSize='medium' />
              </IconButton>
            ) : (
              <Button
                sx={{ whiteSpace: 'nowrap', fontSize: '18px', lineHeight: '21px' }}
                variant='text'
                onClick={handleModalOpen}
              >
                {buttonLabel}
              </Button>
            )}
          </>
        )}
      </MUIToolbar>
    </>
  )
}

export default Toolbar
