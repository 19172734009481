import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Marketplace, MetricsData, Product, RevenueChartData } from 'types/Stat'
import { getMarketplace, getMetrics, getProduct, getRevenueChart } from './ActionCreators'

interface StatState {
  marketplaceData: Marketplace[]
  metricsData: MetricsData
  productData: Product[]
  revenueChartData: RevenueChartData
  isLoading: boolean
  error: string
}

const initialState: StatState = {
  marketplaceData: [],
  metricsData: {
    revenue: {
      current: 0,
      previous: 0,
    },
    order_count: {
      current: 0,
      previous: 0,
    },
  },
  productData: [],
  revenueChartData: {
    day_chart: {
      labels: [],
      values: [],
    },
    week_chart: {
      labels: [],
      values: [],
    },
    month_chart: {
      labels: [],
      values: [],
    },
    year_chart: {
      labels: [],
      values: [],
    },
  },
  isLoading: false,
  error: '',
}

export const statSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: {
    [getMarketplace.fulfilled.type]: (state, action: PayloadAction<Marketplace[]>) => {
      state.isLoading = false
      state.error = ''
      state.marketplaceData = action.payload
    },
    [getMarketplace.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMarketplace.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getMetrics.fulfilled.type]: (state, action: PayloadAction<MetricsData>) => {
      state.isLoading = false
      state.error = ''
      state.metricsData = action.payload
    },
    [getMetrics.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMetrics.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getProduct.fulfilled.type]: (state, action: PayloadAction<Product[]>) => {
      state.isLoading = false
      state.error = ''
      state.productData = action.payload
    },
    [getProduct.pending.type]: (state) => {
      state.isLoading = true
    },
    [getProduct.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [getRevenueChart.fulfilled.type]: (state, action: PayloadAction<RevenueChartData>) => {
      state.isLoading = false
      state.error = ''
      state.revenueChartData = action.payload
    },
    [getRevenueChart.pending.type]: (state) => {
      state.isLoading = true
    },
    [getRevenueChart.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default statSlice.reducer
