import React, { FC } from 'react'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import icon1 from 'assets/img/menuIcons/icon_1.png'
import icon2 from 'assets/img/menuIcons/icon_2.png'
import icon3 from 'assets/img/menuIcons/icon_3.png'
import icon4 from 'assets/img/menuIcons/icon_4.png'
import icon5 from 'assets/img/menuIcons/icon_5.png'
import icon6 from 'assets/img/menuIcons/icon_6.png'
import icon7 from 'assets/img/menuIcons/icon_7.png'
import icon8 from 'assets/img/menuIcons/icon_8.png'
import { useMediaQuery } from 'react-responsive'

interface NavProps {
  onClose: () => void
}

const Nav: FC<NavProps> = ({ onClose }) => {
  const location = useLocation()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const menu = [
    {
      label: 'Статистика',
      path: '/admin',
      icon: <Box src={icon1} component='img' />,
    },
    {
      label: 'Пользователи',
      path: '/admin/users',
      icon: <Box src={icon2} component='img' />,
    },
    {
      label: 'Точки продаж',
      path: '/admin/marketplace',
      icon: <Box src={icon3} component='img' />,
    },
    {
      label: 'Автоматы',
      path: '/admin/terminals',
      icon: <Box src={icon4} component='img' />,
    },
    {
      label: 'Группы',
      path: '/admin/groups',
      icon: <Box src={icon5} component='img' />,
    },
    {
      label: 'Продажи',
      path: '/admin/orders',
      icon: <Box src={icon6} component='img' />,
    },
    {
      label: 'Категории',
      path: '/admin/categories',
      icon: <Box src={icon7} component='img' />,
    },
    {
      label: 'Товары',
      path: '/admin/products',
      icon: <Box src={icon8} component='img' />,
    },
  ]

  return (
    <List sx={{ padding: '0' }}>
      {menu.map((menuItem) => (
        <ListItem key={menuItem.label} disablePadding onClick={isMobile ? onClose : () => null}>
          <ListItemButton
            sx={{
              backgroundColor: location.pathname === menuItem.path ? '#F3F4F9' : 'transparent',
            }}
            component={NavLink}
            to={menuItem.path}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default Nav
