import React, { FC } from 'react'
import { Toolbar, IconButton, Box, AppBar, Stack, Button, Menu, MenuItem } from '@mui/material'
import { Menu as MenuIcon, PersonOutline as PersonOutlineIcon } from '@mui/icons-material'
import { HeaderContent } from 'components/generic/Header'
import { useAuth } from 'hooks'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectUserName } from 'store/selectors/usersSelectors'
import { useMediaQuery } from 'react-responsive'

interface HeaderProps {
  isOpen: boolean
  onOpen: () => void
}

const Header: FC<HeaderProps> = ({ isOpen, onOpen }) => {
  const drawerWidth = process.env.REACT_APP_DRAWER_WIDTH
  const userName = useAppSelector(selectUserName)
  const { logOut } = useAuth()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    logOut()
    handleClose()
  }

  return (
    <Box
      sx={{
        width: { xs: '100%', md: `calc(100% - ${isOpen ? drawerWidth : 0}px)` },
        marginLeft: { xs: '0px', md: isOpen ? `${drawerWidth}px` : '0px' },
        transition: 'all 0.2s ease-out',
        padding: { xs: '20px 25px !important', sm: '20px 40px !important' },
      }}
      position='fixed'
      component={AppBar}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          padding: '0px !important',
          minHeight: 'auto !important',
        }}
      >
        <Stack
          sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1.3}
        >
          <IconButton
            color='inherit'
            onClick={onOpen}
            edge='start'
            sx={{ display: isOpen ? 'none' : 'flex' }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderContent />
        </Stack>
        {isMobile ? (
          <IconButton onClick={handleClick}>
            <PersonOutlineIcon color='secondary' fontSize='medium' />
          </IconButton>
        ) : (
          <Button
            sx={{ textTransform: 'capitalize' }}
            variant='text'
            color='secondary'
            startIcon={<PersonOutlineIcon color='secondary' fontSize='medium' />}
            onClick={handleClick}
          >
            {userName}
          </Button>
        )}

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} disableScrollLock>
          <MenuItem onClick={handleLogOut}>Выйти</MenuItem>
        </Menu>
      </Toolbar>
    </Box>
  )
}

export default Header
