import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User, UsersData } from 'types/User'
import {
  createUser,
  getUser,
  getUsers,
  partialUpdateUser,
  recoveryPassword,
  updateUser,
} from './ActionCreators'

interface AuthState {
  usersData: {
    count: number
    results: User[]
  }
  user: User
  isLoading: boolean
  errors: string[]
}

const initialState: AuthState = {
  usersData: {
    count: 0,
    results: [],
  },
  user: {
    id: 0,
    username: '',
    phone: '',
    email: '',
    is_active: false,
    is_staff: false,
    first_name: '',
    middle_name: '',
    last_name: '',
    company_admin: false,
    terminals: [],
  },
  isLoading: false,
  errors: [],
}

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [getUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
      state.isLoading = false
      state.errors = []
      state.user = action.payload
    },
    [getUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [getUser.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
    [getUsers.fulfilled.type]: (state, action: PayloadAction<UsersData>) => {
      state.isLoading = false
      state.errors = []
      state.usersData.count = action.payload.count
      state.usersData.results = action.payload.results
    },
    [getUsers.pending.type]: (state) => {
      state.isLoading = true
    },
    [getUsers.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
    [recoveryPassword.fulfilled.type]: (state) => {
      state.isLoading = false
      state.errors = []
    },
    [recoveryPassword.pending.type]: (state) => {
      state.isLoading = true
    },
    [recoveryPassword.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
    [updateUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
      state.isLoading = false
      state.errors = []
      state.usersData.results = state.usersData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [updateUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateUser.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
    [partialUpdateUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
      state.isLoading = false
      state.errors = []
      state.usersData.results = state.usersData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [partialUpdateUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [partialUpdateUser.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
    [createUser.fulfilled.type]: (state, action: PayloadAction<User>) => {
      state.isLoading = false
      state.errors = []
      state.usersData.results.push(action.payload)
    },
    [createUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [createUser.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
  },
})

export default usersSlice.reducer
