import React, { FC } from 'react'
import { Menu as MenuIcon } from '@mui/icons-material'
import { Nav } from 'components/generic'
import { Box, IconButton, Drawer } from '@mui/material'
import { Logo } from 'components/icons'
import { useMediaQuery } from 'react-responsive'

interface SidebarProps {
  isOpen: boolean
  onClose: () => void
}

const Sidebar: FC<SidebarProps> = ({ isOpen, onClose }) => {
  const drawerWidth = process.env.REACT_APP_DRAWER_WIDTH
  const isTemporary = useMediaQuery({ maxWidth: 900 })

  return (
    <Drawer
      sx={{
        width: `${drawerWidth}px`,
        position: 'absolute',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: `${drawerWidth}px`,
          boxSizing: 'border-box',
          border: 'none',
        },
      }}
      variant={isTemporary ? 'temporary' : 'persistent'}
      anchor='left'
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '20px 10px 20px 23px',
          justifyContent: 'space-between',
          height: '94px',
        }}
      >
        <Logo sx={{ width: '166px', height: '43px' }} />
        <IconButton onClick={onClose}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Nav onClose={onClose} />
    </Drawer>
  )
}

export default Sidebar
