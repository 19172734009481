import { AxiosInstance, AxiosRequestConfig } from 'axios'

export const SetupInterceptors = (http: AxiosInstance) => {
  const baseURL = process.env.REACT_APP_BASE_URL

  http.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.headers = config.headers ?? {}
      config.headers.Authorization = `Token ${localStorage.getItem('token')}`

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  http.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      const status = error?.response?.status || 0
      const resBaseURL = error?.response?.config?.baseURL
      if (resBaseURL === baseURL && status === 401) {
        if (localStorage.getItem('token')) {
          localStorage.clear()
          window.location.assign('/')
          return Promise.reject(error)
        } else {
          return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    },
  )
}

export default SetupInterceptors
