import { useCallback } from 'react'
import { LabelDisplayedRowsArgs } from '@mui/material'

const useLabelRows = (isHidden: boolean) => {
  const labelDisplayedRows = useCallback(
    ({ from, to, count }: LabelDisplayedRowsArgs) => {
      return isHidden ? '' : '' + from + '-' + to + ' из ' + count
    },
    [isHidden],
  )

  return labelDisplayedRows
}

export default useLabelRows
