import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { http } from 'utils/httpConfig'

export const createRegisterRequest = createAsyncThunk(
  'register/create_request',
  async (
    params: {
      formData: {
        first_name: string
        last_name: string
        middle_name: string
        phone: string
        company: string
        email: string
      }
    },
    thunkAPI,
  ) => {
    const { formData } = params
    try {
      const response = await http.post<any>('/api/admin/register_request/', formData)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create register request, error${error}`])
      }
    }
  },
)
