import React, { FC, memo } from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { CellForm } from 'components/modals/CellModal/Content'
import { SelectFieldType } from 'types/Other'
import { ConfirmationModal, ProductModal } from 'components/modals'
import { CellContainer } from 'types/Cells'
import { useCellModal } from 'hooks/cells'

interface ContentProps {
  onClose: () => void
  modalType: 'change' | 'create'
  productList: SelectFieldType[]
  cookingTime?: number
  productId?: number
  id?: number
  title?: string
  price?: number
  code?: number
  containers?: CellContainer[]
  quantityNotification?: number
  needQuantityNotification?: boolean
}

const Content: FC<ContentProps> = ({
  id,
  onClose,
  modalType,
  productList,
  cookingTime,
  productId,
  title,
  price,
  code,
  containers,
  quantityNotification,
  needQuantityNotification,
}) => {
  const {
    methods,
    handleSubmit,
    productData,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    handleCreateProductModalOpen,
    handleCreateProductModalClose,
    handleChangeProductModalOpen,
    handleChangeProductModalClose,
    handleDelete,
    onSubmit,
    confirmModal,
    createProductModal,
    changeProductModal,
  } = useCellModal(onClose, modalType, id, productId)

  return (
    <>
      <Stack
        sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }}
        direction='column'
        spacing={{ xs: 2, sm: 3 }}
      >
        {modalType === 'change' ? (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>{title}</Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Создать товар</Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form'>
            <CellForm
              productList={productList}
              price={price}
              code={code}
              productId={productId}
              cookingTime={cookingTime}
              containers={containers}
              modalType={modalType}
              handleCreateProductModalOpen={handleCreateProductModalOpen}
              handleChangeProductModalOpen={handleChangeProductModalOpen}
              quantityNotification={quantityNotification}
              needQuantityNotification={needQuantityNotification}
            />
          </Box>
        </FormProvider>
        <Stack sx={{ justifyContent: 'space-between' }} direction='row' spacing={2}>
          {modalType === 'change' && (
            <Button
              color='error'
              sx={{ flexGrow: '1' }}
              variant='outlined'
              onClick={handleConfirmModalOpen}
            >
              Удалить
            </Button>
          )}

          <Button sx={{ flexGrow: '1' }} variant='contained' onClick={handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </Stack>
      </Stack>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />

      <ProductModal
        isOpen={createProductModal}
        onClose={handleCreateProductModalClose}
        modalType='create'
      />

      <ProductModal
        isOpen={changeProductModal}
        onClose={handleChangeProductModalClose}
        modalType='change'
        id={productData.id}
        name={productData.name}
        nameEn={productData.name_en}
        description={productData.description}
        descriptionEn={productData.description_en}
        category={productData.category}
        shortCode={productData.short_code}
        code={productData.code}
        unit={productData.unit}
        weight={productData.weight}
        cookingTime={productData.cooking_time}
        energy={productData.energy}
        proteins={productData.proteins}
        fats={productData.fats}
        carbs={productData.carbs}
        icon={productData.icon}
        isIngredient={productData.is_ingredient}
        isWarmUp={productData.is_warm_up}
        isPopular={productData.is_popular}
        nonFood={productData.non_food}
      />
    </>
  )
}

export default memo(Content)
