import { SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Arrange, RowData } from 'types/Table'

const useTableSort = () => {
  const [defaultPageSize] = useState<number>(25)
  const { pathname } = useLocation()
  const rowSessionState = parseInt(
    sessionStorage.getItem(`${pathname}/PageSize`) || `${defaultPageSize}`,
  )
  const [rowsPerPage, setRowsPerPage] = useState(rowSessionState)
  const pageSessionState = parseInt(sessionStorage.getItem(`${pathname}/Page`) || '0')
  const pageQuerySessionState = parseInt(sessionStorage.getItem(`${pathname}/PageQuery`) || '1')
  const [ordering, setOrdering] = useState<string | undefined>()
  const [order, setOrder] = useState<Arrange>('asc')
  const [orderBy, setOrderBy] = useState<keyof RowData>('name')
  const [page, setPage] = useState<number>(pageSessionState)
  const [pageQuery, setPageQuery] = useState<number>(pageQuerySessionState)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowData,
    ordering?: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrdering(ordering && isAsc ? `-${ordering}` : ordering || undefined)
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (
    event: { target: { value: SetStateAction<string> } },
    newPage: number,
  ) => {
    setPageQuery(newPage + 1)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setPageQuery(1)
    setRowsPerPage(parseInt(event.target.value))
  }

  const clearPageState = () => {
    setPage(0)
    setPageQuery(1)
    setRowsPerPage(defaultPageSize)
    sessionStorage.removeItem(`${pathname}/Page`)
    sessionStorage.removeItem(`${pathname}/PageQuery`)
    sessionStorage.removeItem(`${pathname}/PageSize`)
  }

  useEffect(() => {
    setPage(pageSessionState)
    setPageQuery(pageQuerySessionState)
  }, [])

  useEffect(() => {
    sessionStorage.setItem(`${pathname}/Page`, page.toString())
    sessionStorage.setItem(`${pathname}/PageQuery`, (page + 1).toString())
    sessionStorage.setItem(`${pathname}/PageSize`, rowsPerPage.toString())
  }, [page, rowsPerPage])

  return {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    clearPageState,
  }
}

export default useTableSort
