import { createAsyncThunk } from '@reduxjs/toolkit'
import { Ingredient, IngredientsData } from 'types/Ingredients'
import { IngredientFormTypes } from 'types/FormTypes'
import { http } from 'utils/httpConfig'

export const getIngredients = createAsyncThunk(
  'ingredients/get_ingredients',
  async (
    params: { page?: number; page_size?: number; ordering?: string; product?: number | string },
    thunkAPI,
  ) => {
    const { page, page_size, ordering, product } = params
    try {
      const response = await http.get<IngredientsData>('/api/admin/ingredient/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          product,
        },
      })
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get ingredients, error${e}`)
    }
  },
)

export const updateIngredient = createAsyncThunk(
  'ingredients/update_ingredient',
  async (params: { id?: number; formValues: IngredientFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Ingredient>(`/api/admin/ingredient/${id}/`, formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update ingredient, ${e}`)
    }
  },
)

export const deleteIngredient = createAsyncThunk(
  'ingredients/delete_ingredient',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<any>(`/api/admin/ingredient/${id}/`)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to delete ingredient, ${e}`)
    }
  },
)

export const partialUpdateIngredient = createAsyncThunk(
  'ingredients/partial_update_ingredient',
  async (params: { id: number; formValues: IngredientFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<any>(`/api/admin/ingredient/${id}/`, formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to update ingredient, ${e}`)
    }
  },
)

export const createIngredient = createAsyncThunk(
  'ingredients/create_ingredient',
  async (params: { formValues: IngredientFormTypes }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<Ingredient>('/api/admin/ingredient/', formValues)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to create ingredient, ${e}`)
    }
  },
)
