import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createRegisterRequest } from './ActionCreators'

interface RegisterState {
  request: boolean
  isLoading: boolean
  errors: string[]
}

const initialState: RegisterState = {
  request: false,
  isLoading: false,
  errors: [],
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = []
    },
    clearRequest: (state) => {
      state.request = false
    },
  },
  extraReducers: {
    [createRegisterRequest.fulfilled.type]: (state) => {
      state.request = true
      state.isLoading = false
      state.errors = []
    },
    [createRegisterRequest.pending.type]: (state) => {
      state.request = false
      state.isLoading = true
    },
    [createRegisterRequest.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.request = false
      state.isLoading = false
      state.errors = action.payload
    },
  },
})

export default registerSlice.reducer
