import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectGroupsData = (state: RootState) => state.groups
/**
 * Returns groups list
 */
export const selectGroups = createSelector(
  [selectGroupsData],
  (groups) => groups.groupsData.results,
)
/**
 * Returns group
 */
export const selectGroup = createSelector([selectGroupsData], (groups) => groups.group)
/**
 * Returns groups count
 */
export const selectGroupsCount = createSelector(
  [selectGroupsData],
  (groups) => groups.groupsData.count || 0,
)
/**
 * Returns response errors
 */
export const selectGroupErrors = createSelector([selectGroupsData], (groups) => groups.errors)
/**
 * Returns groups list for the select field
 */
export const selectGroupsList = createSelector([selectGroupsData], (groups) =>
  groups.groupsData.results.map((item) => {
    return {
      label: item.name,
      value: item.id,
    }
  }),
)
/**
 * Returns groups isLoading
 */
export const selectGroupsIsLoading = createSelector(
  [selectGroupsData],
  (groups) => groups.groupsIsLoading,
)
