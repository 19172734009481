export const CONTAINER_TYPE = {
  TYPE_PIECE: 'Штучный',
  TYPE_BULK: 'Сыпучий',
  TYPE_LIQUID: 'Жидкий',
  TYPE_WASTE: 'Отходы',
}

export const UNIT = {
  UNIT_GRAM: 'гр',
  UNIT_MILLILITRE: 'мл',
  UNIT_PIECE: 'шт',
}

export const FRANKE_TYPE = {
  TYPE_COFFEE_HOPPER_1: 'Бункер для зерна №1',
  TYPE_COFFEE_HOPPER_2: 'Бункер для зерна №2',
  TYPE_POWDER_HOPPER_1: 'Бункер для порошка №1',
  TYPE_POWDER_HOPPER_2: 'Бункер для порошка №2',
  TYPE_WATER_TANK: 'Емкость для воды',
  TYPE_DRIP_TRAY: 'Сборный поддон',
  TYPE_WASTE_BIN: 'Контейнер с отходами',
  TYPE_MILK_TANK: 'Емкость для молока',
  TYPE_FLAVOR_1: 'Сироп №1',
  TYPE_FLAVOR_2: 'Сироп №2',
  TYPE_FLAVOR_3: 'Сироп №3',
  TYPE_FLAVOR_4: 'Сироп №4',
  TYPE_FLAVOR_5: 'Сироп №5',
  TYPE_FLAVOR_6: 'Сироп №6',
}
