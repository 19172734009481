import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectCategoriesData = (state: RootState) => state.categories
const selectCategoryData = (state: RootState) => state.categories.category
/**
 * Returns categories
 */
export const selectCategories = createSelector(
  [selectCategoriesData],
  (categories) => categories.categoriesData.results,
)
/**
 * Returns categories count
 */
export const selectCategoriesCount = createSelector(
  [selectCategoriesData],
  (categories) => categories.categoriesData.count || 0,
)
/**
 * Returns category name
 */
export const selectCategoryName = (categoryId: number) =>
  createSelector(
    [selectCategoriesData],
    (categories) => categories.categoriesData.results.find((item) => item.id === categoryId)?.name,
  )
/**
 * Returns category list for select field
 */
export const selectCategiesSelectList = createSelector([selectCategoriesData], (categories) =>
  categories.categoriesData.results.map((category) => {
    return {
      label: category.name,
      value: category.id,
    }
  }),
)
/**
 * Returns category
 */
export const selectCategory = createSelector([selectCategoryData], (category) => {
  return {
    categoryId: category.id,
    categoryName: category.name,
    categoryIcon: category.icon,
    categoryIsVisible: category.is_visible,
  }
})
/**
 * Returns isLoading categories
 */
export const selectCategoriesIsLoading = createSelector(
  [selectCategoriesData],
  (categories) => categories.categoriesIsLoading,
)
/**
 * Returns category errors
 */
export const selectCategoriesErrors = createSelector(
  [selectCategoriesData],
  (categories) => categories.errors,
)
