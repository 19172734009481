import { useAppDispatch } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import { createUser, getUsers, partialUpdateUser } from 'store/reducers/UsersSlice/ActionCreators'
import { UserFormTypes } from 'types/FormTypes'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'

const useUserModal = (modalType: 'change' | 'create', onClose: () => void, id?: number) => {
  const dispatch = useAppDispatch()
  const methods = useForm<UserFormTypes>()
  const { reset } = methods
  const { enqueueSnackbar } = useSnackbar()

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/users/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/users/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const onSubmit = (formValues: UserFormTypes) => {
    if (modalType === 'change') {
      dispatch(partialUpdateUser({ id, formValues }))
        .unwrap()
        .then((res) => {
          if (res.username) {
            dispatch(getUsers({ page: page, page_size: pageSize }))
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      dispatch(createUser({ formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getUsers({ page: page, page_size: pageSize }))
            enqueueSnackbar('Пользователь успешно создан', { variant: 'success' })
            onClose()
            reset()
          }
        })
    }
  }

  return { methods, onSubmit }
}

export default useUserModal
