import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectUser = (state: RootState) => state.users
const selectUsersData = (state: RootState) => state.users
/**
 * Returns user first name
 */
export const selectUserName = createSelector([selectUser], (user) => user.user.username)
/**
 * Returns users list
 */
export const selectUsersList = createSelector([selectUsersData], (users) => users.usersData.results)
/**
 * Returns users count
 */
export const selectUsersCount = createSelector(
  [selectUsersData],
  (users) => users.usersData.count || 0,
)
/**
 * Returns user data from marketplace table
 */
export const selectUserData = (id: number) =>
  createSelector([selectUsersList], (users) => users.find((user) => user.id === id))
/**
 * Returns available users to add to administrators
 */
export const selectAvailableUsers = (administrators: number[]) =>
  createSelector([selectUsersList], (users) =>
    users.filter((user) => !administrators.includes(user.id)),
  )
/**
 * Returns users isLoading
 */
export const selectUsersIsLoading = createSelector([selectUsersData], (users) => users.isLoading)
