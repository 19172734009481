import React, { FC, memo } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { UserForm } from 'components/modals/UserModal'
import { useUserModal } from 'hooks/users'

interface UserModalProps {
  isOpen: boolean
  modalType: 'change' | 'create'
  onClose: () => void
  id?: number
  username?: string
  phone?: string
  email?: string
  firstName?: string
  lastName?: string
}

const UserModal: FC<UserModalProps> = ({
  isOpen,
  modalType,
  onClose,
  id,
  username,
  phone,
  email,
  firstName,
  lastName,
}) => {
  const { methods, onSubmit } = useUserModal(modalType, onClose, id)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '400px',
          width: '100%',
          overflowY: 'inherit',
        },
      }}
    >
      <Stack sx={{ padding: { xs: '20px 25px', sm: '20px 35px' } }} direction='column' spacing={3}>
        {modalType === 'change' ? (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>{username}</Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>
            Создать пользователя
          </Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: { xs: '20px', sm: '30px' } }}>
              <UserForm
                username={username}
                phone={phone}
                email={email}
                firstName={firstName}
                lastName={lastName}
              />
            </Box>

            <Stack sx={{ justifyContent: 'space-between' }} direction='row' spacing={2}>
              <Button sx={{ flexGrow: '1' }} variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
    </Dialog>
  )
}

export default memo(UserModal)
