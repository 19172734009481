import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoriesData, Category } from 'types/Categories'
import {
  createCategory,
  deleteCategory,
  getCategories,
  getCategory,
  partialUpdateCategory,
  updateCategory,
} from './ActionCreators'

interface CategoriesState {
  categoriesData: {
    count: number
    results: Category[]
  }
  category: Category
  categoriesIsLoading: boolean
  categoryIsLoading: boolean
  errors: string[]
}

const initialState: CategoriesState = {
  categoriesData: {
    count: 0,
    results: [],
  },
  category: {
    id: 0,
    name: '',
    icon: '',
    is_visible: false,
  },
  categoriesIsLoading: false,
  categoryIsLoading: false,
  errors: [],
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = []
    },
  },
  extraReducers: {
    [getCategories.fulfilled.type]: (state, action: PayloadAction<CategoriesData>) => {
      state.categoriesIsLoading = false
      state.errors = []
      state.categoriesData.count = action.payload.count
      state.categoriesData.results = action.payload.results
    },
    [getCategories.pending.type]: (state) => {
      state.categoriesIsLoading = true
    },
    [getCategories.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.categoriesIsLoading = false
      state.errors = action.payload
    },
    [getCategory.fulfilled.type]: (state, action: PayloadAction<Category>) => {
      state.categoryIsLoading = false
      state.errors = []
      state.category = action.payload
    },
    [getCategory.pending.type]: (state) => {
      state.categoryIsLoading = true
    },
    [getCategory.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.categoryIsLoading = false
      state.errors = action.payload
    },
    [updateCategory.fulfilled.type]: (state, action: PayloadAction<Category>) => {
      state.categoryIsLoading = false
      state.errors = []
      state.category = action.payload
    },
    [updateCategory.pending.type]: (state) => {
      state.categoryIsLoading = true
    },
    [updateCategory.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.categoryIsLoading = false
      state.errors = action.payload
    },
    [deleteCategory.fulfilled.type]: (state) => {
      state.categoryIsLoading = false
      state.errors = []
    },
    [deleteCategory.pending.type]: (state) => {
      state.categoryIsLoading = true
    },
    [deleteCategory.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.categoryIsLoading = false
      state.errors = action.payload
    },
    [partialUpdateCategory.fulfilled.type]: (state) => {
      state.categoryIsLoading = false
      state.errors = []
    },
    [partialUpdateCategory.pending.type]: (state) => {
      state.categoryIsLoading = true
    },
    [partialUpdateCategory.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.categoryIsLoading = false
      state.errors = action.payload
    },
    [createCategory.fulfilled.type]: (state) => {
      state.categoryIsLoading = false
      state.errors = []
    },
    [createCategory.pending.type]: (state) => {
      state.categoryIsLoading = true
    },
    [createCategory.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.categoryIsLoading = false
      state.errors = action.payload
    },
  },
})

export default categoriesSlice.reducer
