import React, { FC, memo, useEffect, useMemo } from 'react'
import { Box, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { AutocompleteField, Switch, TextField } from 'components/generic'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { SelectFieldType } from 'types/Other'
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { CellContainer } from 'types/Cells'
import { getIngredients } from 'store/reducers/IngredientsSlice/ActionCreators'
import { selectIngredients } from 'store/selectors/ingredientsSelectors'
import { useMediaQuery } from 'react-responsive'
import { getContainers } from 'store/reducers/ContainersSlice/ActionCreators'
import { selectContainers, selectContainersDataIsLoading } from 'store/selectors/containerSelectors'
import { containersSlice } from 'store/reducers/ContainersSlice/ContrainersSlice'
import { useParams } from 'react-router-dom'

interface CellFormProps {
  productList: SelectFieldType[]
  modalType: 'change' | 'create'
  code?: number
  price?: number
  productId?: number
  cookingTime?: number
  containers?: CellContainer[]
  quantityNotification?: number
  needQuantityNotification?: boolean
  handleCreateProductModalOpen: () => void
  handleChangeProductModalOpen: () => void
}

const CellForm: FC<CellFormProps> = ({
  productList,
  modalType,
  price,
  code,
  productId,
  cookingTime,
  containers,
  quantityNotification,
  needQuantityNotification,
  handleCreateProductModalOpen,
  handleChangeProductModalOpen,
}) => {
  const dispatch = useAppDispatch()
  const { terminalId } = useParams()
  const { control, watch, setValue } = useFormContext()
  const productValue = watch('product')
  const needQuantityNotificationValue = watch('need_quantity_notification')
  const quantityNotificationValue = watch('quantity_notification')
  const ingredients = useAppSelector(selectIngredients)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'containers',
  })
  const isHidden = useMediaQuery({ maxWidth: 600 })
  const containersIsLoading = useAppSelector(selectContainersDataIsLoading)
  const selectedContainers = useAppSelector(selectContainers)
  const { clearContainers } = containersSlice.actions

  const isNeedNotification = useMemo(() => {
    return needQuantityNotificationValue ? true : false
  }, [needQuantityNotificationValue, needQuantityNotification])

  useEffect(() => {
    dispatch(clearContainers())
    if (productValue) {
      dispatch(getIngredients({ product: modalType === 'create' ? productValue : productId }))
        .unwrap()
        .then((res) => {
          if (res) {
            if (productValue && res.results.length > 0) {
              dispatch(
                getContainers({
                  terminal: terminalId,
                  product__in: res.results.map((item) => item.ingredient).join(','),
                }),
              )
            }
            if (productValue && res.results.length <= 0) {
              dispatch(
                getContainers({
                  terminal: terminalId,
                  product__in: productValue,
                }),
              )
            }
          }
        })
    }
  }, [productValue])

  useEffect(() => {
    if (modalType === 'create' && productValue && selectedContainers && !containersIsLoading) {
      remove()
      if (ingredients) {
        ingredients.forEach((item) => {
          const containersValue = selectedContainers
            .filter((container) => container.product === item.ingredient)
            .map((container) => container.code)
            .join(',')
          append({ product: item.ingredient, containers: containersValue }, { shouldFocus: false })
        })
      }
      if (ingredients.length <= 0) {
        const containersValue = selectedContainers.map((container) => container.code).join(',')
        append(
          {
            product: productValue,
            containers: containersValue,
          },
          { shouldFocus: false },
        )
      }
    }
  }, [productValue, ingredients, selectedContainers, containersIsLoading])

  useEffect(() => {
    if (modalType === 'change' && containers) {
      remove()
      containers.forEach((container) =>
        append(
          { product: container.product, containers: container.containers },
          { shouldFocus: false },
        ),
      )
    }
  }, [modalType, productValue])

  const inputLabel = (id: number) => {
    const productId = watch(`containers[${id}].product`)

    const label = productList.find((product) => product.value === productId)?.label
    return `${label} контейнер`
  }

  useEffect(() => {
    if (isNeedNotification && quantityNotificationValue) {
      setValue('quantity_notification', quantityNotificationValue)
    } else {
      setValue('quantity_notification', '')
    }
    if (!isNeedNotification) {
      setValue('quantity_notification', 0)
    }
  }, [quantityNotificationValue, isNeedNotification])

  useEffect(() => {
    setValue('quantity_notification', quantityNotification)
  }, [])

  return (
    <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={{ xs: 0, sm: 1 }}>
        <Box sx={{ flexGrow: '1' }}>
          <AutocompleteField
            control={control}
            name='product'
            label='Товар *'
            size='small'
            selectItem={productList}
            defaultValue={productId || ''}
            required
            disabled={modalType === 'change'}
          />
        </Box>
        {!isHidden && (
          <>
            {modalType === 'create' && (
              <Tooltip title='Добавить товар' placement='top-start'>
                <IconButton color='primary' onClick={handleCreateProductModalOpen}>
                  <AddIcon color='primary' />
                </IconButton>
              </Tooltip>
            )}
            {productValue && (
              <Tooltip title='Редактировать товар' placement='top-start'>
                <IconButton color='primary' onClick={handleChangeProductModalOpen}>
                  <EditIcon color='primary' />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Stack>
      <Stack direction='row' spacing={2}>
        <TextField
          control={control}
          name='code'
          label='Код *'
          size='small'
          defaultValue={code || ''}
          required
        />
        <TextField
          control={control}
          name='price'
          label='Отпускная цена *'
          size='small'
          defaultValue={price || ''}
          required
        />
      </Stack>
      <TextField
        control={control}
        name='cooking_time'
        label='Время выдачи заказа, сек *'
        size='small'
        defaultValue={cookingTime || ''}
        required
      />
      <Stack sx={{ alignItems: 'center' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Switch
          control={control}
          name='need_quantity_notification'
          defaultValue={needQuantityNotification || false}
          color='success'
          label='Уведомлять о минимальном остатке товара в автомате'
        />
        <TextField
          control={control}
          name='quantity_notification'
          label='Мин. остаток, в ед. изм.*'
          size='small'
          defaultValue={quantityNotification || null}
          type='number'
          disabled={!isNeedNotification}
          required={isNeedNotification}
        />
      </Stack>

      {modalType === 'create' && (
        <>
          {fields.length > 0 && (
            <Typography sx={{ fontSize: '12px', lineHeight: '14px', marginBottom: '20px' }}>
              Укажите контейнеры с товаром или его ингредиентами через запятую или в виде диапазона,
              например 5,6,10-12
            </Typography>
          )}

          {containersIsLoading ? (
            <Skeleton variant='rounded' sx={{ width: '100%' }} height={40} />
          ) : (
            <>
              {fields.map(({ id }, index) => {
                return (
                  <TextField
                    key={id}
                    control={control}
                    name={`containers.${index}.containers`}
                    label={inputLabel(index) || ''}
                    size='small'
                  />
                )
              })}
            </>
          )}
        </>
      )}

      {modalType === 'change' && (
        <>
          {fields.length > 0 && (
            <Typography sx={{ fontSize: '12px', lineHeight: '14px', marginBottom: '20px' }}>
              Укажите контейнеры с товаром или его ингредиентами через запятую или в виде диапазона,
              например 5,6,10-12
            </Typography>
          )}
          {fields &&
            fields.map(({ id }, index) => {
              return (
                <TextField
                  key={id}
                  control={control}
                  name={`containers.${index}.containers`}
                  label={inputLabel(index) || ''}
                  size='small'
                />
              )
            })}
        </>
      )}
    </Stack>
  )
}

export default memo(CellForm)
