import React, { FC, memo, useCallback, useState } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { useAppDispatch } from 'store/hooks/useAppSelector'
import { FormProvider, useForm } from 'react-hook-form'
import { ContainerBatchForm } from 'components/modals/ContainerBatchModal'
import {
  createContainerBatch,
  deleteContainerBatch,
  getContainerBatches,
  partialUpdateContainerBatch,
} from 'store/reducers/ContainerBatchSlice/ActionCreators'
import { ContainerBatchFormTypes } from 'types/FormTypes'
import { ConfirmationModal, ProductBatchModal } from 'components/modals'

interface ContainerBatchModalProps {
  isOpen: boolean
  modalType: 'change' | 'create'
  onClose: () => void
  id?: number
  batch?: number
  quantity?: number
  containerId?: number
  productId?: number
}

const ContainerBatchModal: FC<ContainerBatchModalProps> = ({
  isOpen,
  modalType,
  onClose,
  id,
  batch,
  quantity,
  containerId,
  productId,
}) => {
  const dispatch = useAppDispatch()
  const methods = useForm<ContainerBatchFormTypes>({
    defaultValues: { container: containerId },
  })
  const { reset } = methods
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [createBatchModal, setCreateBatchModal] = useState<boolean>(false)

  const handleDelete = () => {
    dispatch(deleteContainerBatch({ id }))
      .unwrap()
      .then(() => {
        dispatch(getContainerBatches({ container: containerId }))
      })

    onClose()
  }

  const onSubmit = (formValues: ContainerBatchFormTypes) => {
    if (modalType === 'change') {
      dispatch(partialUpdateContainerBatch({ id, formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      dispatch(createContainerBatch({ formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            onClose()
            reset()
          }
        })
    }
  }

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const handleCreateBatchModalOpen = useCallback(() => {
    setCreateBatchModal(true)
  }, [createBatchModal])

  const handleCreateBatchModalClose = useCallback(() => {
    setCreateBatchModal(false)
  }, [createBatchModal])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '380px',
          width: '100%',
          overflowY: 'auto',
          margin: '0px',
        },
      }}
    >
      <Stack sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }} direction='column' spacing={3}>
        {modalType === 'change' ? (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Изменить партию</Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>
            Добавить партию товара
          </Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <ContainerBatchForm
                batch={batch}
                quantity={quantity}
                modalType={modalType}
                handleCreateBatchModalOpen={handleCreateBatchModalOpen}
              />
            </Box>

            <Stack sx={{ justifyContent: 'flex-end' }} direction='row' spacing={2}>
              {modalType === 'change' && (
                <Button
                  color='error'
                  sx={{ flexGrow: '1' }}
                  variant='outlined'
                  onClick={handleConfirmModalOpen}
                >
                  Удалить
                </Button>
              )}

              <Button variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
      <ProductBatchModal
        modalType='create'
        isOpen={createBatchModal}
        onClose={handleCreateBatchModalClose}
        productId={productId}
      />
    </Dialog>
  )
}

export default memo(ContainerBatchModal)
