import React, { FC, useState, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { Dashboard, Header, Sidebar } from 'components/generic'
import { getUser } from 'store/reducers/UsersSlice/ActionCreators'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { selectUserId } from 'store/selectors/authSelectors'
import { useMediaQuery } from 'react-responsive'

const Admin: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [sidebar, setSidebar] = useState<boolean>(isMobile ? false : true)
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)

  const handleDrawerOpen = useCallback(() => {
    setSidebar(true)
  }, [sidebar])

  const handleDrawerClose = useCallback(() => {
    setSidebar(false)
  }, [sidebar])

  useEffect(() => {
    if (userId) {
      dispatch(getUser({ id: userId }))
    }
  }, [])

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex' }}>
      <CssBaseline />
      <Header isOpen={sidebar} onOpen={handleDrawerOpen} />
      <Sidebar isOpen={sidebar} onClose={handleDrawerClose} />
      <Dashboard isOpen={sidebar} />
    </Box>
  )
}

export default Admin
