import React, { FC, ReactNode } from 'react'
import { Button, IconButton, Stack, Tooltip } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { TerminalFormTypes } from 'types/FormTypes'
import {
  SaveOutlined as SaveIcon,
  DeleteOutline as DeleteOutlineIcon,
  Logout as CloseIcon,
  ContentCopyRounded as ContentCopyIcon,
} from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'

interface TerminalFooterProps {
  handleConfirmModalOpen: () => void
  handleCloseTerminal: () => void
  methods: UseFormReturn<TerminalFormTypes, any>
  onSubmit: (formValues: TerminalFormTypes) => void
  handleCopyTerminalModalOpen: () => void
  children?: ReactNode
}

const TerminalFooter: FC<TerminalFooterProps> = ({
  children,
  handleConfirmModalOpen,
  handleCloseTerminal,
  methods,
  onSubmit,
  handleCopyTerminalModalOpen,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1415 })

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: { xs: 'flex-start', md: 'center' },
        padding: '10px 24px',
        borderTop: '1px solid',
        borderColor: 'divider',
        justifyContent: 'flex-end',
        minHeight: '73px',
      }}
      direction={{ xs: 'column', md: 'row' }}
    >
      {children}
      {isMobile ? (
        <Stack sx={{ marginLeft: 'auto' }} direction='row' spacing={0.5}>
          <Tooltip title='Копировать автомат' placement='top-end'>
            <IconButton size='large' color='success' onClick={handleCopyTerminalModalOpen}>
              <ContentCopyIcon color='success' fontSize='medium' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Удалить' placement='top-end'>
            <IconButton size='large' color='error' onClick={handleConfirmModalOpen}>
              <DeleteOutlineIcon color='error' fontSize='medium' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Закрыть' placement='top-end'>
            <IconButton size='large' color='primary' onClick={handleCloseTerminal}>
              <CloseIcon color='primary' fontSize='medium' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Сохранить' placement='top-end'>
            <IconButton size='large' color='primary' onClick={methods.handleSubmit(onSubmit)}>
              <SaveIcon color='primary' fontSize='medium' />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Stack sx={{ marginLeft: 'auto' }} direction='row' spacing={1}>
          <Button
            sx={{ color: 'success.main' }}
            variant='outlined'
            color='success'
            onClick={handleCopyTerminalModalOpen}
          >
            Копировать автомат
          </Button>
          <Button
            sx={{ color: 'error.main' }}
            variant='outlined'
            onClick={handleConfirmModalOpen}
            color='error'
          >
            Удалить
          </Button>
          <Button variant='outlined' onClick={handleCloseTerminal}>
            Закрыть
          </Button>
          <Button variant='contained' onClick={methods.handleSubmit(onSubmit)}>
            Сохранить
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export default TerminalFooter
