import { useEffect, useState } from 'react'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectRevenueChartData } from 'store/selectors/statSelectors'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

const useChart = () => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
  const { dayChart, weekChart, monthChart, yearChart } = useAppSelector(selectRevenueChartData)
  const [сhartValues, setChartValues] = useState<number[]>(dayChart.values)
  const [сhartLabels, setChartLabels] = useState<string[]>(dayChart.labels)
  const [active, setActive] = useState<string>('day')

  const day = () => {
    setChartValues(dayChart.values)
    setChartLabels(dayChart.labels)
    setActive('day')
  }
  const week = () => {
    setChartValues(weekChart.values)
    setChartLabels(weekChart.labels)
    setActive('week')
  }
  const month = () => {
    setChartValues(monthChart.values)
    setChartLabels(monthChart.labels)
    setActive('month')
  }
  const year = () => {
    setChartValues(yearChart.values)
    setChartLabels(yearChart.labels)
    setActive('year')
  }

  const chartButtons = [
    {
      id: 'day',
      label: 'День',
      func: day,
    },
    {
      id: 'week',
      label: '7 дней',
      func: week,
    },
    {
      id: 'month',
      label: '30 дней',
      func: month,
    },
    {
      id: 'year',
      label: 'Год',
      func: year,
    },
  ]

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
    borderWidth: 4,
    tension: 0.5,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: true,
          color: '#9A9A9A',
          font: {
            size: 12,
          },
        },
        grid: {
          borderWidth: 0,
          borderDash: [3],
          lineWidth: 1,
          color: '#9A9A9A',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: '#9A9A9A',
        },
      },
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
  }

  const chartData = {
    labels: сhartLabels,
    datasets: [
      {
        label: 'Выручка',
        data: сhartValues,
        borderColor: '#6271DD',
        backgroundColor: '#6271DD',
      },
    ],
  }

  useEffect(() => {
    setChartValues(dayChart.values)
    setChartLabels(dayChart.labels)
    setActive('day')
  }, [dayChart])

  return { chartButtons, сhartValues, сhartLabels, active, options, chartData }
}

export default useChart
