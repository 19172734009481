import { useEffect, useState, useCallback, useMemo } from 'react'
import { mapValues } from 'lodash'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getIngredients } from 'store/reducers/IngredientsSlice/ActionCreators'
import {
  deleteProduct,
  getProduct,
  getProducts,
  updateProduct,
} from 'store/reducers/ProductsSlice/ActionCreators'
import {
  selectisLoadingProduct,
  selectProduct,
  selectProductErrors,
} from 'store/selectors/productsSelector'
import { ProductFormTypes } from 'types/FormTypes'
import { Product } from 'types/Products'
import { productsSlice } from 'store/reducers/ProductsSlice/ProductsSlice'

const useProduct = () => {
  const {
    id,
    name,
    name_en,
    description,
    description_en,
    code,
    short_code,
    weight,
    cooking_time,
    icon,
    energy,
    proteins,
    fats,
    carbs,
    category,
    is_popular,
    is_warm_up,
    is_ingredient,
    ingredients,
    video,
    unit,
    non_food,
  } = useAppSelector<Product>(selectProduct)
  const dispatch = useAppDispatch()
  const { productId } = useParams()
  const [tabValue, setTabValue] = useState(0)
  const navigate = useNavigate()
  const methods = useForm<ProductFormTypes>({
    defaultValues: {
      ingredients: ingredients,
    },
  })
  const { setValue } = methods
  const { enqueueSnackbar } = useSnackbar()
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const isLoadingProduct = useAppSelector<boolean>(selectisLoadingProduct)
  const { clearErrors } = productsSlice.actions
  const productErrors = useAppSelector<string[]>(selectProductErrors)

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/products/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/products/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const handleDelete = () => {
    dispatch(deleteProduct({ id }))
      .unwrap()
      .then(() => {
        navigate('/admin/products')
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
        dispatch(getProducts({ page: page, page_size: pageSize }))
      })
  }

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const removeEmptyFields = (values: ProductFormTypes | any) => {
    return mapValues(values, (value) => {
      if (value === '') {
        return (value = null)
      }
      return value
    })
  }

  const onSubmit = (formValues: ProductFormTypes) => {
    const data = removeEmptyFields(formValues)
    dispatch(updateProduct({ id, formValues: data }))
      .unwrap()
      .then((res) => {
        if (res.id) {
          enqueueSnackbar('Успешно сохранено', { variant: 'success' })
          dispatch(getProducts({ page: page, page_size: pageSize }))
        }
      })
  }

  const handleBackRoute = () => {
    navigate(-1)
  }

  useEffect(() => {
    setValue('ingredients', ingredients)
  }, [ingredients])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    dispatch(getProduct({ id: productId }))
    dispatch(getIngredients({ product: productId }))
    dispatch(getProducts({}))
  }, [])

  useEffect(() => {
    if (!isLoadingProduct) {
      setValue('name', name)
      setValue('name_en', name_en)
      setValue('description', description)
      setValue('description_en', description_en)
      setValue('category', category)
      setValue('short_code', short_code)
      setValue('code', code)
      setValue('unit', unit)
      setValue('weight', weight)
      setValue('cooking_time', cooking_time)
      setValue('energy', energy)
      setValue('proteins', proteins)
      setValue('fats', fats)
      setValue('carbs', carbs)
      setValue('is_warm_up', is_warm_up)
      setValue('is_popular', is_popular)
      setValue('is_ingredient', is_ingredient)
      setValue('non_food', non_food)
    }
  }, [
    name,
    name_en,
    description,
    description_en,
    category,
    short_code,
    code,
    unit,
    weight,
    cooking_time,
    energy,
    proteins,
    fats,
    carbs,
    is_warm_up,
    is_popular,
    is_ingredient,
    isLoadingProduct,
    non_food,
  ])

  useEffect(() => {
    if (productErrors.length > 0) {
      productErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [productErrors])

  return {
    tabValue,
    handleChange,
    id,
    name,
    icon,
    video,
    handleDelete,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    onSubmit,
    handleBackRoute,
    methods,
    confirmModal,
    isLoadingProduct,
    nonFood: non_food,
  }
}

export default useProduct
