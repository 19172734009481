import React, { FC } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import { GetCoordinates } from 'components/generic/LeafletMap'

interface LeafletMapProps {
  coordinates?: number[]
}

const LeafletMap: FC<LeafletMapProps> = ({ coordinates }) => {
  return (
    <MapContainer
      style={{ width: '100%', height: '100%' }}
      center={coordinates ? [coordinates[1], coordinates[0]] : [55.7522, 37.6156]}
      zoom={coordinates ? 13 : 5}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <GetCoordinates coordinates={coordinates} />
    </MapContainer>
  )
}

export default LeafletMap
