import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { ProductBatchModal } from 'components/modals'

interface TableRowProps {
  id: number
  code: string
  expireDate: Date | string
  buyingPrice: number
  productId: number
}

const TableRow: FC<TableRowProps> = ({ id, code, expireDate, buyingPrice, productId }) => {
  const [batchModal, setBatchModal] = useState<boolean>(false)
  const labelDate = new Date(expireDate).toLocaleDateString()

  const handleBatchModalOpen = useCallback(() => {
    setBatchModal(true)
  }, [batchModal])

  const handleBatchModalClose = useCallback(() => {
    setBatchModal(false)
  }, [batchModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleBatchModalOpen}
      >
        <TableCell component='th' scope='row'>
          {code}
        </TableCell>
        <TableCell align='left'>{labelDate}</TableCell>
        <TableCell align='left'>{buyingPrice || '-'}</TableCell>
      </MUITableRow>
      <ProductBatchModal
        isOpen={batchModal}
        onClose={handleBatchModalClose}
        modalType='change'
        id={id}
        code={code}
        buyingPrice={buyingPrice}
        expireDate={expireDate}
        productId={productId}
      />
    </>
  )
}

export default TableRow
