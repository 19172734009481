import React, { FC, memo } from 'react'
import { Stack } from '@mui/material'
import { TextField, SelectField } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { units } from 'constants/SelectFields'
import { SelectFieldType } from 'types/Other'

interface IngredientFormProps {
  ingredientsList: SelectFieldType[]
  ingredient?: number
  consumption?: number
  unit?: string
}

const IngredientForm: FC<IngredientFormProps> = ({
  ingredientsList,
  ingredient,
  consumption,
  unit,
}) => {
  const { control } = useFormContext()

  return (
    <Stack direction='column' spacing={2.5}>
      <SelectField
        control={control}
        name='ingredient'
        label='Товар'
        size='small'
        selectItem={ingredientsList}
        defaultValue={ingredient || ''}
        required
      />

      <TextField
        control={control}
        name='consumption'
        label='Расход'
        size='small'
        defaultValue={consumption || ''}
        type='number'
        required
      />

      <SelectField
        control={control}
        name='unit'
        label='Единицы измерения'
        size='small'
        selectItem={units}
        defaultValue={unit || ''}
        required
      />
    </Stack>
  )
}

export default memo(IngredientForm)
