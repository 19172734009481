import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { UserModal } from 'components/modals'
import { useMediaQuery } from 'react-responsive'
interface TableRowProps {
  id: number
  firstName: string
  lastName: string
  username: string
  email: string
  phone: string
}

const TableRow: FC<TableRowProps> = ({ id, firstName, lastName, username, email, phone }) => {
  const [userModal, setUserModal] = useState<boolean>(false)
  const isHidden = useMediaQuery({ maxWidth: 800 })

  const handleUserModalOpen = useCallback(() => {
    setUserModal(true)
  }, [userModal])

  const handleUserModalClose = useCallback(() => {
    setUserModal(false)
  }, [userModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root ': {
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleUserModalOpen}
      >
        <TableCell component='th' scope='row'>
          {username || '-'}
        </TableCell>
        {!isHidden && (
          <TableCell component='th' align='left'>
            {email || '-'}
          </TableCell>
        )}
        <TableCell component='th' align='left'>
          {firstName || '-'}
        </TableCell>
        {!isHidden && (
          <TableCell component='th' align='left'>
            {lastName || '-'}
          </TableCell>
        )}
      </MUITableRow>
      <UserModal
        isOpen={userModal}
        onClose={handleUserModalClose}
        id={id}
        modalType='change'
        username={username}
        phone={phone}
        email={email}
        firstName={firstName}
        lastName={lastName}
      />
    </>
  )
}

export default TableRow
