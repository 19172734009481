import { useMemo } from 'react'

const usePageOptions = (count: number) => {
  const rowsPerPageOptions = useMemo(() => {
    const options = [5, 10, 25, 50, 100, 200]
    if (count) {
      return options
    }

    return
  }, [count])

  return rowsPerPageOptions
}

export default usePageOptions
