import React, { FC } from 'react'
import { Box, Button, Dialog, Stack, Typography } from '@mui/material'

interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  confirm: () => void
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ isOpen, onClose, confirm }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '420px',
          width: '100%',
          overflowY: 'inherit',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px 35px' }}>
        <Typography sx={{ fontSize: '22px', lineHeight: '24px', marginBottom: '20px' }}>
          Вы подтверждаете удаление?
        </Typography>
        <Typography sx={{ marginBottom: '50px' }}>
          Удаление данного элемента может повлечь удаление связанных с ним данных и этот процесс не
          обратим.
        </Typography>
        <Stack sx={{ alignSelf: 'flex-end' }} direction='row' spacing={2}>
          <Button variant='outlined' onClick={onClose}>
            Отмена
          </Button>
          <Button color='error' variant='contained' onClick={confirm}>
            Удалить
          </Button>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default ConfirmationModal
