import { useState, useMemo, useCallback } from 'react'

const useOrderFilter = (clearPageState: () => void) => {
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false)
  const [product, setProduct] = useState<string | null>(
    sessionStorage.getItem('orderProduct') || '',
  )
  const [terminal, setTerminal] = useState<string | null>(
    sessionStorage.getItem('orderTerminal') || '',
  )
  const [startDate, setStartDate] = useState<string | null>(
    sessionStorage.getItem('orderStartDate') || null,
  )
  const [endDate, setEndDate] = useState<string | null>(
    sessionStorage.getItem('orderEndDate') || null,
  )

  const selectedFilterCount = useMemo(() => {
    return [product, terminal, startDate].filter((item) => item).length
  }, [product, terminal, startDate])

  const handleFilterProduct = (event: { target: { value: string } }) => {
    clearPageState()
    setProduct(event.target.value)
    sessionStorage.setItem('orderProduct', event.target.value.toString())
  }

  const handleFilterTerminal = (event: { target: { value: string } }) => {
    clearPageState()
    setTerminal(event.target.value)
    sessionStorage.setItem('orderTerminal', event.target.value.toString())
  }

  const handleStartDate = (value: string | null) => {
    clearPageState()
    sessionStorage.setItem('orderStartDate', value ? value.toString() : '')
    sessionStorage.setItem('orderEndDate', value ? value.toString() : '')

    setStartDate(value)
    setEndDate(value)
  }

  const handleEndDate = (value: string | null) => {
    clearPageState()
    sessionStorage.setItem('orderEndDate', value ? value.toString() : '')
    setEndDate(value)
  }

  const orderDateRange = useMemo(() => {
    if (startDate && endDate) {
      const start = new Date(startDate).toLocaleDateString('fr-CA')
      const end = new Date(
        new Date(endDate).setDate(new Date(endDate).getDate() + 1),
      ).toLocaleDateString('fr-CA')

      return `${start},${end}`
    }
    return undefined
  }, [startDate, endDate])

  const handleToggleFilter = useCallback(() => {
    setFilterIsOpen(!filterIsOpen)
  }, [filterIsOpen])

  const handleClearFilter = () => {
    setProduct('')
    setTerminal('')
    setStartDate(null)
    setEndDate(null)
    sessionStorage.removeItem('orderProduct')
    sessionStorage.removeItem('orderTerminal')
    sessionStorage.removeItem('orderStartDate')
    sessionStorage.removeItem('orderEndDate')
  }

  return {
    product,
    terminal,
    startDate,
    endDate,
    orderDateRange,
    handleFilterProduct,
    handleFilterTerminal,
    handleStartDate,
    handleEndDate,
    selectedFilterCount,
    filterIsOpen,
    handleToggleFilter,
    handleClearFilter,
  }
}

export default useOrderFilter
