import React, { FC } from 'react'
import { Box, Table as MUITable, TableBody, TableContainer, Stack, Typography } from '@mui/material'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { Filter, TableRow } from 'components/orders'
import { useOrders } from 'hooks/orders'
import { RowData } from 'types/Table'

const Orders: FC = () => {
  const {
    headCells,
    order,
    orderBy,
    rowsPerPage,
    page,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    product,
    terminal,
    startDate,
    endDate,
    handleFilterProduct,
    handleFilterTerminal,
    handleStartDate,
    handleEndDate,
    ordersIsLoading,
    filterIsOpen,
    selectedFilterCount,
    filterHeight,
    handleToggleFilter,
    rowsPerPageOptions,
    labelDisplayedRows,
    revenue,
    isShowRevenue,
    handleClearFilter,
  } = useOrders()

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <Toolbar
        title={revenue ? `Продажи на ${revenue}` : 'Продажи'}
        isFilterButton
        isOpen={filterIsOpen}
        selectedFilterCount={selectedFilterCount}
        handleToggleFilter={handleToggleFilter}
        isLoadingData={ordersIsLoading}
      />
      <Filter
        isOpen={filterIsOpen}
        product={product}
        terminal={terminal}
        startDate={startDate}
        endDate={endDate}
        handleFilterProduct={handleFilterProduct}
        handleFilterTerminal={handleFilterTerminal}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        handleClearFilter={handleClearFilter}
      />

      <TableContainer
        sx={{
          height: { xs: 'auto', sm: `calc(100vh - ${filterHeight}px)` },
          transition: 'all 0.3s ease-in-out',
          zIndex: '10',
        }}
      >
        <MUITable stickyHeader size='small'>
          <TableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.map((row: RowData) => (
              <TableRow
                key={row.id}
                date={row.date}
                time={row.time}
                marketplace={row.marketplace}
                terminal={row.terminal}
                product={row.product}
                price={row.price}
                quantity={row.quantity}
              />
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>
      <Stack
        sx={{
          width: '100%',
          alignItems: 'center',
          padding: '10px 24px',
          borderTop: '1px solid',
          borderColor: 'divider',
          minHeight: '73px',
          justifyContent: 'space-between',
        }}
        direction='row'
      >
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
        />
        {isShowRevenue && (
          <Typography
            sx={{ fontSize: '24px', lineHeight: '26px', display: { xs: 'none', md: 'block' } }}
          >
            Итого: {revenue}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}

export default Orders
