import React, { FC, useState, useEffect } from 'react'
import {
  Dialog,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Box,
} from '@mui/material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectAvailableUsers } from 'store/selectors/usersSelectors'

interface AdminListModalProps {
  isOpen: boolean
  onClose: () => void
  handleAddAdministrator: (selectedUsers: number[]) => void
  administrators: number[]
}

const AdminListModal: FC<AdminListModalProps> = ({
  isOpen,
  onClose,
  handleAddAdministrator,
  administrators,
}) => {
  const usersList = useAppSelector(selectAvailableUsers(administrators))

  const [checked, setChecked] = useState<number[]>([])

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  useEffect(() => {
    if (!isOpen) {
      setChecked([])
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '400px',
          maxHeight: '400px',
          minHeight: '400px',
          width: '100%',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          margin: '25px',
        },
      }}
    >
      <Typography
        sx={{ fontSize: '18px', lineHeight: '21px', padding: { xs: '20px 20px', sm: '20px 35px' } }}
      >
        Выбирите администратора
      </Typography>

      <List
        sx={{
          width: '100%',
          overflow: 'auto',
          flexGrow: '1',
          padding: '0',
        }}
      >
        {usersList.map((user) => (
          <ListItem key={user.id} disablePadding>
            <ListItemButton
              sx={{ padding: { xs: '0px 20px', sm: '0px 35px' } }}
              role={undefined}
              onClick={handleToggle(user.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={checked.indexOf(user.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': user.username }}
                />
              </ListItemIcon>
              <ListItemText id={user.username} primary={user.username} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Box sx={{ padding: { xs: '20px 20px', sm: '20px 35px' }, alignSelf: 'flex-end' }}>
        <Button variant='contained' onClick={() => handleAddAdministrator(checked)}>
          Добавить
        </Button>
      </Box>
    </Dialog>
  )
}

export default AdminListModal
