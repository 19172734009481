import React, { FC, useCallback, useState } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { FormProvider, useForm } from 'react-hook-form'
import { IngredientForm } from 'components/modals/IngredientModal'
import { selectIngredientsList } from 'store/selectors/ingredientsSelectors'
import { IngredientFormTypes } from 'types/FormTypes'
import {
  createIngredient,
  deleteIngredient,
  updateIngredient,
} from 'store/reducers/IngredientsSlice/ActionCreators'
import { ConfirmationModal } from 'components/modals'
import { useSnackbar } from 'notistack'

interface IngredientModalProps {
  isOpen: boolean
  onClose: () => void
  modalType: 'create' | 'change'
  id?: number
  product?: number
  productName?: string
  ingredient?: number
  consumption?: number
  unit?: string
}

const IngredientModal: FC<IngredientModalProps> = ({
  isOpen,
  onClose,
  modalType,
  id,
  product,
  productName,
  ingredient,
  consumption,
  unit,
}) => {
  const dispatch = useAppDispatch()
  const methods = useForm<IngredientFormTypes>({ defaultValues: { product: product } })
  const { reset } = methods
  const ingredientsList = useAppSelector(selectIngredientsList)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = () => {
    dispatch(deleteIngredient({ id }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
        onClose()
      })
  }

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const onSubmit = (formValues: IngredientFormTypes) => {
    if (modalType === 'change') {
      dispatch(updateIngredient({ id, formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      dispatch(createIngredient({ formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            onClose()
            reset()
          }
        })
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '450px',
          minWidth: '450px',
          overflowY: 'inherit',
        },
      }}
    >
      <Stack sx={{ padding: '20px 35px' }} direction='column' spacing={3}>
        {modalType === 'change' ? (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>
            Ингредиент - {productName}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Добавить ингредиент</Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <IngredientForm
                ingredientsList={ingredientsList}
                ingredient={ingredient}
                consumption={consumption}
                unit={unit}
              />
            </Box>

            <Stack sx={{ justifyContent: 'space-between' }} direction='row' spacing={2}>
              {modalType === 'change' && (
                <Button sx={{ flexGrow: '1' }} variant='outlined' onClick={handleConfirmModalOpen}>
                  Удалить
                </Button>
              )}
              <Button sx={{ flexGrow: '1' }} variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
    </Dialog>
  )
}

export default IngredientModal
