import React, { FC } from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { GroupForm } from 'components/modals/GroupModal/Content'
import { ConfirmationModal } from 'components/modals'
import { useGroupModal } from 'hooks/groups'

interface ContentProps {
  modalType: 'change' | 'create'
  onClose: () => void
  id?: number
  externalId?: number
  name?: string
  mainTerminal?: number
}

const Content: FC<ContentProps> = ({ modalType, onClose, id, externalId, name, mainTerminal }) => {
  const {
    confirmModal,
    methods,
    onSubmit,
    handleDelete,
    handleConfirmModalOpen,
    handleConfirmModalClose,
  } = useGroupModal(modalType, onClose, id, externalId)

  return (
    <>
      <Stack sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }} direction='column' spacing={3}>
        {modalType === 'change' ? (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>
            Группа автоматов - {name}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Создать группу</Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <GroupForm name={name} externalId={externalId} mainTerminal={mainTerminal} />
            </Box>

            <Stack sx={{ justifyContent: 'space-between' }} direction='row' spacing={2}>
              {modalType === 'change' && (
                <Button
                  sx={{ width: '100%' }}
                  color='error'
                  variant='outlined'
                  onClick={handleConfirmModalOpen}
                >
                  Удалить
                </Button>
              )}

              <Button sx={{ width: '100%' }} variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
    </>
  )
}

export default Content
