import { useState, useEffect, useMemo } from 'react'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { HeadCell, RowData } from 'types/Table'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getOrders } from 'store/reducers/OrdersSlice/ActionCreators'
import {
  selectOrders,
  selectOrdersCount,
  selectOrdersIsLoading,
  selectRevenue,
} from 'store/selectors/ordersSelectors'
import { useMediaQuery } from 'react-responsive'
import { getProducts } from 'store/reducers/ProductsSlice/ActionCreators'
import { getTerminals } from 'store/reducers/TerminalsSlice/ActionCreators'
import { useOrderFilter } from 'hooks/orders'
import { Order } from 'types/Orders'

const useOrders = () => {
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    clearPageState,
  } = useTableSort()
  const {
    product,
    terminal,
    startDate,
    endDate,
    orderDateRange,
    handleFilterProduct,
    handleFilterTerminal,
    handleStartDate,
    handleEndDate,
    selectedFilterCount,
    filterIsOpen,
    handleToggleFilter,
    handleClearFilter,
  } = useOrderFilter(clearPageState)
  const dispatch = useAppDispatch()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector<number>(selectOrdersCount)
  const orders = useAppSelector<Order[]>(selectOrders)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const filterHeightOn1200 = useMediaQuery({ maxWidth: 1200 })
  const ordersIsLoading = useAppSelector<boolean>(selectOrdersIsLoading)
  const revenue = useAppSelector<number>(selectRevenue)
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'date',
      label: 'Дaтa',
      sort: 'order__created_at',
    },
    {
      id: 'time',
      label: 'Время',
      sort: '',
    },
    // {
    //   id: 'marketplace',
    //   label: 'Точка продаж',
    //   sort: '',
    // },
    {
      id: 'terminal',
      label: 'Автомат',
      sort: 'terminal',
    },
    {
      id: 'product',
      label: 'Товар',
      sort: 'product',
    },
    {
      id: 'price',
      label: 'Цена',
      sort: 'price',
    },
    {
      id: 'quantity',
      label: 'Кол-во',
      sort: 'quantity',
    },
  ].filter((item) => {
    if (isHidden600) {
      return item.id !== 'terminal' && item.id !== 'price' && item.id !== 'quantity'
    } else {
      return item
    }
  }) as HeadCell[]

  const isShowRevenue = useMemo(() => {
    return revenue > 0
  }, [revenue])

  useEffect(() => {
    dispatch(
      getOrders({
        page: pageQuery,
        page_size: rowsPerPage,
        ordering,
        terminal: terminal || undefined,
        product: product || undefined,
        orderDateRange,
      }),
    )
  }, [page, rowsPerPage, pageQuery, ordering, terminal, product, orderDateRange])

  useEffect(() => {
    dispatch(getTerminals({}))
    dispatch(getProducts({}))
  }, [])

  useEffect(() => {
    setRows(
      orders.map((order) => {
        const time = new Date(order.order.created_at).toLocaleString('ru', {
          hour: 'numeric',
          minute: 'numeric',
        })
        const date = new Date(order.order.created_at.split('/').reverse().join('/') || '')
          .toLocaleDateString()
          .split('T')[0]

        return {
          date,
          time,
          id: order.id,
          marketplace: '-',
          terminal: order.terminal.address,
          product: order.name,
          price: order.price,
          quantity: order.quantity,
        }
      }),
    )
  }, [orders, rowsPerPage, page])

  const filterHeight = useMemo(() => {
    if (isHidden600) {
      return filterIsOpen ? 600 : 275
    }

    if (filterHeightOn1200) {
      return filterIsOpen ? 405 : 275
    }
    return filterIsOpen ? 345 : 275
  }, [filterIsOpen, filterHeightOn1200, isHidden600])

  return {
    headCells,
    order,
    orderBy,
    rowsPerPage,
    page,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    product,
    terminal,
    startDate,
    endDate,
    handleFilterProduct,
    handleFilterTerminal,
    handleStartDate,
    handleEndDate,
    ordersIsLoading,
    filterIsOpen,
    selectedFilterCount,
    filterHeight,
    handleToggleFilter,
    rowsPerPageOptions,
    labelDisplayedRows,
    revenue,
    isShowRevenue,
    handleClearFilter,
  }
}

export default useOrders
