import React, { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Product } from 'types/Stat'

interface ProductsProps {
  data: Product[]
}

const Products: FC<ProductsProps> = ({ data }) => {
  return (
    <Box sx={{ padding: '25px 30px', borderRadius: '10px', backgroundColor: 'secondary.main' }}>
      <Typography
        sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: '400', marginBottom: '35px' }}
      >
        ТОП Товаров за неделю, ед.
      </Typography>

      <Stack direction='column' spacing={2}>
        {data.slice(0, 10).map((item, index) => (
          <Stack sx={{ justifyContent: 'space-between' }} key={item.product.id} direction='row'>
            <Stack direction='row' spacing={3.1}>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400', color: '#9A9A9A' }}
              >
                {index + 1}
              </Typography>
              <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400' }}>
                {item.product.name}
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400' }}>
              {item.quantity}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}

export default Products
