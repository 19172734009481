import { createAsyncThunk } from '@reduxjs/toolkit'
import { Marketplace, MetricsData, Product, RevenueChartData } from 'types/Stat'
import { http } from 'utils/httpConfig'

export const getMarketplace = createAsyncThunk('stat/get_marketplace', async (_, thunkAPI) => {
  try {
    const response = await http.get<Marketplace>('/api/admin/stat/marketplace/')
    return response.data
  } catch (e) {
    return thunkAPI.rejectWithValue(`Failed to get marketplace stat, ${e}`)
  }
})

export const getMetrics = createAsyncThunk(
  'stat/get_metrics',
  async (params: { from?: string; to?: string }, thunkAPI) => {
    try {
      const { from, to } = params
      const response = await http.get<MetricsData>('/api/admin/stat/metrics/', {
        params: {
          from: from ? from : undefined,
          to: to ? to : undefined,
        },
      })
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue(`Failed to get metrics stat, ${e}`)
    }
  },
)

export const getProduct = createAsyncThunk('stat/get_product', async (_, thunkAPI) => {
  try {
    const response = await http.get<Product>('/api/admin/stat/product/')
    return response.data
  } catch (e) {
    return thunkAPI.rejectWithValue(`Failed to get product stat, ${e}`)
  }
})

export const getRevenueChart = createAsyncThunk('stat/get_revenue_chart', async (_, thunkAPI) => {
  try {
    const response = await http.get<RevenueChartData>('/api/admin/stat/revenue_chart/')
    return response.data
  } catch (e) {
    return thunkAPI.rejectWithValue(`Failed to get revenue chart stat, ${e}`)
  }
})

export const getAuditList = createAsyncThunk('stat/get_audit_List', async (_, thunkAPI) => {
  try {
    const response = await http.get<RevenueChartData>('/api/admin/stat/revenue_chart/')
    return response.data
  } catch (e) {
    return thunkAPI.rejectWithValue(`Failed to get revenue chart stat, ${e}`)
  }
})
