import React, { FC, useState, memo, useEffect } from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SelectField, Switch, TextArea, TextField } from 'components/generic'
import placeholder from 'assets/img/image-placeholder.png'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { selectCategiesSelectList } from 'store/selectors/categoriesSelectors'
import { partialUpdateProduct } from 'store/reducers/ProductsSlice/ActionCreators'
import { units } from 'constants/SelectFields'

interface GeneralFormProps {
  id: number
  icon: string
  nonFood: boolean
}

const GeneralForm: FC<GeneralFormProps> = ({ id, icon, nonFood }) => {
  const { control, watch } = useFormContext()

  const dispatch = useAppDispatch()
  const categoriesList =
    useAppSelector<{ label: string; value: number }[]>(selectCategiesSelectList)
  const [image, setImage] = useState<string>(icon)
  const [isNonFood, setisNonFood] = useState<boolean>(nonFood)
  const nonFoodValue = watch('non_food')

  useEffect(() => {
    if (nonFoodValue) {
      setisNonFood(true)
    } else {
      setisNonFood(false)
    }
  }, [nonFoodValue, nonFood])

  const handleAddImage = (event: any) => {
    const formData = new FormData()
    formData.append('icon', event.target.files ? event.target.files[0] : null)
    dispatch(partialUpdateProduct({ id, formValues: formData }))
      .unwrap()
      .then((res) => {
        if (res.icon) {
          setImage(res.icon)
        }
      })
  }

  const handleDeleteImage = () => {
    const formData = new FormData()
    formData.append('icon', '')
    dispatch(partialUpdateProduct({ id, formValues: formData }))
  }

  return (
    <Stack direction='column' spacing={2}>
      <Box>
        <Switch
          control={control}
          name='non_food'
          label='Непищевой товар'
          size='small'
          color='success'
          defaultValue={nonFood}
        />
      </Box>
      <Stack direction='row' spacing={2}>
        <TextField
          control={control}
          name='name'
          label='Название товара RU *'
          size='small'
          required
        />
        <TextField control={control} name='name_en' label='Название товара EN' size='small' />
      </Stack>
      <Stack direction='row' spacing={2}>
        <TextArea control={control} name='description' label='Описание RU' />
        <TextArea control={control} name='description_en' label='Описание EN' />
      </Stack>
      <Stack direction='row' spacing={2}>
        <Stack sx={{ width: '50%' }} direction='column' spacing={2}>
          <SelectField
            control={control}
            name='category'
            label='Категория *'
            required
            size='small'
            selectItem={categoriesList}
          />
          <Stack direction='row' spacing={2}>
            <TextField
              sx={{ width: '60%' }}
              control={control}
              name='short_code'
              label='Код товара 4 знака'
              size='small'
              mask='9999'
              maskPlaceholder={''}
            />
            <TextField
              sx={{ width: '40%' }}
              control={control}
              name='code'
              label='Штрих код *'
              size='small'
              type='number'
              maskPlaceholder={''}
              required
            />
          </Stack>

          <Stack direction='row' spacing={2}>
            <Stack sx={{ width: !isNonFood ? '60%' : '100%' }} direction='row' spacing={2}>
              <SelectField
                sx={{ width: '30%' }}
                control={control}
                name='unit'
                label='Eд.изм.'
                required
                size='small'
                selectItem={units}
              />
              <TextField
                sx={{ width: '70%' }}
                control={control}
                name='weight'
                label='Вес/Объем в ед.изм.'
                size='small'
                type='number'
                required
              />
            </Stack>
            {!isNonFood && (
              <TextField
                sx={{ width: '40%' }}
                control={control}
                name='cooking_time'
                label='Время разогрева, сек *'
                size='small'
                type='number'
                required
              />
            )}
          </Stack>
          {!isNonFood && (
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  control={control}
                  name='energy'
                  label='Эн. ценность, Ккал'
                  size='small'
                  type='number'
                />
                <TextField
                  control={control}
                  name='proteins'
                  label='Белки, г'
                  size='small'
                  type='number'
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                <TextField
                  control={control}
                  name='fats'
                  label='Жиры, г'
                  size='small'
                  type='number'
                />
                <TextField
                  control={control}
                  name='carbs'
                  label='Углеводы, г'
                  size='small'
                  type='number'
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack direction='column' spacing={1} sx={{ width: '50%' }}>
          <Typography sx={{ marginBottom: '10px' }}>
            Изображение(PNG не более 1000х1000 пикс. и не более 1,5Мб)
          </Typography>
          <Stack sx={{ marginBottom: '20px' }} direction='row' spacing={2.4}>
            <Box sx={{ width: '110px', height: '110px', overflow: 'hidden' }}>
              <Box
                sx={{ objectFit: 'cover', width: '100%', minHeight: '100%' }}
                component='img'
                src={image || placeholder}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Stack direction='column' spacing={2}>
                <Button variant='contained' component='label'>
                  {image ? 'Изменить' : 'Загрузить'}
                  <input
                    name='icon'
                    type='file'
                    hidden
                    onChange={(event: any) => handleAddImage(event)}
                  />
                </Button>
                {image && (
                  <Button variant='outlined' onClick={handleDeleteImage}>
                    Удалить
                  </Button>
                )}
              </Stack>
            </Box>
          </Stack>

          <Stack
            sx={{
              '& :first-of-type': {
                margin: '0',
              },
            }}
            direction='column'
            spacing={1.2}
          >
            {!isNonFood && (
              <Switch
                control={control}
                name='is_warm_up'
                label='Можно греть'
                size='small'
                color='success'
              />
            )}

            <Switch
              control={control}
              name='is_popular'
              label='Показывать в популярных'
              size='small'
              color='success'
            />
            <Switch
              control={control}
              name='is_ingredient'
              label='Может быть ингредиентом'
              size='small'
              color='success'
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default memo(GeneralForm)
