import React, { FC } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { Batch, General, Ingredients, TabSection, Video } from 'components/product'
import { useProduct } from 'hooks/products'
import { ConfirmationModal } from 'components/modals'

const Product: FC = () => {
  const {
    tabValue,
    handleChange,
    id,
    name,
    icon,
    video,
    handleDelete,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    onSubmit,
    handleBackRoute,
    methods,
    confirmModal,
    isLoadingProduct,
    nonFood,
  } = useProduct()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          overflow: 'hidden',
          flexGrow: '1',
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label='Общее' disabled={isLoadingProduct} />
            <Tab label='Ингредиенты' disabled={isLoadingProduct} />
            <Tab label='Партии и сроки годности' disabled={isLoadingProduct} />
            <Tab label='Видео' disabled={isLoadingProduct} />
          </Tabs>
        </Box>
        <TabSection value={tabValue} index={0}>
          <General
            id={id}
            icon={icon}
            handleConfirmModalOpen={handleConfirmModalOpen}
            onSubmit={onSubmit}
            handleBackRoute={handleBackRoute}
            methods={methods}
            nonFood={nonFood}
          />
        </TabSection>
        <TabSection value={tabValue} index={1}>
          <Ingredients
            name={name}
            id={id}
            handleConfirmModalOpen={handleConfirmModalOpen}
            onSubmit={onSubmit}
            handleBackRoute={handleBackRoute}
            methods={methods}
          />
        </TabSection>
        <TabSection value={tabValue} index={2}>
          <Batch
            name={name}
            id={id}
            handleConfirmModalOpen={handleConfirmModalOpen}
            onSubmit={onSubmit}
            handleBackRoute={handleBackRoute}
            methods={methods}
          />
        </TabSection>
        <TabSection value={tabValue} index={3}>
          <Video
            video={video}
            id={id}
            handleConfirmModalOpen={handleConfirmModalOpen}
            onSubmit={onSubmit}
            handleBackRoute={handleBackRoute}
            methods={methods}
          />
        </TabSection>
      </Box>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
    </>
  )
}

export default Product
