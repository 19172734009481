import React, { FC, memo, useEffect, useState } from 'react'
import placeholder from 'assets/img/image-placeholder.png'
import { Box, Stack, Typography, Button, Tooltip, IconButton } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SelectField, Switch, TextArea, TextField } from 'components/generic'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectCategiesSelectList } from 'store/selectors/categoriesSelectors'
import { units } from 'constants/SelectFields'
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material'

interface GroupFormProps {
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  category?: number
  shortCode?: string
  code?: string
  unit?: string
  weight?: number
  cookingTime?: number
  energy?: number
  proteins?: number
  fats?: number
  carbs?: number
  image?: string
  isIngredient?: boolean
  isWarmUp?: boolean
  isPopular?: boolean
  nonFood?: boolean
  handleAddImage: (event: any) => void
  handleDeleteImage: () => void
  handleCategoryCreateModalOpen?: () => void
  handleCategoryChangeModalOpen?: () => void
}

const GroupForm: FC<GroupFormProps> = ({
  name,
  nameEn,
  description,
  descriptionEn,
  category,
  shortCode,
  code,
  unit,
  weight,
  cookingTime,
  energy,
  proteins,
  fats,
  carbs,
  image,
  isIngredient,
  isWarmUp,
  isPopular,
  nonFood,
  handleAddImage,
  handleDeleteImage,
  handleCategoryCreateModalOpen,
  handleCategoryChangeModalOpen,
}) => {
  const { control, setValue, watch } = useFormContext()
  const nonFoodValue = watch('non_food')
  const [isNonFood, setisNonFood] = useState<boolean>(nonFood || false)
  const categoriesList =
    useAppSelector<{ label: string; value: number }[]>(selectCategiesSelectList)

  useEffect(() => {
    setValue('name', name || '')
    setValue('name_en', nameEn || '')
    setValue('description', description || '')
    setValue('description_en', descriptionEn || '')
    setValue('category', category || '')
    setValue('short_code', shortCode || '')
    setValue('code', code || '')
    setValue('unit', unit || '')
    setValue('weight', weight || '')
    setValue('cooking_time', cookingTime || '')
    setValue('energy', Number(energy || ''))
    setValue('proteins', Number(proteins || ''))
    setValue('fats', Number(fats || ''))
    setValue('carbs', Number(carbs || ''))
    setValue('is_warm_up', isWarmUp || false)
    setValue('is_popular', isPopular || false)
    setValue('is_ingredient', isIngredient || false)
    setValue('non_food', nonFood || false)
  }, [
    name,
    nameEn,
    description,
    descriptionEn,
    category,
    shortCode,
    code,
    unit,
    weight,
    cookingTime,
    energy,
    proteins,
    fats,
    carbs,
    isWarmUp,
    isPopular,
    isIngredient,
    nonFood,
  ])

  useEffect(() => {
    if (nonFoodValue) {
      setisNonFood(true)
    } else {
      setisNonFood(false)
    }
  }, [nonFoodValue, nonFood])

  return (
    <Stack direction='column' spacing={2}>
      <Box>
        <Switch
          control={control}
          name='non_food'
          label='Непищевой товар'
          size='small'
          color='success'
          defaultValue={nonFood}
        />
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          control={control}
          name='name'
          label='Название товара RU *'
          size='small'
          defaultValue={name || ''}
          required
        />
        <TextField
          control={control}
          name='name_en'
          label='Название товара EN'
          size='small'
          defaultValue={nameEn || ''}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextArea
          control={control}
          name='description'
          label='Описание RU'
          defaultValue={description || ''}
        />
        <TextArea
          control={control}
          name='description_en'
          label='Описание EN'
          defaultValue={descriptionEn || ''}
        />
      </Stack>
      <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2}>
        <Stack direction='column' spacing={2} sx={{ width: '50%' }}>
          <Stack direction='row' spacing={2}>
            <SelectField
              control={control}
              name='category'
              label='Категория *'
              required
              size='small'
              selectItem={categoriesList}
              defaultValue={category}
            />
            <Tooltip title='Добавить категорию' placement='top-start'>
              <IconButton color='primary' onClick={handleCategoryCreateModalOpen}>
                <AddIcon color='primary' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Редактировать категорию' placement='top-start'>
              <IconButton color='primary' onClick={handleCategoryChangeModalOpen}>
                <EditIcon color='primary' />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack direction='row' spacing={2}>
            <TextField
              control={control}
              name='short_code'
              label='Код товара 4 знака *'
              size='small'
              mask='9999'
              maskPlaceholder={''}
              defaultValue={shortCode || ''}
            />
            <TextField
              control={control}
              name='code'
              label='Штрих код *'
              size='small'
              type='number'
              required
              defaultValue={code || ''}
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <Stack sx={{ width: !isNonFood ? '65%' : '100%' }} direction='row' spacing={2}>
              <SelectField
                sx={{ width: '35%' }}
                control={control}
                name='unit'
                label='Eд.изм.'
                required
                size='small'
                selectItem={units}
                defaultValue={unit || ''}
              />
              <TextField
                sx={{ width: '65%' }}
                control={control}
                name='weight'
                label='Вес/Объем в ед.изм.'
                size='small'
                defaultValue={weight || ''}
                type='number'
                required
              />
            </Stack>
            {!isNonFood && (
              <TextField
                sx={{ width: '35%' }}
                control={control}
                name='cooking_time'
                label='Время приготовления, сек *'
                size='small'
                required
                type='number'
                defaultValue={cookingTime || ''}
              />
            )}
          </Stack>
          {!isNonFood && (
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  control={control}
                  name='energy'
                  label='Эн. ценность, Ккал'
                  size='small'
                  type='number'
                  defaultValue={energy || ''}
                />
                <TextField
                  control={control}
                  name='proteins'
                  label='Белки, г'
                  size='small'
                  type='number'
                  defaultValue={proteins || ''}
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                <TextField
                  control={control}
                  name='fats'
                  label='Жиры, г '
                  size='small'
                  type='number'
                  defaultValue={fats || ''}
                />
                <TextField
                  control={control}
                  name='carbs'
                  label='Углеводы, г'
                  size='small'
                  type='number'
                  defaultValue={carbs || ''}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack sx={{ width: '50%' }} spacing={1} direction='column'>
          <Typography sx={{ fontSize: '14px', lineHeight: '16px' }}>
            Изображение(PNG не более 1000х1000 пикс. и не более 1,5Мб)
          </Typography>
          <Stack
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            direction='row'
            spacing={2}
          >
            <Box sx={{ width: '120px', height: '120px', overflow: 'hidden' }}>
              <Box
                sx={{ objectFit: 'cover', width: '100%', minHeight: '100%' }}
                component='img'
                src={image || placeholder}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Stack direction='column' spacing={{ xs: 1, sm: 2 }}>
                <Button variant='contained' component='label'>
                  Изменить
                  <input
                    name='icon'
                    type='file'
                    hidden
                    onChange={(event: any) => handleAddImage(event)}
                  />
                </Button>
                <Button variant='contained' onClick={handleDeleteImage}>
                  Удалить
                </Button>
              </Stack>
            </Box>
          </Stack>

          <Stack
            sx={{
              '& :first-of-type': {
                margin: '0',
              },
            }}
            direction='column'
            spacing={1}
          >
            {!isNonFood && (
              <Switch
                control={control}
                name='is_warm_up'
                label='Можно греть'
                size='small'
                color='success'
                defaultValue={isWarmUp}
              />
            )}
            <Switch
              control={control}
              name='is_popular'
              label='Показывать в популярных'
              size='small'
              color='success'
              defaultValue={isPopular}
            />
            <Switch
              control={control}
              name='is_ingredient'
              label='Может быть ингредиентом'
              size='small'
              color='success'
              defaultValue={isIngredient}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default memo(GroupForm)
