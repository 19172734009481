import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectStatData = (state: RootState) => state.stat
const selectRevenueChart = (state: RootState) => state.stat.revenueChartData
/**
 * Returns chart data
 */
export const selectRevenueChartData = createSelector([selectRevenueChart], (revenueChart) => {
  return {
    dayChart: {
      labels: revenueChart.day_chart.labels.map((item) => item.toString()),
      values: revenueChart.day_chart.values,
    },
    weekChart: revenueChart.week_chart,
    monthChart: {
      labels: revenueChart.month_chart.labels.map((item) => item.toString()),
      values: revenueChart.month_chart.values,
    },
    yearChart: revenueChart.year_chart,
  }
})
/**
 * Returns metrics data
 */
export const selectMetricsData = createSelector([selectStatData], (statData) => {
  const { revenue, order_count } = statData.metricsData

  const precent = (a: number, b: number) => {
    if (a === b) {
      return '0%'
    }
    if (a === 0) {
      return (100).toFixed(1) + '%'
    }
    return ((100 * (b - a)) / a).toFixed(1) + '%'
  }

  const data = [
    {
      id: 1,
      title: 'Выручка',
      stat: revenue.current,
      precent: precent(revenue.previous, revenue.current),
      trend:
        revenue.current > revenue.previous
          ? 'up'
          : revenue.current < revenue.previous
          ? 'down'
          : revenue.current === revenue.previous && 'neutral',
    },
    {
      id: 2,
      title: 'Кол-во покупок',
      stat: order_count.current,
      precent: precent(order_count.previous, order_count.current),
      trend:
        order_count.current > order_count.previous
          ? 'up'
          : order_count.current < order_count.previous
          ? 'down'
          : order_count.current === order_count.previous && 'neutral',
    },
  ] as {
    id: number
    title: string
    stat: number
    precent: string
    trend: 'up' | 'down' | 'neutral'
  }[]

  return data
})
/**
 * Returns products data
 */
export const selectProductData = createSelector(
  [selectStatData],
  (statData) => statData.productData,
)
/**
 * Returns marketplace data
 */
export const selectMarketplaceData = createSelector(
  [selectStatData],
  (statData) => statData.marketplaceData,
)
