import { useEffect, useState, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from 'store/hooks/useAppSelector'
import {
  getMarketplace,
  getMetrics,
  getProduct,
  getRevenueChart,
} from 'store/reducers/StatSlice/ActionCreators'
import { selectMarketplaceData, selectProductData } from 'store/selectors/statSelectors'
import { useChart } from 'hooks/statistics'

const useStat = () => {
  const dispatch = useAppDispatch()
  const productData = useAppSelector(selectProductData)
  const marketplaceData = useAppSelector(selectMarketplaceData)
  const { chartButtons, active, options, chartData } = useChart()
  const [rangeLabel, setRangeLabel] = useState<string>('')

  const to = useMemo(() => {
    return new Date().toISOString()
  }, [active])

  const from = useMemo(() => {
    const date = new Date()
    switch (active) {
      case 'day':
        return new Date(date.setDate(date.getDate() - 1)).toISOString()
      case 'week':
        return new Date(new Date().setDate(date.getDate() - 7)).toISOString()
      case 'month':
        return new Date(new Date().setDate(date.getDate() - 30)).toISOString()
      case 'year':
        return new Date(new Date().setDate(date.getDate() - 365)).toISOString()
      default:
        return ''
    }
  }, [active])

  // const [from, setFrom] = useState<string>('')
  // const [to, setTo] = useState<string>('')

  // useEffect(() => {
  //   const date = new Date()
  //   if (active === 'day') {
  //     const firstday = new Date(date.setHours(3, 0, 0, 0)).toISOString()
  //     const lastday = new Date(date.setDate(date.getDate() + 1)).toISOString()
  //     setFrom(firstday)
  //     setTo(lastday)
  //   }
  //   if (active === 'week') {
  //     const firstday = new Date(date.setDate(date.getDate() - date.getDay() + 1)).toISOString()
  //     const lastday = new Date(date.setDate(date.getDate() - date.getDay() + 7)).toISOString()
  //     setFrom(firstday)
  //     setTo(lastday)
  //   }
  //   if (active === 'month') {
  //     const firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
  //     const lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString()
  //     setFrom(firstMonthDay)
  //     setTo(lastMonthDay)
  //   }
  //   if (active === 'year') {
  //     const firstYearDay = new Date(date.getFullYear(), 0, 1).toISOString()
  //     const lastYearDay = new Date(date.getFullYear(), 11, 31).toISOString()
  //     setFrom(firstYearDay)
  //     setTo(lastYearDay)
  //   }
  // }, [active])

  useEffect(() => {
    dispatch(getRevenueChart())
    dispatch(getMarketplace())
    dispatch(getProduct())
  }, [])

  useEffect(() => {
    if (from && to) {
      dispatch(getMetrics({ from, to }))
    }
  }, [from, to])

  useEffect(() => {
    if (active === 'day') {
      setRangeLabel('за последние 24 часа')
    }
    if (active === 'week') {
      setRangeLabel('за последние 7 дней')
    }
    if (active === 'month') {
      setRangeLabel('за последние 30 дней')
    }
    if (active === 'year') {
      setRangeLabel('за последний год')
    }
  }, [active])

  return { productData, marketplaceData, chartButtons, active, options, chartData, rangeLabel }
}

export default useStat
