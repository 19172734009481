import React, { FC } from 'react'
import { TableCell, TableHead as MUITableHead, TableRow } from '@mui/material'

interface TableHeadProps {
  headCells: {
    id: number
    label: string
  }[]
}

const TableHead: FC<TableHeadProps> = ({ headCells }) => {
  return (
    <MUITableHead>
      <TableRow>
        {headCells.map((item) => (
          <TableCell
            sx={{ backgroundColor: '#F7F9FC', padding: '8px 20px', border: '0' }}
            align='left'
            key={item.id}
          >
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  )
}

export default TableHead
