import { useCallback, useEffect, useState, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import {
  createCell,
  deleteCell,
  getCells,
  updateCell,
} from 'store/reducers/CellSlice/ActionCreators'
import { CellFormType } from 'types/FormTypes'
import { useSnackbar } from 'notistack'
import { getProduct } from 'store/reducers/ProductsSlice/ActionCreators'
import { selectProduct } from 'store/selectors/productsSelector'
import { selectCellsErrors } from 'store/selectors/cellsSelectors'
import { cellSlice } from 'store/reducers/CellSlice/CellSlice'
import { useParams } from 'react-router-dom'

const useCellModal = (
  onClose: () => void,
  modalType: 'change' | 'create',
  cellId?: number,
  productId?: number,
) => {
  const dispatch = useAppDispatch()
  const { terminalId } = useParams()
  const methods = useForm<CellFormType>({
    defaultValues: {
      containers: [],
    },
  })
  const { handleSubmit, reset, setValue, watch } = methods
  const productSelectedValue = watch('product')
  const { enqueueSnackbar } = useSnackbar()
  const productData = useAppSelector(selectProduct)
  const cellErrors = useAppSelector(selectCellsErrors)
  const { clearErrors } = cellSlice.actions
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [createProductModal, setCreateProductModal] = useState<boolean>(false)
  const [changeProductModal, setChangeProductModal] = useState<boolean>(false)

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/cells/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/cells/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const handleCreateProductModalOpen = useCallback(() => {
    setCreateProductModal(true)
  }, [createProductModal])

  const handleCreateProductModalClose = useCallback(() => {
    setCreateProductModal(false)
  }, [createProductModal])

  const handleChangeProductModalOpen = useCallback(() => {
    setChangeProductModal(true)
  }, [changeProductModal])

  const handleChangeProductModalClose = useCallback(() => {
    setChangeProductModal(false)
  }, [changeProductModal])

  const handleDelete = () => {
    dispatch(deleteCell({ id: cellId }))
      .unwrap()
      .then(() => {
        dispatch(getCells({ page: page, page_size: pageSize, terminal: terminalId }))
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
        handleConfirmModalClose()
        onClose()
      })
  }

  const onSubmit = (formValues: CellFormType) => {
    if (modalType === 'change') {
      dispatch(updateCell({ id: cellId, formValues }))
        .unwrap()
        .then((res: any) => {
          if (res.id) {
            dispatch(getCells({ page: page, page_size: pageSize, terminal: terminalId }))
            enqueueSnackbar('Успешно сохранено', { variant: 'success' })
            onClose()
          }
        })
    }
    if (modalType === 'create') {
      dispatch(createCell({ formValues }))
        .unwrap()
        .then((res) => {
          if (res.id) {
            dispatch(getCells({ page: page, page_size: pageSize, terminal: terminalId }))
            reset()
            enqueueSnackbar('Успешно добавлено', { variant: 'success' })
            onClose()
          }
        })
    }
  }

  useEffect(() => {
    if (cellErrors.length > 0) {
      cellErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [cellErrors])

  useEffect(() => {
    if (productSelectedValue || productId) {
      dispatch(getProduct({ id: productSelectedValue || productId }))
    }
  }, [productSelectedValue])

  useEffect(() => {
    if (terminalId) {
      setValue('terminal', Number(terminalId))
    }
  }, [terminalId])

  return {
    methods,
    handleSubmit,
    productData,
    handleConfirmModalOpen,
    handleConfirmModalClose,
    handleCreateProductModalOpen,
    handleCreateProductModalClose,
    handleChangeProductModalOpen,
    handleChangeProductModalClose,
    handleDelete,
    onSubmit,
    confirmModal,
    createProductModal,
    changeProductModal,
  }
}

export default useCellModal
