import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getGroup, getGroups } from 'store/reducers/GroupsSlice/ActionCreators'
import { getMarketplace, getMarketplaceList } from 'store/reducers/MarketplaceSlice/ActionCreators'
import {
  deleteTerminal,
  getTerminal,
  getTerminalModels,
  getTerminals,
  getTerminalTypes,
  partialUpdateTerminal,
} from 'store/reducers/TerminalsSlice/ActionCreators'
import {
  selectTerminal,
  selectTerminalErrors,
  selectTerminalIsLoading,
  selectTerminalTypes,
} from 'store/selectors/terminalsSelector'
import { TerminalFormTypes } from 'types/FormTypes'
import { Terminal } from 'types/Terminals'
import { terminalsSlice } from 'store/reducers/TerminalsSlice/TerminalsSlice'

const useTerminal = () => {
  const {
    id,
    address,
    secret_key,
    description,
    external_id,
    marketplace,
    group,
    code,
    franke_properties,
    type,
    model,
  } = useAppSelector<Terminal>(selectTerminal)
  const dispatch = useAppDispatch()
  const methods = useForm<TerminalFormTypes>()
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { setValue, watch } = methods
  const marketplaceSelectValue = watch('marketplace')
  const groupSelectValue = watch('group')
  const { terminalId } = useParams()
  const terminalIsLoading = useAppSelector<boolean>(selectTerminalIsLoading)
  const terminalErrors = useAppSelector<string[]>(selectTerminalErrors)
  const types = useAppSelector(selectTerminalTypes)
  const { clearErrors } = terminalsSlice.actions
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [copyTerminalModal, setCopyTerminalModal] = useState<boolean>(false)

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/terminals/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/terminals/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const handleDelete = () => {
    dispatch(deleteTerminal({ id }))
      .unwrap()
      .then(() => {
        dispatch(getTerminals({ page: page, page_size: pageSize }))
        navigate('/admin/terminals')
        enqueueSnackbar('Успешно удалено', { variant: 'success' })
      })
  }

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModal(true)
  }, [confirmModal])

  const handleConfirmModalClose = useCallback(() => {
    setConfirmModal(false)
  }, [confirmModal])

  const handleCopyTerminalModalOpen = useCallback(() => {
    setCopyTerminalModal(true)
  }, [copyTerminalModal])

  const handleCopyTerminalModalClose = useCallback(() => {
    setCopyTerminalModal(false)
  }, [copyTerminalModal])

  const onSubmit = (formValues: TerminalFormTypes) => {
    const formVal = {
      ...formValues,
      type: types.find((type) => type.id === Number(formValues.type)),
    }

    dispatch(partialUpdateTerminal({ id, formValues: formVal }))
      .unwrap()
      .then((res) => {
        if (res.id) {
          dispatch(getTerminals({ page: page, page_size: pageSize }))
          enqueueSnackbar('Успешно сохранено', { variant: 'success' })
        }
      })
  }

  const handleCloseTerminal = () => {
    navigate('/admin/terminals')
  }

  useEffect(() => {
    dispatch(getMarketplaceList({}))
    dispatch(getGroups({}))
    dispatch(getTerminals({}))
    dispatch(getTerminalModels())
    dispatch(getTerminalTypes())
    dispatch(getTerminal({ id: terminalId }))
  }, [])

  useEffect(() => {
    if (!terminalIsLoading) {
      setValue('franke_properties', franke_properties)
      setValue('address', address)
      setValue('description', description)
      setValue('external_id', external_id)
      setValue('marketplace', marketplace)
      setValue('group', group)
      setValue('code', code)
      setValue('type', type?.id.toString())
    }
  }, [
    franke_properties,
    address,
    description,
    external_id,
    marketplace,
    group,
    code,
    type,
    terminalIsLoading,
  ])

  useEffect(() => {
    if (marketplace) {
      dispatch(getMarketplace({ id: marketplaceSelectValue || marketplace }))
    }
  }, [marketplaceSelectValue, marketplace])

  useEffect(() => {
    if (group) {
      dispatch(getGroup({ id: groupSelectValue || group }))
    }
  }, [groupSelectValue, group])

  useEffect(() => {
    if (terminalErrors.length > 0) {
      terminalErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [terminalErrors])

  const tabValue = useMemo(() => {
    return location.pathname
  }, [location])

  const tabs = useMemo(() => {
    if (id) {
      return [
        {
          label: 'Настройки',
          path: `/admin/terminals/${id}`,
        },
        {
          label: 'Товары в продаже',
          path: `/admin/terminals/${id}/cells`,
        },
        {
          label: 'Контейнеры',
          path: `/admin/terminals/${id}/containers`,
        },
      ]
    }
    return []
  }, [id])

  return {
    methods,
    onSubmit,
    tabValue,
    id,
    address,
    secret_key,
    model,
    confirmModal,
    handleDelete,
    handleConfirmModalClose,
    handleConfirmModalOpen,
    handleCloseTerminal,
    copyTerminalModal,
    handleCopyTerminalModalOpen,
    handleCopyTerminalModalClose,
    tabs,
  }
}

export default useTerminal
