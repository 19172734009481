import React, { FC } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { ru } from 'date-fns/locale'

interface DatePickerProps {
  control: Control<FieldValues>
  name: string
  label?: string
  required?: boolean
  size?: 'small' | 'medium'
  defaultValue?: Date | string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
}

const DatePicker: FC<DatePickerProps> = ({
  control,
  name,
  label,
  required,
  size,
  defaultValue,
  minDate,
  maxDate,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue?.toString() || null}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
          <MUIDatePicker
            inputFormat='dd/MM/yyyy'
            value={value}
            label={label}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                sx={{
                  '& .MuiInputLabel-root': {
                    color: '#BDBDBD',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#ffffff',
                  },
                  '& .MuiInputLabel-shrink': {
                    color: '#ffffff',
                    backgroundColor: 'primary.main',
                    padding: '0px 5px',
                    left: '-5px',
                    top: '2px',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '20px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '6px',
                    '& fieldset': {
                      border: '2px solid',
                      borderColor: 'primary.main',
                      '& legend': {
                        width: '38px',
                      },
                    },
                    '&:hover fieldset': {
                      border: '2px solid',
                      borderColor: '#36429A',
                    },
                    '&.Mui-focused fieldset': {
                      border: '2px solid',
                      borderColor: 'primary.main',
                    },
                  },
                }}
                size={size}
                fullWidth
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      )}
      rules={{
        required,
      }}
    />
  )
}

export default DatePicker
