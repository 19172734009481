import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTableSort, usePageOptions, useLabelRows } from 'hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { getTerminals } from 'store/reducers/TerminalsSlice/ActionCreators'
import {
  selectTerminals,
  selectTerminalsCount,
  selectTerminaslIsLoading,
} from 'store/selectors/terminalsSelector'
import { HeadCell, RowData } from 'types/Table'
import { useMediaQuery } from 'react-responsive'

const useTerminals = () => {
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()

  const dispatch = useAppDispatch()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector(selectTerminalsCount)
  const terminals = useAppSelector(selectTerminals)
  const [modal, setModal] = useState<boolean>(false)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const terminalsIsLoading = useAppSelector(selectTerminaslIsLoading)
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false)
  const heightOn1200 = useMediaQuery({ maxWidth: 1200 })
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  // Filter
  const [marketplace, setMarketplace] = useState<string>(
    sessionStorage.getItem('marketplace') || '',
  )
  const [group, setGroup] = useState<string>(sessionStorage.getItem('group') || '')
  const [cleaning, setСleaning] = useState<string>(sessionStorage.getItem('cleaning') || '')
  const [terminalType, setTerminalType] = useState<string>(
    sessionStorage.getItem('terminalType') || '',
  )

  const handleFilterMarketplace = (event: { target: { value: React.SetStateAction<string> } }) => {
    setMarketplace(event.target.value)
    sessionStorage.setItem('marketplace', event.target.value.toString())
  }
  const handleFilterGroup = (event: { target: { value: React.SetStateAction<string> } }) => {
    setGroup(event.target.value)
    sessionStorage.setItem('group', event.target.value.toString())
  }
  const handleFilterCleaning = (event: { target: { value: React.SetStateAction<string> } }) => {
    setСleaning(event.target.value)
    sessionStorage.setItem('cleaning', event.target.value.toString())
  }
  const handleFilterTerminalType = (event: { target: { value: React.SetStateAction<string> } }) => {
    setTerminalType(event.target.value)
    sessionStorage.setItem('terminalType', event.target.value.toString())
  }

  const headCells: HeadCell[] = [
    {
      id: 'address',
      label: 'Название',
      sort: 'address',
    },
    {
      id: 'containers_need_revision',
      label: 'Требует сервиса',
      sort: 'containers_need_revision',
    },
    {
      id: 'model',
      label: 'Модель',
      sort: 'model__name',
    },
  ].filter((item) => {
    if (isHidden600) {
      return item.id === 'address'
    } else {
      return item
    }
  }) as HeadCell[]

  const selectedFilterCount = useMemo(() => {
    return [marketplace, group, cleaning, terminalType].filter((item) => item).length
  }, [marketplace, group, cleaning, terminalType])

  const filterHeight = useMemo(() => {
    if (heightOn1200) {
      return filterIsOpen ? 345 : 215
    }
    return filterIsOpen ? 345 : 275
  }, [filterIsOpen])

  const handleToggleFilter = useCallback(() => {
    setFilterIsOpen(!filterIsOpen)
  }, [filterIsOpen])

  const handleFilterClose = useCallback(() => {
    setFilterIsOpen(false)
  }, [filterIsOpen])

  useEffect(() => {
    setRows(
      terminals.map((item) => {
        return { ...item, modelName: item.model.name }
      }),
    )
  }, [terminals, rowsPerPage, page])

  useEffect(() => {
    dispatch(
      getTerminals({
        page: pageQuery,
        page_size: rowsPerPage,
        marketplace: marketplace || undefined,
        group: group || undefined,
        model__type: terminalType || undefined,
        containers_need_revision: cleaning === 'false' ? '0' : undefined,
        containers_need_revision__gt: cleaning === 'true' ? '0' : undefined,
        ordering,
      }),
    )
  }, [page, rowsPerPage, pageQuery, marketplace, group, terminalType, cleaning, ordering])

  const handleModalOpen = useCallback(() => {
    setModal(true)
  }, [modal])

  const handleModalClose = useCallback(() => {
    setModal(false)
  }, [modal])

  return {
    headCells,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    modal,
    handleModalOpen,
    handleModalClose,
    marketplace,
    handleFilterMarketplace,
    group,
    handleFilterGroup,
    cleaning,
    handleFilterCleaning,
    terminalType,
    handleFilterTerminalType,
    terminalsIsLoading,
    selectedFilterCount,
    filterIsOpen,
    filterHeight,
    handleToggleFilter,
    handleFilterClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useTerminals
