import React from 'react'
import { TextField } from 'components/generic'
import { Box, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'

const LogInForm = () => {
  const { control } = useFormContext()
  return (
    <Stack sx={{ marginBottom: '16px' }} direction='column' spacing={3}>
      <Box>
        <TextField
          control={control}
          name='username'
          label='Имя пользователя'
          size='small'
          required
        />
      </Box>
      <Box>
        <TextField
          control={control}
          name='password'
          label='Пароль'
          size='small'
          type='password'
          required
        />
      </Box>
    </Stack>
  )
}

export default LogInForm
