import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from 'types/User'
import { UserFormTypes } from 'types/FormTypes'
import { http } from 'utils/httpConfig'
import axios from 'axios'

export const getUser = createAsyncThunk(
  'user/get_user',
  async (params: { id: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.get<User>(`/api/admin/user/${id}/`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(`Failed to get user, error${error}`)
    }
  },
)

export const getUsers = createAsyncThunk(
  'user/get_users',
  async (params: { page?: number; page_size?: number; ordering?: string }, thunkAPI) => {
    const { page, page_size, ordering } = params
    try {
      const response = await http.get<User>('/api/admin/user/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get users, ${error}`])
      }
    }
  },
)

export const recoveryPassword = createAsyncThunk(
  'user/recovery_password',
  async (params: { formData: { email: string } }, thunkAPI) => {
    const { formData } = params
    try {
      const response = await http.post<User>('/api/admin/user/recovery_password/', formData)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(`Failed to get recovery password, error${error}`)
    }
  },
)

export const updateUser = createAsyncThunk(
  'users/update_user',
  async (params: { id?: number; formValues: UserFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<User>(`/api/admin/user/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update user, ${error}`])
      }
    }
  },
)

export const partialUpdateUser = createAsyncThunk(
  'users/partial_update_user',
  async (params: { id?: number; formValues: UserFormTypes }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<User>(`/api/admin/user/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to update terminal, ${error}`])
      }
    }
  },
)

export const createUser = createAsyncThunk(
  'users/create_user',
  async (params: { formValues: UserFormTypes }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<User>('/api/admin/user/', formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })
        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create user, ${error}`])
      }
    }
  },
)
