import React, { FC } from 'react'
import { Box, CssBaseline } from '@mui/material'
import line from 'assets/img/line.png'
import { Outlet } from 'react-router-dom'

const AuthLayout: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#1C2A4B',
        position: 'relative',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          left: '0',
          rigth: '0',
          top: '0',
          height: '100%',
        }}
      >
        <Box
          sx={{ objectFit: 'cover', minHeight: '50%', width: '100%' }}
          src={line}
          component='img'
        />
      </Box>
      <Outlet />
    </Box>
  )
}

export default AuthLayout
