import React, { FC, ChangeEventHandler, useMemo } from 'react'
import { Box, LabelDisplayedRowsArgs, TablePagination as MUITablePagination } from '@mui/material'

interface TablePaginationProps {
  rowsPerPageOptions?:
    | (
        | number
        | {
            value: number
            label: string
          }
      )[]
    | undefined
  count: number
  rowsPerPage: number
  page: number
  onPageChange: (event: any, page: number) => void
  onRowsPerPageChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  labelDisplayedRows: ({ from, to, count }: LabelDisplayedRowsArgs) => string
}

const TablePagination: FC<TablePaginationProps> = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  labelDisplayedRows,
}) => {
  const isShow = useMemo(() => {
    return count !== 0
  }, [count])

  return (
    <>
      {isShow && (
        <MUITablePagination
          sx={{
            border: 'none',
            '& .MuiTablePagination-toolbar': {
              paddingLeft: '0px',
            },
            '& .MuiTablePagination-select': {
              marginRight: '0',
            },
            '& .MuiTablePagination-actions': {
              marginLeft: '10px',
            },
            '& .MuiInputBase-root': {
              marginRight: { xs: '0px', sm: '10px' },
            },
          }}
          color='primary'
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          component={Box}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={(paginationInfo) => labelDisplayedRows(paginationInfo)}
        />
      )}
    </>
  )
}

export default TablePagination
