import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectAuth = (state: RootState) => state.auth
/**
 * Returns auth token
 */
export const selectAuthToken = createSelector([selectAuth], (auth) => auth.login.token)
/**
 * Returns auth user ID
 */
export const selectUserId = createSelector([selectAuth], (auth) => auth.login.user_id)
/**
 * Returns auth errors
 */
export const selectAuthErrors = createSelector([selectAuth], (auth) => auth.errors)
/**
 * Returns auth is loading
 */
export const selectAuthIsLoading = createSelector([selectAuth], (auth) => auth.isLoading)
