import React, { FC, memo } from 'react'
import { TableRow as MUITableRow, TableCell, IconButton, Tooltip } from '@mui/material'
import { DeleteForeverOutlined as DeleteIcon } from '@mui/icons-material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectUserData } from 'store/selectors/usersSelectors'
import { User } from 'types/User'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  handleDeleteAdministrator: (id: number) => void
}

const TableRow: FC<TableRowProps> = ({ id, handleDeleteAdministrator }) => {
  const user = useAppSelector<User | undefined>(selectUserData(id))
  const isMobile = useMediaQuery({ maxWidth: 600 })

  return (
    <MUITableRow role='row' tabIndex={-1} component='tr'>
      <TableCell
        sx={{ borderBottom: 'none', padding: '5px 0px 5px 20px' }}
        component='th'
        scope='row'
      >
        {user?.username}
      </TableCell>
      {!isMobile && (
        <TableCell sx={{ borderBottom: 'none', padding: '5px 0px 5px 20px' }} align='left'>
          {user?.email}
        </TableCell>
      )}
      <TableCell sx={{ borderBottom: 'none', padding: '5px 20px' }} align='left'>
        <Tooltip title='Удалить из списка' placement='right'>
          <IconButton color='primary' onClick={() => handleDeleteAdministrator(id)}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </TableCell>
    </MUITableRow>
  )
}

export default memo(TableRow)
