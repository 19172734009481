import React, { FC } from 'react'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { TextField, SelectField, TextArea } from 'components/generic'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectMarketplacesList } from 'store/selectors/marketplaceSelectors'
import { SelectFieldType } from 'types/Other'
import { selectGroupsList } from 'store/selectors/groupsSelectors'
import {
  selectTerminalModelsList,
  selectTerminalTypesList,
  selectModelType,
} from 'store/selectors/terminalsSelector'
import { Add as AddIcon } from '@mui/icons-material'

interface TerminalFormProps {
  handleMarketplaceModalOpen: () => void
}

const TerminalForm: FC<TerminalFormProps> = ({ handleMarketplaceModalOpen }) => {
  const { control, watch } = useFormContext()
  const modelValue = watch('model')
  const marketplacesList = useAppSelector<SelectFieldType[]>(selectMarketplacesList)
  const groupList = useAppSelector<SelectFieldType[]>(selectGroupsList)
  const modelType = useAppSelector(selectModelType(modelValue))
  const modelList = useAppSelector(selectTerminalModelsList)
  const typeList = useAppSelector(selectTerminalTypesList)

  return (
    <Stack direction='column' spacing={2}>
      <TextField
        control={control}
        name='address'
        label='Наименование *'
        size='small'
        defaultValue={''}
        required
      />

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <TextField
          control={control}
          name='external_id'
          label='Внешний идентификатор*'
          size='small'
          defaultValue={''}
          type='number'
        />
        <TextField
          control={control}
          name='code'
          label='Код *'
          size='small'
          defaultValue={''}
          required
          type='number'
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <SelectField
          control={control}
          name='group'
          label='Группа автоматов'
          size='small'
          selectItem={groupList}
          defaultValue={null}
        />
        <Stack sx={{ width: '100%' }} direction='row' spacing={2}>
          <SelectField
            control={control}
            name='marketplace'
            label='Точка продаж *'
            size='small'
            selectItem={marketplacesList}
            defaultValue={null}
          />
          <Tooltip title='Добавить точку продаж' placement='top-start'>
            <IconButton color='primary' onClick={handleMarketplaceModalOpen}>
              <AddIcon color='primary' />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <SelectField
          control={control}
          name='type'
          label='Тип автомата *'
          size='small'
          selectItem={typeList}
          defaultValue={''}
          required
        />
        <SelectField
          control={control}
          name='model'
          label='Модель автомата *'
          size='small'
          selectItem={modelList}
          defaultValue={''}
          required
        />
      </Stack>
      {modelType === 'tcn' && (
        <>
          <Typography sx={{ fontSize: '12px', lineHeight: '14px', marginBottom: '20px' }}>
            Укажите количество контейнеров, например 1-15,21-25,31-35
          </Typography>
          <Stack direction='row' spacing={2}>
            <TextField
              control={control}
              name='tcn_properties.container_code_rule'
              label='Кол-во контейнеров'
              size='small'
              pattern={/^(?:\d+(?:-\d+)?,?\s?)+$/}
            />
            <TextField
              control={control}
              name='tcn_properties.container_size'
              label='Емкость контейнера'
              size='small'
              defaultValue='1'
            />
          </Stack>
        </>
      )}

      <TextArea control={control} name='description' label='Описание' defaultValue={''} />
    </Stack>
  )
}

export default TerminalForm
