import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Cell, CellsData } from 'types/Cells'
import { CellFormType } from 'types/FormTypes'
import { http } from 'utils/httpConfig'

export const getCells = createAsyncThunk(
  'cells/get_cells',
  async (
    params: { page?: number; page_size?: number; ordering?: string; terminal?: number | string },
    thunkAPI,
  ) => {
    const { page, page_size, ordering, terminal } = params
    try {
      const response = await http.get<CellsData>('/api/admin/cell/', {
        params: {
          page,
          page_size: page_size ? page_size : 1000000,
          ordering,
          terminal,
        },
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue([`Failed to get cells data, ${error.message}`])
      } else {
        return thunkAPI.rejectWithValue([`Failed to get cells data, ${error}`])
      }
    }
  },
)

export const updateCell = createAsyncThunk(
  'cells/update_cell',
  async (params: { id?: number; formValues: CellFormType }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.put<Cell>(`/api/admin/cell/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })

        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create cell, ${error}`])
      }
    }
  },
)

export const deleteCell = createAsyncThunk(
  'cells/delete_cell',
  async (params: { id?: number }, thunkAPI) => {
    const { id } = params
    try {
      const response = await http.delete<Cell>(`/api/admin/cell/${id}/`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue([`Failed to delete cell, ${error}`])
    }
  },
)

export const partialUpdateCell = createAsyncThunk(
  'cells/partial_update_cell',
  async (params: { id: number; formValues: CellFormType }, thunkAPI) => {
    const { id, formValues } = params
    try {
      const response = await http.patch<any>(`/api/admin/cell/${id}/`, formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })

        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue([`Failed to create cell, ${error}`])
      }
    }
  },
)

export const createCell = createAsyncThunk(
  'cells/create_cell',
  async (params: { formValues: CellFormType }, thunkAPI) => {
    const { formValues } = params
    try {
      const response = await http.post<Cell>('/api/admin/cell/', formValues)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data
        Object.keys(data)
        const message = Object.keys(data).map((key) => {
          return data[key]
        })

        return thunkAPI.rejectWithValue(message || [error.message])
      } else {
        return thunkAPI.rejectWithValue(`Failed to create cell, ${error}`)
      }
    }
  },
)
