import React, { FC } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectTerminalAddress } from 'store/selectors/terminalsSelector'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  date: string
  time: string
  marketplace: string
  terminal: number
  product: number
  price: number
  quantity: number
}

const TableRow: FC<TableRowProps> = ({
  date,
  time,
  marketplace,
  terminal,
  product,
  price,
  quantity,
}) => {
  // const terminalArrdress = useAppSelector(selectTerminalAddress(terminal))
  const isMobile = useMediaQuery({ maxWidth: 600 })

  return (
    <MUITableRow
      sx={{
        '& .MuiTableCell-root': {
          borderBottom: 'none',
          padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
          '&:first-of-type': {
            padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
          },
        },
        zIndex: '10',
      }}
      hover
      role='row'
      tabIndex={-1}
      component='tr'
    >
      <TableCell align='left' component='th' scope='row'>
        {date}
      </TableCell>
      <TableCell align='left' component='th' scope='row'>
        {time}
      </TableCell>
      {/* <TableCell align='left' component='th'>
        {marketplace}
      </TableCell> */}
      {!isMobile && (
        <TableCell align='left' component='th'>
          {terminal}
        </TableCell>
      )}

      <TableCell align='left' component='th' scope='row'>
        {product}
      </TableCell>
      {!isMobile && (
        <>
          <TableCell align='left' component='th'>
            {price}
          </TableCell>
          <TableCell align='left' component='th'>
            {quantity}
          </TableCell>
        </>
      )}
    </MUITableRow>
  )
}

export default TableRow
