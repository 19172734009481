import React, { FC, ReactNode } from 'react'
import { Stack, Breadcrumbs, Link, Typography } from '@mui/material'
import { HomeOutlined as HomeOutlinedIcon } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'

interface ContentProps {
  title: string
  isLoading?: boolean
  children?: ReactNode
}

const Content: FC<ContentProps> = ({ title, isLoading, children }) => {
  return (
    <Stack
      sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, sm: 10 }}
    >
      <Typography
        sx={{
          fontSize: '20px',
          lineHeight: '23px',
          fontWeight: '600',
          color: '#ffffff',
        }}
      >
        {title}
      </Typography>
      {isLoading ? (
        <></>
      ) : (
        <Breadcrumbs sx={{ color: '#ffffff' }} separator='•' aria-label='breadcrumb'>
          <Link
            underline='hover'
            sx={{ display: 'flex', alignItems: 'center', color: '#ffffff' }}
            component={NavLink}
            to='/admin'
          >
            <HomeOutlinedIcon fontSize='medium' />
          </Link>
          {children}
        </Breadcrumbs>
      )}
    </Stack>
  )
}

export default Content
