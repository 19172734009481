import React, { FC, memo } from 'react'
import { Box, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/terminal/Containers'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { ContainerModal } from 'components/modals'
import { useContainers } from 'hooks/containers'
import { RowData } from 'types/Table'
import { TerminalFooter } from 'components/terminal'
import { useTerminal } from 'hooks/terminals'

const Containers: FC = () => {
  const {
    methods,
    onSubmit,
    address,
    handleConfirmModalOpen,
    handleCloseTerminal,
    handleCopyTerminalModalOpen,
  } = useTerminal()
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    isContainersLoading,
    headCells,
    containerModal,
    handleContainerModalOpen,
    handleContainerModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useContainers()

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '90%',
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar
          title={address}
          buttonLabel='+ Добавить контейнер'
          handleModalOpen={handleContainerModalOpen}
          isLoadingData={isContainersLoading}
        />
        <TableContainer
          sx={{
            height: { xs: 'auto', sm: 'calc(100vh - 320px)' },
          }}
        >
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  key={row.id}
                  id={row.id}
                  code={row.code}
                  productId={row.product}
                  type={row.type}
                  unit={row.unit}
                  isActive={Boolean(row.is_active)}
                  needRevision={row.need_revision}
                  title={address}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <TerminalFooter
          handleConfirmModalOpen={handleConfirmModalOpen}
          handleCloseTerminal={handleCloseTerminal}
          methods={methods}
          onSubmit={onSubmit}
          handleCopyTerminalModalOpen={handleCopyTerminalModalOpen}
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </TerminalFooter>
      </Box>
      <ContainerModal
        isOpen={containerModal}
        onClose={handleContainerModalClose}
        modalType='create'
      />
    </>
  )
}

export default memo(Containers)
