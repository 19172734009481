import React, { FC } from 'react'
import { Box, Button, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/product/Ingredients'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { IngredientModal } from 'components/modals'
import { ProductFormTypes } from 'types/FormTypes'
import { UseFormReturn } from 'react-hook-form'
import { useIngredients } from 'hooks/ingredients'
import { RowData } from 'types/Table'

interface IngredientsProps {
  id: number
  name: string
  methods: UseFormReturn<ProductFormTypes, any>
  onSubmit: (formValues: ProductFormTypes) => void
  handleConfirmModalOpen: () => void
  handleBackRoute: () => void
}

const Ingredients: FC<IngredientsProps> = ({
  id,
  name,
  methods,
  onSubmit,
  handleConfirmModalOpen,
  handleBackRoute,
}) => {
  const {
    order,
    orderBy,
    rows,
    page,
    count,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    ingredientsIsLoading,
    unit,
    rowsPerPageOptions,
    labelDisplayedRows,
    headCells,
    ingredientModal,
    handleIngredientModalOpen,
    handleIngredientModalClose,
  } = useIngredients(id)

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar
          title={name}
          buttonLabel='+ Добавить ингредиент'
          handleModalOpen={handleIngredientModalOpen}
          isLoadingData={ingredientsIsLoading}
        />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 320px)', minHeight: 'calc(100vh - 320px)' }}>
          <MUITable stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  key={row.id}
                  id={row.id}
                  product={row.product}
                  ingredient={row.ingredient}
                  consumption={row.consumption}
                  unit={row.unit}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            padding: '10px 24px',
            borderTop: '1px solid',
            alignItems: 'center',
            borderColor: 'divider',
            justifyContent: 'space-between',
            minHeight: '73px',
          }}
          direction='row'
          spacing={2}
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
          <Stack direction='row' spacing={2}>
            <Button
              sx={{ color: 'error.main' }}
              variant='outlined'
              onClick={handleConfirmModalOpen}
              color='error'
            >
              Удалить
            </Button>
            <Button variant='outlined' onClick={handleBackRoute}>
              Закрыть
            </Button>
            <Button variant='contained' onClick={methods.handleSubmit(onSubmit)}>
              Сохранить
            </Button>
          </Stack>
        </Stack>
      </Box>
      <IngredientModal
        isOpen={ingredientModal}
        onClose={handleIngredientModalClose}
        modalType='create'
        product={id}
        unit={unit}
      />
    </>
  )
}

export default Ingredients
