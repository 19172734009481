import { useEffect, useMemo, useState, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { useForm } from 'react-hook-form'
import { TerminalFormTypes } from 'types/FormTypes'
import {
  createTerminal,
  getTerminalModels,
  getTerminals,
  getTerminalTypes,
} from 'store/reducers/TerminalsSlice/ActionCreators'
import {
  selectTerminalErrors,
  selectTerminalModels,
  selectTerminalTypes,
} from 'store/selectors/terminalsSelector'
import { getMarketplaceList } from 'store/reducers/MarketplaceSlice/ActionCreators'
import { getGroups } from 'store/reducers/GroupsSlice/ActionCreators'
import { useSnackbar } from 'notistack'
import { terminalsSlice } from 'store/reducers/TerminalsSlice/TerminalsSlice'

const useTerminalModal = (onClose: () => void) => {
  const dispatch = useAppDispatch()
  const methods = useForm<TerminalFormTypes>()
  const models = useAppSelector(selectTerminalModels)
  const types = useAppSelector(selectTerminalTypes)
  const terminalErrors = useAppSelector(selectTerminalErrors)
  const { enqueueSnackbar } = useSnackbar()
  const { clearErrors } = terminalsSlice.actions
  const [marketplaceModal, setMarketplaceModal] = useState<boolean>(false)

  const handleMarketplaceModalOpen = useCallback(() => {
    setMarketplaceModal(true)
  }, [marketplaceModal])

  const handleMarketplaceModalClose = useCallback(() => {
    setMarketplaceModal(false)
  }, [marketplaceModal])

  const page = useMemo(() => {
    const pageQuery = sessionStorage.getItem('/admin/terminals/PageQuery')
    return Number(pageQuery) || undefined
  }, [])

  const pageSize = useMemo(() => {
    const rowsPerPage = sessionStorage.getItem('/admin/terminals/PageSize')
    return Number(rowsPerPage) || undefined
  }, [])

  const onSubmit = (formValues: TerminalFormTypes) => {
    const formVal = {
      ...formValues,
      model: models.find((model) => model.id === Number(formValues.model)),
      type: types.find((type) => type.id === Number(formValues.type)),
    }

    dispatch(createTerminal({ formValues: formVal }))
      .unwrap()
      .then((res) => {
        if (res.id) {
          onClose()
          dispatch(getTerminals({ page: page, page_size: pageSize }))
          enqueueSnackbar('Teрминал успешно создан', { variant: 'success' })
        }
      })
  }

  useEffect(() => {
    dispatch(getMarketplaceList({}))
    dispatch(getGroups({}))
    dispatch(getTerminalModels())
    dispatch(getTerminalTypes())
  }, [])

  useEffect(() => {
    if (terminalErrors.length > 0) {
      terminalErrors.forEach((item: string) => enqueueSnackbar(item, { variant: 'warning' }))
      dispatch(clearErrors())
    }
  }, [terminalErrors])

  return {
    methods,
    onSubmit,
    marketplaceModal,
    handleMarketplaceModalOpen,
    handleMarketplaceModalClose,
  }
}

export default useTerminalModal
