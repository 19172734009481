import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContainerBatch, ContainerBatchData } from 'types/ContainerBatch'
import {
  createContainerBatch,
  deleteContainerBatch,
  getContainerBatches,
  partialUpdateContainerBatch,
  updateContainerBatch,
} from './ActionCreators'

interface ContainerBatchState {
  containerBatchData: {
    count: number
    results: ContainerBatch[]
  }
  isLoading: boolean
  error: string
}

const initialState: ContainerBatchState = {
  containerBatchData: {
    count: 0,
    results: [],
  },
  isLoading: false,
  error: '',
}

export const containerBatchSlice = createSlice({
  name: 'container-batch',
  initialState,
  reducers: {},
  extraReducers: {
    [getContainerBatches.fulfilled.type]: (state, action: PayloadAction<ContainerBatchData>) => {
      state.isLoading = false
      state.error = ''
      state.containerBatchData.count = action.payload.count
      state.containerBatchData.results = action.payload.results
    },
    [getContainerBatches.pending.type]: (state) => {
      state.isLoading = true
    },
    [getContainerBatches.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [updateContainerBatch.fulfilled.type]: (state, action: PayloadAction<ContainerBatch>) => {
      state.isLoading = false
      state.error = ''
      state.containerBatchData.results = state.containerBatchData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [updateContainerBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateContainerBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [deleteContainerBatch.fulfilled.type]: (state, action: AnyAction) => {
      state.isLoading = false
      state.error = ''
      state.containerBatchData.results = state.containerBatchData.results.filter(
        (item) => item.id !== action.meta.arg.id,
      )
    },
    [deleteContainerBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteContainerBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [partialUpdateContainerBatch.fulfilled.type]: (
      state,
      action: PayloadAction<ContainerBatch>,
    ) => {
      state.isLoading = false
      state.error = ''
      state.containerBatchData.results = state.containerBatchData.results.map((item) =>
        item.id !== action.payload.id ? item : action.payload,
      )
    },
    [partialUpdateContainerBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [partialUpdateContainerBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createContainerBatch.fulfilled.type]: (state, action: PayloadAction<ContainerBatch>) => {
      state.isLoading = false
      state.error = ''
      state.containerBatchData.results.push(action.payload)
    },
    [createContainerBatch.pending.type]: (state) => {
      state.isLoading = true
    },
    [createContainerBatch.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default containerBatchSlice.reducer
