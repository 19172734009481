import React, { FC, memo } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { TerminalForm } from 'components/modals/TerminalModal'
import { useTerminalModal } from 'hooks/terminals'
import { MarketplaceModal } from 'components/modals'

interface TerminalModalProps {
  isOpen: boolean
  onClose: () => void
}

const TerminalModal: FC<TerminalModalProps> = ({ isOpen, onClose }) => {
  const {
    methods,
    onSubmit,
    marketplaceModal,
    handleMarketplaceModalOpen,
    handleMarketplaceModalClose,
  } = useTerminalModal(onClose)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '600px',
          width: '100%',
          overflowY: 'auto',
          margin: '0px',
        },
      }}
    >
      <Stack sx={{ padding: { xs: '20px 20px', sm: '20px 35px' } }} direction='column' spacing={3}>
        <Typography sx={{ fontSize: '18px', lineHeight: '21px' }}>Создать терминал</Typography>
        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: { xs: '20px', sm: '30px' } }}>
              <TerminalForm handleMarketplaceModalOpen={handleMarketplaceModalOpen} />
            </Box>
            <Stack sx={{ justifyContent: 'space-between' }} direction='row' spacing={2}>
              <Button sx={{ flexGrow: '1' }} variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
      <MarketplaceModal
        isOpen={marketplaceModal}
        onClose={handleMarketplaceModalClose}
        type='create'
      />
    </Dialog>
  )
}

export default memo(TerminalModal)
