import React, { FC, useEffect } from 'react'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { RegistratinForm } from 'components/registration'
import { createRegisterRequest } from 'store/reducers/RegisterSlice/ActionCreators'
import { Logo } from 'components/icons'
import {
  selectRegistrationErrors,
  selectRegistrationIsLoading,
  selectRequestResponse,
} from 'store/selectors/registrationSelectors'
import { registerSlice } from 'store/reducers/RegisterSlice/RegisterSlice'
import { useSnackbar } from 'notistack'
import { RegistrationFormTypes } from 'types/FormTypes'

const Registration: FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<RegistrationFormTypes>()
  const { clearRequest } = registerSlice.actions
  const registrationErrors = useAppSelector(selectRegistrationErrors)
  const isRequest = useAppSelector(selectRequestResponse)
  const isLoading = useAppSelector(selectRegistrationIsLoading)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (formData: RegistrationFormTypes) => {
    dispatch(createRegisterRequest({ formData }))
  }

  useEffect(() => {
    if (registrationErrors.length > 0) {
      registrationErrors.map((item) => enqueueSnackbar(item, { variant: 'warning' }))
    }
  }, [registrationErrors])

  useEffect(() => {
    if (isRequest) {
      enqueueSnackbar('Заявка успешно отправлена', { variant: 'success' })
      navigate('/')
      dispatch(clearRequest())
    }
  }, [isRequest])

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          width: '420px',
          padding: '40px 20px 20px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
          zIndex: '10',
          margin: '25px',
        }}
        component='form'
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Logo sx={{ width: '166px', height: '43px', marginBottom: '50px' }} />

        <Typography
          sx={{ fontSize: '26px', lineHeight: '26px', fontWeight: '700', marginBottom: '20px' }}
        >
          Заявка на регистрацию
        </Typography>

        <Box sx={{ width: '100%' }}>
          <RegistratinForm />
        </Box>
        <Box sx={{ marginBottom: '44px' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button type='submit' variant='contained'>
              Отправить
            </Button>
          )}
        </Box>

        <Stack direction='row' spacing={3}>
          <Typography
            sx={{ color: 'text.primary', textDecoration: 'none' }}
            component={NavLink}
            to='/'
            variant='body1'
          >
            Войти
          </Typography>
          <Typography
            sx={{
              alignSelf: 'flex-end',
              marginBottom: '27px',
              color: 'text.primary',
              textDecoration: 'none',
            }}
            variant='body1'
            component={NavLink}
            to='/recovery_password'
          >
            Восстановление пароля
          </Typography>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default Registration
