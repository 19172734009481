import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableHead, Toolbar, TableRow } from 'components/modals/MarketplaceModal/Table'
import { AdminListModal } from 'components/modals'
import { useFormContext } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'

interface TableProps {
  administrators: number[]
}

const Table: FC<TableProps> = ({ administrators }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const [addUserModal, setAddUserModal] = useState<boolean>(false)
  const [userData, setUserData] = useState<number[]>(administrators)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const headCells = [
    {
      id: 1,
      label: 'Имя',
    },
    {
      id: 2,
      label: 'Эл. почта',
    },
    {
      id: 3,
      label: '',
    },
  ].filter((item) => {
    if (isMobile) {
      return item.id !== 2
    } else {
      return item
    }
  })

  useEffect(() => {
    if (userData.length > 0) {
      setValue('administrators', userData, { shouldValidate: true, shouldDirty: true })
    }
    if (userData.length <= 0) {
      setValue('administrators', userData, { shouldValidate: false, shouldDirty: false })
    }
  }, [userData.length])

  const handleAddUserModalOpen = useCallback(() => {
    setAddUserModal(true)
  }, [addUserModal])

  const handleAddUserModalClose = useCallback(() => {
    setAddUserModal(false)
  }, [addUserModal])

  const handleDeleteAdministrator = (id: number) => {
    setUserData(userData.filter((userId) => userId !== id))
  }

  const handleAddAdministrator = (selectedUsers: number[]) => {
    setUserData([...userData, ...selectedUsers])
    handleAddUserModalClose()
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#EDEDED',
          borderRadius: '10px',
          overflow: 'hidden',
          width: '100%',
          height: { xs: '230px', sm: '370px' },
          border: '2px solid',
          borderColor: errors.administrators ? 'error.main' : 'transparent',
        }}
      >
        <Toolbar
          title='Администраторы *'
          buttonLabel='+ Добавить'
          handleModalOpen={handleAddUserModalOpen}
        />
        <TableContainer sx={{ maxHeight: { xs: '187px', sm: '306px' } }}>
          <MUITable size='small' stickyHeader>
            <TableHead headCells={headCells} />
            <TableBody>
              {userData.map((row) => (
                <TableRow
                  key={row}
                  id={row}
                  handleDeleteAdministrator={handleDeleteAdministrator}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Box>
      <AdminListModal
        isOpen={addUserModal}
        onClose={handleAddUserModalClose}
        handleAddAdministrator={handleAddAdministrator}
        administrators={userData}
      />
    </>
  )
}

export default Table
