import { UNIT } from 'constants/Labels'

const useUnitLabel = (unit: string) => {
  switch (unit) {
    case 'gr':
      return UNIT.UNIT_GRAM
    case 'ml':
      return UNIT.UNIT_MILLILITRE
    default:
      return UNIT.UNIT_PIECE
  }
}

export default useUnitLabel
