import React, { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Marker, useMap } from 'react-leaflet'

interface GetCoordinatesProps {
  coordinates?: number[]
}

const GetCoordinates: FC<GetCoordinatesProps> = ({ coordinates }) => {
  const { setValue } = useFormContext()
  const [lat, setLat] = useState<number>(coordinates ? coordinates[1] : 0)
  const [lng, setLng] = useState<number>(coordinates ? coordinates[0] : 0)
  const map = useMap()

  const slice = (coord: number) => {
    return Math.floor(coord * 1000000) / 1000000
  }

  useEffect(() => {
    if (!map) return

    map.on('click', (e) => {
      setValue('position.type', 'point')
      setValue('position.coordinates', [slice(e.latlng.lng), slice(e.latlng.lat)])
      setLat(e.latlng.lat)
      setLng(e.latlng.lng)
    })
  }, [map])

  if (!lat || !lng) {
    return null
  }

  return <Marker position={[lat, lng]} />
}

export default GetCoordinates
