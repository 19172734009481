import { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { HeadCell, RowData } from 'types/Table'
import { useLabelRows, usePageOptions, useTableSort } from 'hooks'
import { Container } from 'types/Containers'
import {
  selectContainers,
  selectContainersCount,
  selectContainersDataIsLoading,
} from 'store/selectors/containerSelectors'
import { getContainers } from 'store/reducers/ContainersSlice/ActionCreators'
import { getProducts } from 'store/reducers/ProductsSlice/ActionCreators'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

const useContainers = () => {
  const dispatch = useAppDispatch()
  const { terminalId } = useParams()
  const {
    ordering,
    order,
    orderBy,
    page,
    pageQuery,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableSort()
  const [rows, setRows] = useState<RowData[] | any>([])
  const count = useAppSelector<number>(selectContainersCount)
  const containers = useAppSelector<Container[]>(selectContainers)
  const [containerModal, setContainerModal] = useState<boolean>(false)
  const isContainersLoading = useAppSelector<boolean>(selectContainersDataIsLoading)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const isHidden1000 = useMediaQuery({ maxWidth: 1000 })
  const rowsPerPageOptions = usePageOptions(count)
  const labelDisplayedRows = useLabelRows(isHidden600)

  const headCells: HeadCell[] = [
    {
      id: 'code',
      label: 'Код',
      sort: 'code',
    },
    {
      id: 'product',
      label: 'Товар',
      sort: 'product',
    },
    {
      id: 'type',
      label: 'Тип',
      sort: 'type',
    },
    {
      id: 'unit',
      label: 'Ед.изм.',
      sort: 'unit',
    },
    {
      id: 'need_revision',
      label: 'Сервис',
      sort: 'need_revision',
    },
    {
      id: 'is_active',
      label: 'Активен',
      sort: 'is_active',
    },
  ]
    .filter((item) =>
      isHidden1000
        ? item.id !== 'is_active' && item.id !== 'need_revision' && item.id !== 'unit'
        : item,
    )
    .filter((item) => (isHidden600 ? item.id !== 'type' : item)) as HeadCell[]

  useEffect(() => {
    setRows(
      containers.map((item) => {
        return { ...item }
      }),
    )
  }, [containers, rowsPerPage, page])

  useEffect(() => {
    if (terminalId) {
      dispatch(
        getContainers({ page: pageQuery, page_size: rowsPerPage, terminal: terminalId, ordering }),
      )
    }
  }, [page, rowsPerPage, pageQuery, ordering, terminalId])

  useEffect(() => {
    dispatch(getProducts({}))
  }, [])

  const handleContainerModalOpen = useCallback(() => {
    setContainerModal(true)
  }, [containerModal])

  const handleContainerModalClose = useCallback(() => {
    setContainerModal(false)
  }, [containerModal])

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    isContainersLoading,
    headCells,
    containerModal,
    handleContainerModalOpen,
    handleContainerModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  }
}

export default useContainers
