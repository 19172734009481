import React, { FC, memo } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'hooks'

interface PrivateRouteProps {
  children: JSX.Element
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { isAuth } = useAuth()

  return isAuth ? children : <Navigate to='/' replace />
}

export default memo(PrivateRoute)
