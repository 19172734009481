import React, { FC, useState, useCallback } from 'react'
import { TableCell, TableRow as MUITableRow } from '@mui/material'
import { useContainerTypeLabel, useUnitLabel } from 'hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks/useAppSelector'
import { selectProductName } from 'store/selectors/productsSelector'
import { TableSwitch } from 'components/generic'
import { partialUpdateContainer } from 'store/reducers/ContainersSlice/ActionCreators'
import { ContainerModal } from 'components/modals'
import { useMediaQuery } from 'react-responsive'

interface TableRowProps {
  id: number
  code: number
  productId: number
  type: string
  unit: string
  isActive: boolean
  needRevision: boolean
  title?: string
}

const TableRow: FC<TableRowProps> = ({
  id,
  code,
  productId,
  type,
  unit,
  isActive,
  needRevision,
  title,
}) => {
  const dispatch = useAppDispatch()
  const containerTypeLabel = useContainerTypeLabel(type)
  const unitLabel = useUnitLabel(unit)
  const productLabel = useAppSelector(selectProductName(productId))
  const [isChecked, setIsChecked] = useState<boolean>(isActive)
  const [containerModal, setContainerModal] = useState<boolean>(false)
  const isHidden600 = useMediaQuery({ maxWidth: 600 })
  const isHidden1000 = useMediaQuery({ maxWidth: 1000 })

  const handleSwitchChange = () => {
    dispatch(partialUpdateContainer({ id, formValues: { is_active: !isChecked } }))
    setIsChecked(!isChecked)
  }

  const handleContainerModalOpen = useCallback(() => {
    setContainerModal(true)
  }, [containerModal])

  const handleContainerModalClose = useCallback(() => {
    setContainerModal(false)
  }, [containerModal])

  return (
    <>
      <MUITableRow
        sx={{
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            color: needRevision ? 'error.main' : 'text.primary',
            borderBottom: 'none',
            padding: { xs: '12px 20px', sm: '12px 0px 12px 20px' },
            '&:first-of-type': {
              padding: { xs: '12px 20px', sm: '12px 0px 12px 35px' },
            },
          },
        }}
        hover
        role='row'
        tabIndex={-1}
        component='tr'
        onClick={handleContainerModalOpen}
      >
        <TableCell component='th' scope='row'>
          {code}
        </TableCell>
        <TableCell component='th' scope='row'>
          {productLabel}
        </TableCell>
        {!isHidden600 && (
          <TableCell component='th' scope='row'>
            {containerTypeLabel}
          </TableCell>
        )}
        {!isHidden1000 && (
          <>
            <TableCell component='th' scope='row'>
              {unitLabel}
            </TableCell>
            <TableCell component='th' scope='row'>
              {needRevision ? 'Да' : 'Нет'}
            </TableCell>
            <TableCell component='th' scope='row'>
              <TableSwitch isCheck={isChecked} handleChange={handleSwitchChange} />
            </TableCell>
          </>
        )}
      </MUITableRow>
      <ContainerModal
        isOpen={containerModal}
        onClose={handleContainerModalClose}
        modalType='change'
        productId={productId}
        id={id}
        title={title}
      />
    </>
  )
}

export default TableRow
