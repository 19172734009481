import React, { FC } from 'react'
import { Toolbar as MUIToolbar, Typography, Button } from '@mui/material'

interface ToolbarProps {
  title: string
  buttonLabel: string
  handleModalOpen: () => void
}

const Toolbar: FC<ToolbarProps> = ({ title, buttonLabel, handleModalOpen }) => {
  return (
    <>
      <MUIToolbar sx={{ padding: '0px 20px !important', minHeight: 'auto' }}>
        <Typography sx={{ flex: '1 1 100%', fontSize: '18px', lineHeight: '21px' }} component='div'>
          {title}
        </Typography>
        <Button
          sx={{ whiteSpace: 'nowrap', fontSize: '18px', lineHeight: '21px' }}
          variant='text'
          onClick={handleModalOpen}
        >
          {buttonLabel}
        </Button>
      </MUIToolbar>
    </>
  )
}

export default Toolbar
