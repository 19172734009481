import React, { FC } from 'react'
import { Box, Stack, Table as MUITable, TableBody, TableContainer } from '@mui/material'
import { TableRow } from 'components/users'
import { TableHead, Toolbar, TablePagination } from 'components/generic'
import { UserModal } from 'components/modals'
import { useUsers } from 'hooks/users'
import { RowData } from 'types/Table'

const Users: FC = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    headCells,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    count,
    usersIsLoading,
    userModal,
    handleUserModalOpen,
    handleUserModalClose,
    rowsPerPageOptions,
    labelDisplayedRows,
  } = useUsers()

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Toolbar
          title='Список пользователей'
          buttonLabel='+ Добавить пользователя'
          handleModalOpen={handleUserModalOpen}
          isLoadingData={usersIsLoading}
        />
        <TableContainer
          sx={{
            maxHeight: { xs: 'auto', sm: 'calc(100vh - 275px)' },
            minHeight: { xs: 'auto', sm: 'calc(100vh - 275px)' },
          }}
        >
          <MUITable sx={{ alignSelf: 'flex-start' }} stickyHeader size='small'>
            <TableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row: RowData) => (
                <TableRow
                  id={Number(row.id)}
                  key={row.id}
                  username={row.username}
                  email={row.email}
                  phone={row.phone}
                  firstName={row.first_name}
                  lastName={row.last_name}
                />
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <Stack
          sx={{
            width: '100%',
            padding: { xs: '10px 20px', sm: '10px 24px' },
            borderTop: '1px solid',
            borderColor: 'divider',
            minHeight: '73px',
          }}
          direction='row'
        >
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={labelDisplayedRows}
          />
        </Stack>
      </Box>
      <UserModal isOpen={userModal} onClose={handleUserModalClose} modalType='create' />
    </>
  )
}

export default Users
