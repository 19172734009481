import React, { FC } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { Content } from 'components/headerContent'
import { Link } from '@mui/material'
import { selectTerminalIsLoading, selectTerminalName } from 'store/selectors/terminalsSelector'
import { useAppSelector } from 'store/hooks/useAppSelector'
import { selectProductIsLoading, selectProductName } from 'store/selectors/productsSelector'
import { useMediaQuery } from 'react-responsive'

const NavLinkItem: FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => (
  <Link
    underline='hover'
    sx={{ display: 'flex', alignItems: 'center', color: '#ffffff' }}
    component={NavLink}
    to={to}
  >
    {children}
  </Link>
)

const HeaderContent: FC = () => {
  const location = useLocation()
  const { terminalId, productId } = useParams()
  const name = useAppSelector<string>(selectTerminalName)
  const productName = useAppSelector(selectProductName(productId))
  const terminalIsLoading = useAppSelector(selectTerminalIsLoading)
  const productIsLoading = useAppSelector(selectProductIsLoading)
  const isHidden = useMediaQuery({ maxWidth: 600 })

  const getComponent = (path: string) => {
    switch (path) {
      case '/admin':
        return (
          <Content title='Статистика'>
            <NavLinkItem to='/admin'>Статистика</NavLinkItem>
          </Content>
        )
      case '/admin/users':
        return (
          <Content title='Пользователи'>
            <NavLinkItem to='/admin/users'>Пользователи</NavLinkItem>
          </Content>
        )
      case '/admin/terminals':
        return (
          <Content title='Автоматы'>
            <NavLinkItem to='/admin/terminals'>Автоматы</NavLinkItem>
          </Content>
        )
      case `/admin/terminals/${terminalId}`:
        return (
          <Content title='Автоматы' isLoading={terminalIsLoading}>
            <NavLinkItem to='/admin/terminals'>Автоматы</NavLinkItem>
            <NavLinkItem to={`/admin/terminals/${terminalId}`}>
              {isHidden ? name.slice(0, 15) + '...' : name}
            </NavLinkItem>
          </Content>
        )
      case `/admin/terminals/${terminalId}/cells`:
        return (
          <Content title='Автоматы' isLoading={terminalIsLoading}>
            <NavLinkItem to='/admin/terminals'>Автоматы</NavLinkItem>
            <NavLinkItem to={`/admin/terminals/${terminalId}`}>
              {isHidden ? name.slice(0, 15) + '...' : name}
            </NavLinkItem>
            <NavLinkItem to={`/admin/terminals/${terminalId}/cells`}>Товары в продаже</NavLinkItem>
          </Content>
        )
      case `/admin/terminals/${terminalId}/containers`:
        return (
          <Content title='Автоматы' isLoading={terminalIsLoading}>
            <NavLinkItem to='/admin/terminals'>Автоматы</NavLinkItem>
            <NavLinkItem to={`/admin/terminals/${terminalId}`}>
              {isHidden ? name.slice(0, 15) + '...' : name}
            </NavLinkItem>
            <NavLinkItem to={`/admin/terminals/${terminalId}/containers`}>Контейнеры</NavLinkItem>
          </Content>
        )
      case '/admin/marketplace':
        return (
          <Content title='Точки продаж'>
            <NavLinkItem to='/admin/marketplace'>Точки продаж</NavLinkItem>
          </Content>
        )
      case '/admin/groups':
        return (
          <Content title='Группы'>
            <NavLinkItem to='/admin/groups'>Группы</NavLinkItem>
          </Content>
        )
      case '/admin/categories':
        return (
          <Content title='Категории'>
            <NavLinkItem to='/admin/categories'>Категории</NavLinkItem>
          </Content>
        )
      case '/admin/products':
        return (
          <Content title='Товары'>
            <NavLinkItem to='/admin/products'>Товары</NavLinkItem>
          </Content>
        )
      case `/admin/products/${productId}`:
        return (
          <Content title='Товары' isLoading={productIsLoading}>
            <NavLinkItem to='/admin/products'>Товары</NavLinkItem>
            <NavLinkItem to={`/admin/products/${productId}`}>{productName}</NavLinkItem>
          </Content>
        )
      case '/admin/orders':
        return (
          <Content title='Заказы'>
            <NavLinkItem to='/admin/orders'>Заказы</NavLinkItem>
          </Content>
        )
      default:
        return null
    }
  }

  return <>{getComponent(location.pathname)}</>
}

export default HeaderContent
