import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Company, СompaniesData } from 'types/Companies'
import { getСompanies } from './ActionCreators'

interface companiesState {
  company: Company
  isLoading: boolean
  errors: string[]
}

const initialState: companiesState = {
  company: {
    name: '',
    video: '',
    logo_dark: '',
    logo_light: '',
    currency_code: '',
    currency_symbol: '',
  },
  isLoading: false,
  errors: [],
}

export const companySlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: {
    [getСompanies.fulfilled.type]: (state, action: PayloadAction<СompaniesData>) => {
      state.isLoading = false
      state.errors = []
      state.company = action.payload.results[0]
    },
    [getСompanies.pending.type]: (state) => {
      state.isLoading = true
    },
    [getСompanies.rejected.type]: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false
      state.errors = action.payload
    },
  },
})

export default companySlice.reducer
