import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

const selectMarketplacesData = (state: RootState) => state.marketplace
/**
 * Returns marketplaces
 */
export const selectMarketplaces = createSelector(
  [selectMarketplacesData],
  (marketplaces) => marketplaces.marketplacesData.results,
)
/**
 * Returns marketplaces count
 */
export const selectMarketplaceCount = createSelector(
  [selectMarketplacesData],
  (marketplaces) => marketplaces.marketplacesData.count || 0,
)
/**
 * Returns marketplaces list
 */
export const selectMarketplacesList = createSelector([selectMarketplacesData], (marketplaces) =>
  marketplaces.marketplacesData.results.map((item) => {
    return {
      label: item.name,
      value: item.id,
    }
  }),
)
/**
 * Returns marketplace
 */
export const selectMarketplace = createSelector(
  [selectMarketplacesData],
  (marketplace) => marketplace.marketplace,
)
/**
 * Returns marketplace
 */
export const selectMarketplaceIsLoading = createSelector(
  [selectMarketplacesData],
  (marketplace) => marketplace.isLoading,
)
