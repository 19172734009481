import React, { FC } from 'react'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { MarketplaceForm } from 'components/modals/MarketplaceModal'
import { MarketplacePosition } from 'types/Marketplace'
import { ConfirmationModal } from 'components/modals'
import { useMarketplaceModal } from 'hooks/marketplaces'

interface MarketplaceModalProps {
  isOpen: boolean
  type: 'change' | 'create'
  onClose: () => void
  administrators?: number[]
  position?: MarketplacePosition
  id?: number
  title?: string
  address?: string
}

const MarketplaceModal: FC<MarketplaceModalProps> = ({
  id,
  title,
  address,
  administrators = [],
  position,
  isOpen,
  type,
  onClose,
}) => {
  const {
    methods,
    handleConfirmModalOpen,
    handleDelete,
    onSubmit,
    confirmModal,
    handleConfirmModalClose,
  } = useMarketplaceModal(onClose, type, id, title, address, position)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '10px',
          maxWidth: '920px',
          width: '100%',
          margin: '20px',
        },
      }}
    >
      <Stack sx={{ padding: { xs: '20px 25px', sm: '20px 35px' } }} direction='column' spacing={3}>
        {type === 'change' ? (
          <Typography
            sx={{ fontSize: '20px', lineHeight: '21px', marginBottom: { xs: '0', sm: '20px' } }}
          >
            Редактирование точки продаж - {title}
          </Typography>
        ) : (
          <Typography
            sx={{ fontSize: '20px', lineHeight: '21px', marginBottom: { xs: '0', sm: '20px' } }}
          >
            Создать точку продаж
          </Typography>
        )}

        <FormProvider {...methods}>
          <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: '30px' }}>
              <MarketplaceForm
                title={title}
                administrators={administrators}
                address={address}
                position={position}
              />
            </Box>

            <Stack sx={{ justifyContent: 'flex-end' }} direction='row' spacing={2}>
              {type === 'change' && (
                <Button color='error' variant='outlined' onClick={handleConfirmModalOpen}>
                  Удалить
                </Button>
              )}

              <Button variant='contained' type='submit'>
                Сохранить
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
      <ConfirmationModal
        isOpen={confirmModal}
        onClose={handleConfirmModalClose}
        confirm={handleDelete}
      />
    </Dialog>
  )
}

export default MarketplaceModal
